<template>
    <div class="btn-group m-b-10">
        <router-link :to="{ name: 'PageEdit' }" class="btn btn-dropbox"><i class="fa fa-plus"></i> YENİ
        </router-link>
        <button class="btn btn-pinterest" @click="Delete_Click"><i class="fa fa-minus"></i> SİL</button>
        <router-link :to="{ name: 'PageEdit', params: { id: Model.Id } }" class="btn btn-dropbox"><i
                class="fa fa-edit"></i> DÜZENLE
        </router-link>
    </div>

    <div class="col card">

        <div class="row p-t-15">

            <div class="col col-12 col-sm-12 col-md-6">

                <div class="form-group">
                    <label>Üst Sayfa Adı</label><br>
                    {{ Model.ParentName }}
                </div>

                <div class="form-group">
                    <label>Kod</label><br>
                    {{ Model.Code }}
                </div>

                <div class="form-group">
                    <label>Adı</label><br>
                    {{ Model.Name }}
                </div>

                <div class="form-group">
                    <label>Başlık</label><br>
                    {{ Model.Title }}
                </div>

                <div class="form-group">
                    <label>Adres</label><br>
                    {{ Model.Url }}
                </div>

                <div class="form-group">
                    <label>Icon</label><br>
                    <i :class="Model.Icon"></i>
                    {{ Model.Icon }}
                </div>

                <div class="form-group">
                    <label>Sıra</label><br>
                    {{ Model.LineNumber }}
                </div>

                <div class="form-group">

                    <ul class="d-flex flex-wrap">
                        <li>
                            <label>Aktif/Pasif</label><br>
                            <i v-if="Model.Active" class="fa fa-circle text-c-green"></i><i v-else
                                class="fa fa-circle text-danger"></i>
                        </li>
                        <li>
                            <label>Gizle</label><br>
                            <i v-if="Model.IsHide" class="fa fa-circle text-c-green"></i><i v-else
                                class="fa fa-circle text-danger"></i>
                        </li>
                        <li>
                            <label>Admin Sayfası</label><br>
                            <i v-if="Model.IsAdminPage" class="fa fa-circle text-c-green"></i><i v-else
                                class="fa fa-circle text-danger"></i>
                        </li>
                    </ul>
                </div>

            </div>

            <div class="col col-12 col-sm-12 col-md-6">

                <div class="row">
                    <div class="col col-sm-6 form-group">
                        <label>Oluşturan</label><br>
                        {{ Model.CreatedBy_Text }}
                    </div>

                    <div class="col col-sm-6 form-group">
                        <label>Oluşturulma Tarihi</label><br>
                        {{ Model.CreadedDate_Text }}
                    </div>
                </div>

                <div class="row">
                    <div class="col col-sm-6 form-group">
                        <label>Güncelleyen</label><br>
                        {{ Model.ModifiedBy_Text }}
                    </div>

                    <div class="col col-sm-6 form-group">
                        <label>Güncellenme Tarihi</label><br>
                        {{ Model.ModifiedDate_Text }}
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router"

import { ErrorNotifi } from "@/_helpers/notification";

import ButtonLoading from "@/components/ButtonLoading";

import PageService from "@/_services/page.service";

export default {
    components: {
        ButtonLoading
    },
    setup() {

        const { detail, Delete, pageResult } = PageService();
        const route = useRoute();

        const Model = ref({
            Id: 0,
            ParentName:null,
            Code: null,
            Name: null,
            Title: null,
            Url: null,
            Icon: null,
            LineNumber: 1,
            Active: true,
            IsHide: false,
            IsAdminPage: false,
            CreatedBy_Text: null,
            CreadedDate_Text: null,
            ModifiedBy_Text: null,
            ModifiedDate_Text: null
        });

        onMounted(async () => {

            if (route.params.id > 0) {
                await detail(route.params.id);

                if (pageResult.value.Success)
                    Model.value = pageResult.value.Data;
            }
        });

        const Delete_Click = async () => {
            if (route.params.id > 0)
                await Delete(route.params.id);
        }

        return { Model, Delete_Click };
    }
}
</script>

<style scoped>
.flex-wrap li {
    padding-right: 15px;
}
</style>