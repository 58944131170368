<template>

    <div class="row">
        <div class="col">
            <button class="btn btn-default" @click="btnAddColumn_Click()"><i class="fa fa-plus"></i></button>
            <button class="btn btn-default m-l-5" @click="btnInsertColumn_Click()" :disabled="Model.length == 0"><i
                    class="fa fa-long-arrow-right"></i></button>
            <button class="btn btn-default m-l-5" @click="btnDeleteColumn_Click()" :disabled="Model.length == 0"><i
                    class="fa fa-minus"></i></button>
            <button class="btn btn-default m-l-5" @click="btnDownColumn_Click()" :disabled="Model.length == 0"><i
                    class="fa fa-angle-down"></i></button>
            <button class="btn btn-default m-l-5" @click="btnUpColumn_Click()" :disabled="Model.length == 0"><i class="fa fa-angle-up"></i></button>
        </div>
    </div>

    <div v-if="Model.length == 0" class="alert alert-info m-t-10">Column Ekleyiniz...</div>
    <div v-else class="row m-t-10">
        <div class="col col-4">
            <ul class="ul-column-list">
                <li v-for="(col, index) in Model" ref="ColumnNameRefs"
                    :style="{ 'background-color': (selectedIndex == index ? '#dee2e6' : '') }">
                    <a href="javascript:;" @click="ColumnSelected(index)">{{ col.Name }}</a>
                </li>
            </ul>
        </div>
        <div class="col col-8">
            <div class="table-responsive">
                <table class="table">
                    <tbody>
                        <tr>
                            <th>Name</th>
                            <td><input type="text" class="form-control" v-model="selectedModel.Name"></td>
                        </tr>
                        <tr>
                            <th>Caption</th>
                            <td><input type="text" class="form-control" v-model="selectedModel.Caption"></td>
                        </tr>
                        <tr>
                            <th>Field Name</th>
                            <td><input type="text" class="form-control" v-model="selectedModel.FieldName"></td>
                        </tr>
                        <tr>
                            <th>Css</th>
                            <td><input type="text" class="form-control" v-model="selectedModel.Css"></td>
                        </tr>
                        <tr>
                            <th>Sort Order</th>
                            <td>
                                <select class="form-control" v-model="selectedModel.SortOrder">
                                    <option value="None">None</option>
                                    <option value="Ascending">Ascending</option>
                                    <option value="Descending">Descending</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th>Export Show</th>
                            <td><input type="checkbox" v-model="selectedModel.ExportShow"></td>
                        </tr>
                        <tr>
                            <th>Visible</th>
                            <td><input type="checkbox" v-model="selectedModel.Visible"></td>
                        </tr>
                        <tr>
                            <th>Visible Index</th>
                            <td><input type="number" class="form-control" v-model="selectedModel.VisibleIndex"></td>
                        </tr>
                        <tr>
                            <th>Width</th>
                            <td><input type="number" class="form-control" v-model="selectedModel.Width"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

</template>

<script>

import { ref } from "vue";

export default {
    props: ["Model"],
    setup(props, context) {

        const ColumnNameRefs = ref([]);
        const selectedModel = ref(null);
        const selectedIndex = ref(-1);
        const index = ref(0);

        const ColumnSelected = (index) => {
            $(".ul-column-list li").removeAttr("style");
            let li = ColumnNameRefs.value[index]
            if (li != null)
                li.style.backgroundColor = "#dee2e6";

            selectedIndex.value = index;
            selectedModel.value = props["Model"][index];
        }


        const btnAddColumn_Click = () => {
            const columnModel = ref(
                {
                    Name: "ColumnName" + index.value,
                    Caption: "Caption" + index.value,
                    FieldName: null,
                    Css: null,
                    ExportShow: true,
                    VisibleIndex: index.value,
                    Visible: true,
                    Width: 75,
                    SortOrder: "None"
                });

            props["Model"].push(columnModel.value);

            ColumnSelected(props["Model"].length - 1);

            index.value++;
        }

        const btnInsertColumn_Click = () => {
            const columnModel = ref(
                {
                    Name: "ColumnName" + index.value,
                    Caption: "Caption" + index.value,
                    FieldName: null,
                    Css: null,
                    ExportShow: true,
                    VisibleIndex: index.value,
                    Visible: true,
                    Width: 75,
                    SortOrder: "None"
                });

            props["Model"].splice(selectedIndex.value, 0, columnModel.value);

            ColumnSelected(selectedIndex.value);

            index.value++;
        }

        const btnDeleteColumn_Click = () => {
            props["Model"].splice(selectedIndex.value, 1);
        }

        const btnDownColumn_Click = () => {
            const columnModel = ref(null);

            columnModel.value = props["Model"][selectedIndex.value];

            props["Model"].splice(selectedIndex.value, 1);

            if (props["Model"].length > selectedIndex.value)
                selectedIndex.value++;

            props["Model"].splice(selectedIndex.value, 0, columnModel.value);

        }

        const btnUpColumn_Click = () => {
            const columnModel = ref(null);

            columnModel.value = props["Model"][selectedIndex.value];

            props["Model"].splice(selectedIndex.value, 1);

            if (selectedIndex.value > 0)
                selectedIndex.value--;

            props["Model"].splice(selectedIndex.value, 0, columnModel.value);
        }

        return { ColumnNameRefs, selectedModel, selectedIndex, ColumnSelected, btnAddColumn_Click, btnInsertColumn_Click, btnDeleteColumn_Click, btnDownColumn_Click, btnUpColumn_Click }
    }
}

</script>

<style scoped>
.table tr:first-child th,
tr:first-child td {
    border-top: none;
}

.table th {
    width: 150px;
}

.ul-column-list {
    height: 100%;
    padding: .50rem;
    border-width: 1px;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    border: solid #dee2e6;
}
</style>