<template>
<div class="row">
                <div class="col col-12 col-sm-12 col-md-12 col-lg-8">
                    <div class="card card-block">
                        <GChart type="PieChart" :data="pieChartData" :options="options" />
                    </div>
                </div>
                <div class="col col-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="card card-block">
                        <div class="row align-items-center m-l-0">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-2 text-center">
                                <i class="feather icon-check f-30 text-c-lite-green"></i>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-9 text-center">
                                <h6 class="text-muted m-b-10">Zamanında Sevkiyat</h6>
                                <h2 class="m-b-0">{{ $filters["N0"](zamanindaSevkiyat) }}</h2>
                            </div>
                        </div>
                    </div>
                    <div class="card card-block">
                        <div class="row align-items-center m-l-0">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-2 text-center">
                                <i class="feather icon-x-circle f-30 text-c-pink"></i>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-9 text-center">
                                <h6 class="text-muted m-b-10">Geç Sevkiyat</h6>
                                <h2 class="m-b-0">{{ $filters["N0"](gecSevkiyat) }}</h2>
                            </div>
                        </div>
                    </div>
                    <div class="card card-block">
                        <div class="row align-items-center m-l-0">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-2 text-center">
                                <img src="/images/icons/alert-circle.svg" />
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-9 text-center">
                                <h6 class="text-muted m-b-10">Erken Sevkiyat</h6>
                                <h2 class="m-b-0">{{ $filters["N0"](erkenSevkiyat) }}</h2>
                            </div>
                        </div>
                    </div>
                    <div class="card card-block">
                        <div class="row align-items-center m-l-0">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-2 text-center">
                                <i class="fa fa-question-circle-o" style="font-size: 30px;"></i>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-9 text-center">
                                <h6 class="text-muted m-b-10">Bilinmeyen Sevkiyat</h6>
                                <h2 class="m-b-0">{{ $filters["N0"](bilinmeyenSevkiyat) }}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col card card-block">
                <GChart type="ColumnChart" :data="chartData" :options="options" />

                <div class="row m-t-15">
                    <div class="col col-12">
                        <div class="table-responsive">
                            <table class="table table-hover">
                                <tr style="background-color: rgba(0,0,0,.075)">
                                    <th width="100" v-for="item in tableChartData[0]" >{{ item }}</th>
                                </tr>
                                <tr v-for="items in tableChartData.slice(1)">
                                    <td v-for="(itm, index) in items">
                                        <b v-if="index==0">{{ itm }}</b>
                                        <span v-else>{{ $filters["N0"](itm) }}</span>
                                    </td>
                                </tr>

                            </table>
                        </div>
                    </div>
                </div>
            </div>
</template>

<script>

import { ref, onMounted } from "vue";
import { GChart } from 'vue-google-charts'

import DashboardService from "@/_services.reports/dashboard.service";

export default {
    components: {
        GChart
    },
    setup() {

        const { satinalma_sevk_raporu, result } = DashboardService();

        const tabControlList = ref([]);
        const chartData = ref([]);
        const pieChartData = ref([]);
        const tableChartData = ref([]);
        const zamanindaSevkiyat = ref(0);
        const gecSevkiyat = ref(0);
        const erkenSevkiyat = ref(0);
        const bilinmeyenSevkiyat = ref(0);

        onMounted(async () => {
            await satinalma_sevk_raporu();

            if (result.value.Success) {

                chartData.value = [['Sevkiyat', 'Zamanında Sevkiyat', { role: 'annotation' }, 'Geç Sevkiyat', { role: 'annotation' }, 'Erken Sevkiyat', { role: 'annotation' }, 'Bilinmeyen Sevkiyat', { role: 'annotation' }]];
                pieChartData.value = [
                    ['Sevkiyat', 'Sevkiyat'],
                    ['Zamanında Sevkiyat', 0],
                    ['Geç Sevkiyat', 0],
                    ['Erken Sevkiyat', 0],
                    ['Bilinmeyen Sevkiyat', 0]
                ];

                tableChartData.value = [
                    [''],
                    ['Zamanında Sevkiyat'],
                    ['Geç Sevkiyat'],
                    ['Erken Sevkiyat'],
                    ['Bilinmeyen Sevkiyat']
                ];

                let weekNumber = 0;
                let firstValue = 0;
                let colIndex = 0;

                let model = [];

                result.value.Data.forEach((item) => {

                    if (weekNumber !== item.HAFTA) {
                        if (firstValue === 1)
                            chartData.value.push(model);

                        firstValue = 1;
                        model = [];
                        weekNumber = item.HAFTA;
                        tableChartData.value[0][tableChartData.value[0].length] = weekNumber;
                        tableChartData.value[1][tableChartData.value[1].length] = 0;
                        tableChartData.value[2][tableChartData.value[2].length] = 0;
                        tableChartData.value[3][tableChartData.value[3].length] = 0;
                        tableChartData.value[4][tableChartData.value[4].length] = 0;

                        model[0] = weekNumber;
                        model[1] = 0;
                        model[2] = 0;
                        model[3] = 0;
                        model[4] = 0;
                        model[5] = 0;
                        model[6] = 0;
                        model[7] = 0;
                        model[8] = 0;
                    }

                    colIndex = -1;

                    if (item.TESLIMAT_GUN_KODU == "ZAMANINDA_SEVKIYAT") {
                        zamanindaSevkiyat.value += item.SAYISI;
                        tableChartData.value[1][tableChartData.value[1].length - 1] = item.SAYISI;
                        colIndex = 1;
                    }
                    else if (item.TESLIMAT_GUN_KODU == "GEC_SEVKIYAT") {
                        gecSevkiyat.value += item.SAYISI;
                        tableChartData.value[2][tableChartData.value[2].length - 1] = item.SAYISI;
                        colIndex = 3;
                    }
                    else if (item.TESLIMAT_GUN_KODU == "ERKEN_SEVKIYAT") {
                        erkenSevkiyat.value += item.SAYISI;
                        tableChartData.value[3][tableChartData.value[3].length - 1] = item.SAYISI;
                        colIndex = 5;
                    }
                    else if (item.TESLIMAT_GUN_KODU == "BILINMEYEN_SEVKIYAT") {
                        bilinmeyenSevkiyat.value += item.SAYISI;
                        tableChartData.value[4][tableChartData.value[4].length - 1] = item.SAYISI;
                        colIndex = 7;
                    }

                    if (colIndex > -1) {
                        model[colIndex] = item.SAYISI;
                        model[colIndex + 1] = item.SAYISI;
                    }
                });

                if (firstValue === 1)
                    chartData.value.push(model);

                pieChartData.value[1][1] = zamanindaSevkiyat.value;
                pieChartData.value[2][1] = gecSevkiyat.value;
                pieChartData.value[3][1] = erkenSevkiyat.value;
                pieChartData.value[4][1] = bilinmeyenSevkiyat.value;

                result.value.Success = false;
            }
        });

        const GetData = async (tabCode, modulType) => {
            let control = tabControlList.value.lastIndexOf(tabCode);

            if (control < 0) {

                await sevk_raporu(modulType);

                tabControlList.value.push(tabCode);
            }
        }


        const options = {
            width: "100%",
            legend: { position: 'top' },         // no legend
            backgroundColor: { strokeWidth: 1 },   // to get a nice box
            seriesType: "bars",                   // the standard chart type
            chartArea: { left: 75, top: 75, right: 60, bottom: 65, height: '100%', width: '100%' },
            // the second data column should be of type 'line' and should be associated with the second vertical axis
            height: 450,
            isStacked: true,
            colors: ['#01a9ac', '#eb3422', '#fe9365', 'Gray']
        }

        return { chartData, pieChartData, tableChartData, options, zamanindaSevkiyat, gecSevkiyat, erkenSevkiyat, bilinmeyenSevkiyat, GetData }
    }

}

</script>