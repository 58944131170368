import { ref } from "vue";
import { store } from "../store";
import ResponseService from "@/_services/response.service";

import { ErrorNotifi, SuccessNotifi, MessageBoxShow } from "../_helpers/notification";

const result = ref({ Success: false, Data: null });
const routePrefix = ref("dashboard/");

const envanter_raporu = async () => {

    store.Loading = true;

    const { get, responseResult } = ResponseService();

    await get(routePrefix.value + "envanter-raporu");

    if (responseResult.value) {
        if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
            result.value.Success = true;
            result.value.Data = responseResult.value.Data;
        }
        else {
            let Messages = responseResult.value.Messages.filter(x => x.MessageId == 0);
            if (Messages.length > 0)
                ErrorNotifi(Messages[0].Message);

            result.value.Success = false;
        }

        store.Loading = false;
    }
}

const sevk_raporu = async (modulType) => {

    store.Loading = true;

    const { get, responseResult } = ResponseService();

    //await get(routePrefix.value + "sevk-raporu?mt=" + modulType);
    await get(routePrefix.value + "sevk-raporu");

    if (responseResult.value) {

        result.value.Success = true;
        result.value.Data = JSON.parse(responseResult.value.Message);

        /*
        if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
            result.value.Success = true;
            result.value.Data = responseResult.value.Data;
        }
        else {
            let Messages = responseResult.value.Messages.filter(x => x.MessageId == 0);
            if (Messages.length > 0)
                ErrorNotifi(Messages[0].Message);

                result.value.Success = false;
        }
        */

        store.Loading = false;
    }
}

const satinalma_sevk_raporu = async (modulType) => {

    store.Loading = true;

    const { get, responseResult } = ResponseService();

    //await get(routePrefix.value + "sevk-raporu?mt=" + modulType);
    await get(routePrefix.value + "satinalma-sevk-raporu");

    if (responseResult.value) {

        result.value.Success = true;
        result.value.Data = JSON.parse(responseResult.value.Message);

        /*
        if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
            result.value.Success = true;
            result.value.Data = responseResult.value.Data;
        }
        else {
            let Messages = responseResult.value.Messages.filter(x => x.MessageId == 0);
            if (Messages.length > 0)
                ErrorNotifi(Messages[0].Message);

                result.value.Success = false;
        }
        */

        store.Loading = false;
    }
}


const DashboardService = () => {
    return { envanter_raporu, sevk_raporu, satinalma_sevk_raporu, result }
}

export default DashboardService