import { ref } from "vue";
import { store } from "../store";
import ResponseService from "./response.service";
import router from "../router";

import { ErrorNotifi, SuccessNotifi, MessageBoxShow } from "../_helpers/notification";

const authGroupResult = ref({ Success: false, Data: null });
const deleteResult = ref({ Success: false });
const routePrefix = ref("authorization-group/");

const list = async () => {

    store.Loading = true;

    const { get, responseResult } = ResponseService();

    await get(routePrefix.value + "list");

    if (responseResult.value) {

        if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
            authGroupResult.value.Success = true;
            authGroupResult.value.Data = responseResult.value.Data;
        }
        else {
            let Messages = responseResult.value.Messages.filter(x => x.MessageId == 0);
            if (Messages.length > 0)
                ErrorNotifi(Messages[0].Message);

                authGroupResult.value.Success = false;
        }

        store.Loading = false;
    }
}

const editdetail = async (id) => {

    store.Loading = true;

    const { get, responseResult } = ResponseService();

    await get(routePrefix.value + "edit/" + id);

    if (responseResult.value) {
        if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
            authGroupResult.value.Success = true;
            authGroupResult.value.Data = responseResult.value.Data;
        }
        else {
            let Messages = responseResult.value.Messages.filter(x => x.MessageId == 0);
            if (Messages.length > 0)
                ErrorNotifi(Messages[0].Message);

                authGroupResult.value.Success = false;
        }

        store.Loading = false;
    }
}

const save = async (model) => {

    store.Loading = true;

    const { post, responseResult } = ResponseService();

    await post(routePrefix.value + "edit", model);

    if (responseResult.value) {
        if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
            let messageModel = responseResult.value.Messages.filter(x => x.MessageId == 1);
            SuccessNotifi(messageModel[0].Message);

            router.push({ name: "AuthGroupList" });
            authGroupResult.value.Success = true;
        }
        else {
            let Messages = responseResult.value.Messages.filter(x => x.MessageId == 0);
            if (Messages.length > 0)
                ErrorNotifi(Messages[0].Message);

            authGroupResult.value.Data = responseResult.value;
            authGroupResult.value.Success = false;
        }

        store.Loading = false;
    }
}

const DeleteList = async (CheckedList) => {
    store.Loading = true;

    const { Delete, responseResult } = ResponseService();

    let ids = CheckedList.join(',')
    await Delete(routePrefix.value + "delete-list?ids=" + ids);

    if (responseResult.value) {
        if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
            let messageModel = responseResult.value.Messages.filter(x => x.MessageId == 1);
            SuccessNotifi(messageModel[0].Message);

            deleteResult.value.Success = true;
        }
        else {
            let Messages = responseResult.value.Messages.filter(x => x.MessageId == 0);
            if (Messages.length > 0)
                ErrorNotifi(Messages[0].Message);

            deleteResult.value.Success = false;
        }

        store.Loading = false;
    }
}

const DeSelectedAndSelectedUserList = async (id) => {

    const { get, responseResult } = ResponseService();

    await get(routePrefix.value + "deselected-and-selected-user-list/"+id);

    if (responseResult.value) {
        authGroupResult.value.Success = true;
        authGroupResult.value.Data = responseResult.value;
    }
    else
        authGroupResult.value.Success = false;
}

const AuthorizationGroupService = () => {
    return { list, editdetail, save, DeleteList, DeSelectedAndSelectedUserList, authGroupResult, deleteResult }
}

export default AuthorizationGroupService