<template>
    <label v-if="Config.CaptionShow == null || Config.CaptionShow" :for="Config.Name">{{ Config.Caption }}</label><label
        v-if="Config.Required" :for="Config.Name" class="text-danger">*</label>
    <div class="input-group m-t-15">
        <input type="text" class="form-control bg-white" :value="Value" :placeholder="Config.Placeholder"
            :class="{ 'input-required-field': Config.ValidationMessage != null }"
            :required="Config.Required"
            @input="$emit('update:Value', $event.target.value)"
            readonly
            >
        <span class="input-group-btn">
            <button type="button" class="btn btn-dropbox" @click="$emit('BtnClick')">
                <i v-if="Config.Icon != null" :class="Config.Icon"></i> <span
                    v-if="Config.BtnCaption != null">{{ Config.BtnCaption }}</span>
            </button>
        </span>
    </div>
    <div v-if="Config.ValidationMessage != null" class="text-danger">{{
        Config.ValidationMessage
    }}
    </div>
</template>

<script>
export default {
    props: ["Config", "Value"],
    emits: ['update:Value']
}
</script>