import { createRouter, createWebHistory } from 'vue-router'

import _Layout from './views/template/_Layout';
import Home from './views/home/Home'

import EnvanterRaporu_Dashboard from './views/dashboard/EnvanterTakip'
import Sevkiyat_Dashboard from './views/dashboard/Sevkiyat/Sevkiyat'

import Ciro_KPI_Dashboard from './views/dashboard_kpi/Ciro'
import DepoEnvanter_KPI_Dashboard from './views/dashboard_kpi/DepoEnvanter'

import AvDosyaTakipList from './views/av_dosya_takip/AvDosya_List'
import AvDosyaTakipEdit from './views/av_dosya_takip/AvDosya_Edit'
import AvDosyaTakipDetail from './views/av_dosya_takip/AvDosya_Detail'

import EmployeeList from './views/admin/employee/Emp_List'
import EmployeeEdit from './views/admin/employee/Emp_Edit'
import EmployeeDetail from './views/admin/employee/Emp_Detail'

import UserList from './views/admin/user/User_List'
import UserEdit from './views/admin/user/User_Edit'
import UserDetail from './views/admin/user/User_Detail'
import UserAuthorizations from './views/admin/user/User_Authorizations'
import UserPasswordChange from './views/admin/user/User_PasswordChange'

import AuthGroupList from './views/admin/authorizationgroup/AuthGroup_List'
import AuthGroupEdit from './views/admin/authorizationgroup/AuthGroup_Edit'

import PageList from './views/admin/page/Page_List'
import PageEdit from './views/admin/page/Page_Edit'
import PageDetail from './views/admin/page/Page_Detail'

import TableViewList from './views/admin/tableview/TableView_List'
import TableViewEdit from './views/admin/tableview/TableView_Edit'
import TableViewDetail from './views/admin/tableview/TableView_Detail'

import DescriptionFormatsList from './views/proposal_management/general_definitions/DescriptionFormats_List'
import DescriptionFormatsEdit from './views/proposal_management/general_definitions/DescriptionFormats_Edit'

import ProposalList from './views/proposal_management/proposals/Proposal_List'
import ProposalEdit from './views/proposal_management/proposals/Proposal_Edit'
import ProposalDetail from './views/proposal_management/proposals/Proposal_Detail'

import MessageLoggedOut from './views/messages/Message_LoggedOut'
import LoadingPage from './views/auth/LoadingPage';

const routes = [
  {
    path: '/login',
    component: () => import('./views/template/_LoginLayout'),
    children: [
      { path: '/login', name: "Login", component: () => import('./views/auth/Login') },
      { path: '/forgotmypassword', name: "ForgotMyPassword", component: () => import('./views/auth/ForgotMyPassword') },
      { path: '/activate/:id', name: "Activate", component: () => import('./views/auth/Activate') },

      // belirtilen url yok ise yönlendir
      { path: '*', redirect: '/' }
    ]
  },
  {
    path: '/',
    component: _Layout,
    children: [
      { path: '/', component: Home, name: "Home" },

      { path: '/dashboard/envanter-takip', component: EnvanterRaporu_Dashboard, name: "EnvanterRaporu_Dashboard" },
      { path: '/dashboard/sevkiyat', component: Sevkiyat_Dashboard, name: "Sevkiyat_Dashboard" },

      { path: '/dashboard-kpi/ciro', component: Ciro_KPI_Dashboard, name: "Ciro_KPI_Dashboard" },
      { path: '/dashboard-kpi/depo-envanter', component: DepoEnvanter_KPI_Dashboard, name: "DepoEnvanter_KPI_Dashboard" },

      { path: '/avdosyatakip/list', component: AvDosyaTakipList, name: "AvDosyaTakipList" },
      { path: '/avdosyatakip/edit/:id?', component: AvDosyaTakipEdit, name: "AvDosyaTakipEdit" },
      { path: '/avdosyatakip/detail/:id', component: AvDosyaTakipDetail, name: "AvDosyaTakipDetail" },

      { path: '/employee/list', component: EmployeeList, name: "EmployeeList" },
      { path: '/employee/edit/:id?', component: EmployeeEdit, name: "EmployeeEdit" },
      { path: '/employee/detail/:id', component: EmployeeDetail, name: "EmployeeDetail" },

      { path: '/user/list', component: UserList, name: "UserList" },
      { path: '/user/edit/:id?', component: UserEdit, name: "UserEdit" },
      { path: '/user/detail/:id', component: UserDetail, name: "UserDetail" },
      { path: '/user/authorizations/:id', component: UserAuthorizations, name: "UserAuthorizations" },
      { path: '/user/password-change', component: UserPasswordChange, name: "UserPasswordChange"},

      { path: '/page/list', component: PageList, name: "PageList" },
      { path: '/page/edit/:id?', component: PageEdit, name: "PageEdit" },
      { path: '/page/detail/:id', component: PageDetail, name: "PageDetail" },

      { path: '/authorization-group/list', component: AuthGroupList, name: "AuthGroupList" },
      { path: '/authorization-group/edit/:id?', component: AuthGroupEdit, name: "AuthGroupEdit" },

      { path: '/table-view/list', component: TableViewList, name: "TableViewList" },
      { path: '/table-view/edit/:id?', component: TableViewEdit, name: "TableViewEdit" },
      { path: '/table-view/detail/:id', component: TableViewDetail, name: "TableViewDetail" },

      { path: '/proposal/description-formats/list/', component: DescriptionFormatsList, name: "DescriptionFormatsList" },
      { path: '/proposal/description-formats/edit/:id?', component: DescriptionFormatsEdit, name: "DescriptionFormatsEdit" },

      { path: '/proposal/list', component: ProposalList, name: "ProposalList" },
      { path: '/proposal/edit/:id?', component: ProposalEdit, name: "ProposalEdit" },
      { path: '/proposal/detail/:id', component: ProposalDetail, name: "ProposalDetail" },

      { path: '/message/loggedout', component: MessageLoggedOut, name: "MessageLoggedOut" },
      { path: '/loading', component: LoadingPage, name: "LoadingPage" },

      // belirtilen url yok ise yönlendir
      { path: '*', redirect: '/' }
    ]
  }
  //{
  //  path: '/about',
  //  name: 'about',
  //  component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  //}

]

const router = createRouter({
  history: createWebHistory(),
  routes
})

import { store } from "./store";

router.beforeEach((to, from, next) => {

  let localUserModel = JSON.parse(localStorage.getItem("user"));

  if (localUserModel != null) {
    let localToken = localUserModel.Token;
    let storeToken = store.UserModel.Token;
    if (localToken != storeToken) {
      localStorage.clear("user");
    }
  }


  // giriş yapmadıysa ve kısıtlı bir sayfaya erişmeye çalışıyorsa giriş sayfasına yönlendir
  const publicPages = ['/login', '/forgotmypassword', '/activate'];
  //const authRequired = !publicPages.includes(to.path);
  const authRequired = !(publicPages.filter(x => to.path.includes(x)).length > 0);
  const loggedIn = localStorage.getItem('user');


  if (authRequired && !loggedIn) {
    return next('/login');
  }

  if (authRequired) {

    if (store.UserAuth != null) {
      store.PageTitle = "";

      if (!store.userPublicPages.find(x => to.path.includes(x))) {
        return next('/');
      }

      let pageModel = store.UserAuth.PageAuthList.find(x => x.Code === to.name);
      if (pageModel != null)
        store.PageTitle = pageModel["Title"];
    }
  }

  next();
});


export default router;
