<template>
    <form @submit.prevent="Save_Click">
        <div class="btn-group m-b-10">
            <ButtonLoading class="btn btn-success" :Config="{ Disabled: submitted }">
                <i class="fa fa-check"></i> KAYDET
            </ButtonLoading>
        </div>

        <div class="col card p-t-15">

            <input type="hidden" v-model="Model.Id" />

            <div class="row">
                <div class="form-group col-6">

                    <DropDownList
                        :Config="{ Name: 'CountryId', Caption: 'Ülke', Required: true, DataText: 'Name', DataValue: 'Id', DisabledFieldName: 'Id', DisabledValue: -1, ValidationMessage: ValidationMessage['CountryId'] }"
                        :Data="CountryData" v-model:Value="Model.CountryId">
                    </DropDownList>
                </div>

                <div class="form-group col-6">

                    <DropDownList
                        :Config="{ Name: 'CompanyId', Caption: 'Şirket', Required: true, DataText: 'Name', DataValue: 'Id', DisabledFieldName: 'Id', DisabledValue: -1, ValidationMessage: ValidationMessage['CompanyId'] }"
                        :Data="CompanyData" v-model:Value="Model.CompanyId"></DropDownList>
                </div>
            </div>


            <div class="form-group">
                <TextBox
                    :Config="{ Name: 'Name', Caption: 'Grup Adı', Placeholder: 'Grup Adı', Type: 'text', Required: true, ValidationMessage: ValidationMessage['Name'] }"
                    v-model:Value="Model.Name"></TextBox>

            </div>

        </div>

        <div class="col card p-15">
            <table class="col col-12 col-sm-12 col-md-7">
                <tr>
                    <td>
                        <ListBox :Data="User_DeSelectedList" :Config="{ Title: 'Kullanıcılar' }" :KeyValue="KeyValue"
                            @SelectedValue="DeSelected_UserList_Selected">
                        </ListBox>
                    </td>
                    <td align="center">
                        <a @click="AllAdd" class="btn btn-dropbox text-default m-b-10 btn-add-or-remove"><i
                                class="fa fa-angle-double-right"></i></a><br />
                        <a @click="AllRemove" class="btn btn-dropbox text-default m-b-10 btn-add-or-remove"><i
                                class="fa fa-angle-double-left"></i></a><br />
                    </td>
                    <td>
                        <ListBox :Data="User_SelectedList" :KeyValue="KeyValue" :Config="{ Title: 'Seçilenler' }"
                            @SelectedValue="Selected_UserList_Selected"></ListBox>
                    </td>
                </tr>
            </table>
        </div>

        <div class="col card">
            <ul class="nav nav-tabs md-tabs" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" data-toggle="tab" href="#tabPageAuthorization" role="tab"
                        data-tab-type="cpu">SAYFA YETKİLERİ</a>
                    <div class="slide"></div>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#tabActionAuthorization" role="tab"
                        data-tab-type="ram">AKSİYON YETKİLERİ</a>
                    <div class="slide"></div>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#tabOtherAuthorization" role="tab"
                        data-tab-type="freespace">DİĞER YETKİLER</a>
                    <div class="slide"></div>
                </li>
            </ul>
            <div class="tab-content card-block">
                <div class="tab-pane active" id="tabPageAuthorization" role="tabpanel">
                    <TreeView :DataSource="Model.PageAuthList" :Config="{ Text_FieldName: 'Name' }"
                        :CheckedList="pageAuthorization_CheckedList" @SelectChecked="PageAuthorization_SelectChecked">
                    </TreeView>
                </div>
                <div class="tab-pane" id="tabActionAuthorization" role="tabpanel"></div>
                <div class="tab-pane" id="tabOtherAuthorization" role="tabpanel"></div>
            </div>
        </div>

</form>
</template>

<script>

import { ref, onMounted, watch } from "vue";
import { useRoute } from "vue-router"

import ButtonLoading from "@/components/ButtonLoading";
import TextBox from "@/components/textbox/TextBox";
import DropDownList from "@/components/dropdownlist/DropDownList";
import ListBox from "@/components/list_box/ListBox.vue";
import TreeView from "@/components/TreeView";

import CountryService from "@/_services/country.service";
import CompanyService from "@/_services/company.service";
import AuthorizationGroupService from "@/_services/authorizationgroup.service";

export default {
    components: {
        ButtonLoading,
        TextBox,
        DropDownList,
        ListBox,
        TreeView
    },
    setup() {

        const route = useRoute();
        const { editdetail, save, DeSelectedAndSelectedUserList, authGroupResult } = AuthorizationGroupService();
        const countryService = CountryService();
        const companyService = CompanyService();

        const submitted = ref(false);
        const ValidationMessage = ref([]);
        const CountryData = ref([{ Id: -1, Name: "Lütfen Bekleyin" }]);
        const CompanyData = ref([{ Id: -1, Name: "" }]);
        const User_DeSelectedList = ref([]);
        const User_SelectedList = ref([]);
        const KeyValue = ref(0);
        const Model = ref({
            Id: 0,
            CountryId: 0,
            CompanyId: 0,
            Name: null,
            User_SelectedList: [],
            PageAuthList: []
        });

        const pageAuthorization_CheckedList = ref([]);

        onMounted(async () => {

            await countryService.dropdownList();

            if (countryService.countryResult.value.Success) {
                CountryData.value = countryService.countryResult.value.Data;
                Model.value.CountryId = 0;
            }


            await editdetail(route.params.id);

            if (authGroupResult.value.Success) {
                Model.value = authGroupResult.value.Data;

                Model.value.PageAuthList.filter(x => x.Active).forEach((item) => {
                    pageAuthorization_CheckedList.value.push({ Id: item.Id, ParentId: item.ParentId });
                });

                authGroupResult.value.Success = false;
                await DeSelectedAndSelectedUserList(route.params.id);

                if (authGroupResult.value.Success) {
                    User_DeSelectedList.value = authGroupResult.value.Data.DeSelectedAndSelectedUserList;
                    User_SelectedList.value = authGroupResult.value.Data.SelectedAndSelectedUserList;
                    KeyValue.value += 1;
                }
            }
        });

        watch(() => Model.value.CountryId, async (newValue, oldValue) => {

            if (newValue > 0) {
                await companyService.dropdownList(newValue);

                if (companyService.companyResult.value.Success) {
                    CompanyData.value = companyService.companyResult.value.Data;

                    if (newValue != oldValue && route.params.id == 0)
                        Model.value.CompanyId = 0;
                    else if (newValue != oldValue && route.params.id > 0 && oldValue > 0)
                        Model.value.CompanyId = 0;
                }
            }
            else {
                CompanyData.value = [{ Id: -1, Name: "" }];
            }


        });


        const AllAdd = () => {

            User_DeSelectedList.value.forEach((item) => {
                User_SelectedList.value.push(item);
            });

            User_DeSelectedList.value = [];

            KeyValue.value += 1;
        }

        const AllRemove = () => {

            User_SelectedList.value.forEach((item) => {
                User_DeSelectedList.value.push(item);
            });

            User_SelectedList.value = [];

            KeyValue.value += 1;
        }


        const Save_Click = async () => {
            if (!submitted.value) {
                submitted.value = true;

                Model.value.User_SelectedList = [];
                User_SelectedList.value.forEach((item) => {
                    Model.value.User_SelectedList.push(item.Value);
                });

                Model.value.PageAuthList.forEach((item) => {
                    if (pageAuthorization_CheckedList.value.filter(x => x.Id == item.Id).length > 0)
                        item.Active = true;
                    else
                        item.Active = false;
                });

                await save(Model.value);

                if (authGroupResult.value.Success)
                    submitted.value = false;
                else {
                    submitted.value = false;

                    ValidationMessage.value = ref([]);
                    if (authGroupResult.value.Data.Messages.filter(x => x.MessageId == -1).length > 0)
                        ValidationMessage.value = authGroupResult.value.Data.Validations;
                }
            }
        }

        const PageAuthorization_SelectChecked = ($event) => {
            pageAuthorization_CheckedList.value = $event.value;
        }

        const DeSelected_UserList_Selected = ($event) => {
            User_DeSelectedList.value.splice(User_DeSelectedList.value.lastIndexOf($event), 1);
            User_SelectedList.value.push($event);

            KeyValue.value += 1;
        }

        const Selected_UserList_Selected = ($event) => {
            User_SelectedList.value.splice(User_SelectedList.value.lastIndexOf($event), 1);
            User_DeSelectedList.value.push($event);

            KeyValue.value += 1;
        }

        return {
            Model,
            ValidationMessage,
            submitted,
            CountryData,
            CompanyData,
            User_DeSelectedList,
            User_SelectedList,
            pageAuthorization_CheckedList,
            KeyValue,
            AllAdd,
            AllRemove,
            Save_Click,
            PageAuthorization_SelectChecked,
            DeSelected_UserList_Selected,
            Selected_UserList_Selected
        };
    }
}
</script>