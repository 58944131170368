import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Toaster from "@meforma/vue-toaster";
import moment from "moment";
import VueGoogleCharts from 'vue-google-charts'
//import PrimeVue from 'primevue/config';

//import "primevue/resources/themes/lara-light-indigo/theme.css";

const app = createApp(App)

app.config.globalProperties.$filters = {
    Date: function (value, format) {

        if (value) {
            return moment(String(value)).format(format || 'DD.MM.YYYY');
        }

        return value;
    },
    N0: function (value) {
        if (value) {
                return new Intl.NumberFormat("tr-TR", {
                    maximumFractionDigits: 0
                  }).format(value);
        }

        return value;
    },
    N2: function (value) {
        if (value) {
                return new Intl.NumberFormat("tr-TR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }).format(value);
        }

        return value;
    },
    FormatUnknow: function (value) {
        return value;
    }
}

Array.prototype.remove = function() {
    var what, a = arguments, L = a.length, ax;
    while (L && this.length) {
        what = a[--L];
        while ((ax = this.indexOf(what)) !== -1) {
            this.splice(ax, 1);
        }
    }
    return this;
};


app
.use(router)
.use(Toaster)
.use(VueGoogleCharts)
//.use(PrimeVue)
.mount('#app');
