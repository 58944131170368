import { ref } from "vue";
import { store } from "../store";
import ResponseService from "./response.service";

import { ErrorNotifi, SuccessNotifi } from "../_helpers/notification";

const workplaceResult = ref({ Success: false, Data: null });
const routePrefix = ref("workplace/");

const list = async () => {

    store.Loading = true;

    const { get, responseResult } = ResponseService();

    await get(routePrefix.value + "list");

    if (responseResult.value) {
        if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
            workplaceResult.value.Success = true;
            workplaceResult.value.Data = responseResult.value.Data;
        }
        else {
            let Messages = responseResult.value.Messages.filter(x => x.MessageId == 0);
            if (Messages.length > 0)
                ErrorNotifi(Messages[0].Message);

                workplaceResult.value.Success = false;
        }

        store.Loading = false;
    }
}

const dropdownList = async (CompanyId) => {

    const { get, responseResult } = ResponseService();

    await get(routePrefix.value + "company-owned-workplace/" + CompanyId);

    if (responseResult.value) {
        if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
            workplaceResult.value.Success = true;
            workplaceResult.value.Data = [{ Id: 0, Name: "Seçiniz" }, ...responseResult.value.Data]
        }
        else {
            let Messages = responseResult.value.Messages.filter(x => x.MessageId == 0);
            if (Messages.length > 0)
                ErrorNotifi(Messages[0].Message);

                workplaceResult.value.Success = false;
        }
    }
}

const WorkPlaceService = () => {
    return { list, dropdownList, workplaceResult }
}

export default WorkPlaceService