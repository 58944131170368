<template>
    <div v-if="DataSource != null">
        <div class="row m-b-15" v-if="DataSource.length > 0">
            <div class="col-3 col-xs-3 col-sm-5 col-md-4 ">

                <ul class="ul-show-data-size" v-if="Properties.Export.MenuShow">
                    <li>
                        <select class="form-control form-control-sm" v-model="exportType">
                            <option value="export" disabled>export</option>
                            <option value="excel">excel</option>
                            <option value="pdf">pdf</option>
                            <option value="pdf-show">pdf göster</option>
                        </select>
                    </li>
                    <li>
                        <ButtonLoading class="btn btn-success btn-sm" v-if="exportType !== 'export'"
                            :Config="{ Disabled: exportSubmitted }" @click="tableExport_Click">
                            <i class="fa fa-check"></i>
                        </ButtonLoading>
                    </li>
                </ul>
            </div>

            <div class="col-9 col-xs-9 col-sm-7 col-md-8 text-right">
                <!--User DataGrid ayarlarını değiştirmek için kullacak. Column yerlerini değiştirmek, kayıt sıralamasını değiştirmek için vb-->
            </div>
        </div>

        <div class="row m-b-15" v-if="dataSource.length > 0">
            <div class="col-12">
                <ul>
                    <li>- İçinde arama yapmak için önce '*' karakterini yazınız. Örnk: *ABC</li>
                </ul>
            </div>
        </div>

        <div class="card" v-if="DataSource.length > 0">
            <div class="table-responsive" style="background-color: #eee;">
                <table :class="{ 'word-wrap-column': properties.WordWrapColumn, 'word-wrap-cell': properties.WordWrapCell }"
                    class="table table-list table-hover"
                    :style="{ 'table-layout': properties.AutoSize ? 'inherit' : 'fixed' }" style="background-color: #fff;">
                    <thead>
                        <tr class="border-bottom-primary">
                            <th width="35px" v-if="properties.Selection.FieldValue != ''"><input type="checkbox"
                                    v-on:change="onAllSelect" v-model="allSelected" /></th>

                            <th v-for="col in properties.Columns" :width="col.Width == null ? '' : col.Width">
                                <a href="javascript:;" @click="Sort_Click(col.FieldName)">{{ col.Caption }}</a>
                                <i v-if="sortValue && col.FieldName == sortFieldName && col.FieldName != ''"
                                    class="fa fa-sort-asc m-l-10"></i>
                                <i v-else-if="!sortValue && col.FieldName == sortFieldName && col.FieldName != ''"
                                    class="fa fa-sort-desc m-l-10"></i>
                            </th>
                        </tr>
                        <tr>
                            <th v-if="properties.Selection.FieldValue != ''"></th>
                            <th v-for="col in properties.Columns.filter(x => x.PrintShow == null || x.PrintShow == true)">
                                <input type="text" class="form-control"
                                    @keyup="Search($event.target.value, col.FieldName)" />
                            </th>
                        </tr>
                    </thead>
                    <tfoot>
                        <tr>
                            <th v-if="properties.Selection.FieldValue != ''"></th>
                            <th v-for="col in properties.Columns">
                                <div v-if="col.SummaryItem">
                                    <span>
                                        {{ col.SummaryItem.Text }}
                                    </span>
                                    <span v-if="col.SummaryItem.SummaryType == 'Count'">
                                        {{ $filters[col.SummaryItem.Format](dataSourceCount) }}
                                    </span>
                                    <span v-else-if="col.SummaryItem.SummaryType == 'Sum'">
                                        {{ $filters[col.SummaryItem.Format](summaryItem[col.FieldName])
                                        }}
                                    </span>
                                </div>
                            </th>
                        </tr>
                    </tfoot>
                    <tbody>
                        <template v-for="(itemRow, rowIndex) in dataSource.slice(indexStart, indexEnd)">
                            <tr :style="RowStyle(itemRow)">



                                <td v-if="properties.Selection.FieldValue != ''">
                                    <input type="checkbox" :value="itemRow[properties.Selection.FieldValue]"
                                        v-model="checkedList" />
                                </td>

                                <td v-for="itemCol in properties.Columns" :style="CellStyle(itemRow, itemCol)">

                                    <!--RouterLink-->
                                    <router-link v-if="itemCol.Component != null && itemCol.Component.Type == 'RouterLink'"
                                        :to="{ name: itemCol.Component.To.Name, params: itemRow.Params }"
                                        :class="itemCol.Css">
                                        <span v-if="itemCol.Component.TextImageRel == 'Icon'"><i
                                                :class="itemCol.Component.IconCss"></i></span>
                                        <span
                                            v-else-if="itemCol.Component.TextImageRel == 'Text' || itemCol.Component.TextImageRel == null">{{
                                                itemRow[itemCol.FieldName]
                                            }}</span>
                                        <span v-else-if="itemCol.Component.TextImageRel == 'ImageBeforeText'"><i
                                                :class="itemCol.Component.IconCss"></i> {{ itemRow[itemCol.FieldName]
                                                }}</span>
                                        <span v-else-if="itemCol.Component.TextImageRel == 'TextBeforeImage'">{{
                                            itemRow[itemCol.FieldName]
                                        }} <i :class="itemCol.Component.IconCss"></i></span>
                                    </router-link>

                                    <!--Link Click-->
                                    <a href="javascript:;"
                                        v-else-if="itemCol.Component != null && itemCol.Component.Type == 'Link'"
                                        @click="$emit('Link_Click', { Value: itemRow[itemCol.Component.Click_FieldName], Key: itemCol.Component.Click_Key })"
                                        :class="itemCol.Css">
                                        <span v-if="itemCol.Component.TextImageRel == 'Icon'"><i
                                                :class="itemCol.Component.IconCss"></i></span>
                                        <span
                                            v-else-if="itemCol.Component.TextImageRel == 'Text' || itemCol.Component.TextImageRel == null">
                                            {{
                                                $filters[itemCol.Format == null ? "FormatUnknow" :
                                                    itemCol.Format](itemRow[itemCol.FieldName])
                                            }}
                                        </span>
                                        <span v-else-if="itemCol.Component.TextImageRel == 'ImageBeforeText'"><i
                                                :class="itemCol.Component.IconCss"></i> {{ itemRow[itemCol.FieldName]
                                                }}</span>
                                        <span v-else-if="itemCol.Component.TextImageRel == 'TextBeforeImage'">{{
                                            itemRow[itemCol.FieldName]
                                        }} <i :class="itemCol.Component.IconCss"></i></span>
                                    </a>

                                    <!--Menü-->
                                    <div v-else-if="itemCol.Component != null && itemCol.Component.Type == 'Menu'" class="dropdown dropdown-inverse">
                                        <button class="btn btn-inverse dropdown-toggle waves-effect waves-light "
                                            type="button" data-toggle="dropdown">
                                            <span v-if="itemCol.Component.TextImageRel == 'Icon'"><i
                                                :class="itemCol.Component.IconCss"></i></span>
                                                <span
                                            v-else-if="itemCol.Component.TextImageRel == 'Text'">
                                            {{
                                                itemCol.Component.Text
                                            }}
                                        </span>
                                        </button>
                                        <div class="dropdown-menu" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut">
                                            <a v-for="v in itemCol.Component.Items" class="dropdown-item waves-light waves-effect"
                                                href="javascript:;"
                                                @click="$emit(v.Click, { Value: itemRow[v.ValueMember], Key: v.Name, RowIndex:rowIndex })">{{ v.Text }}</a>
                                        </div>
                                    </div>

                                    <!--Dropdown List-->
                                    <select
                                        v-else-if="itemCol.Component != null && itemCol.Component.Type == 'DropDownList'"
                                        class="form-control"
                                        @change="DropDownList_Selected(itemCol.Component, $event, itemRow)">
                                        <option v-for="v in itemCol.Component.DataSource"
                                            :key="v[itemCol.Component.ValueMember]"
                                            :value="v[itemCol.Component.ValueMember]"
                                            :selected="v[itemCol.Component.ValueMember] === itemRow[itemCol.FieldName]">
                                            {{ v[itemCol.Component.DisplayMember] }}
                                        </option>
                                    </select>

                                    <!--TextBox-->
                                    <input v-else-if="itemCol.Component != null && itemCol.Component.Type == 'TextBox'"
                                        type="text" class="form-control"
                                        @blur="TextBox_Blur(itemCol.Component.Func, $event, itemRow)"
                                        @keyup.enter="$event.target.blur()"
                                        :value="$filters[itemCol.Component.Format == null ? 'FormatUnknow' : itemCol.Component.Format](itemRow[itemCol.FieldName])"
                                        :class="itemCol.Component.Class" />

                                    <!--Html String-->
                                    <span v-else-if="itemCol.AllowHtmlString != null && itemCol.AllowHtmlString"
                                        v-html="itemRow[itemCol.FieldName]">
                                    </span>
                                    <span v-else>{{
                                        $filters[itemCol.Format == null ? "FormatUnknow" :
                                            itemCol.Format](itemRow[itemCol.FieldName])
                                    }}</span>

                                </td>
                            </tr>
                            <tr v-if="Properties.PreviewLine">
                                <td :colspan="properties.Columns.length + (properties.Selection == null ? 0 : 1)"
                                    :style="Properties.PreviewLine.Style">{{ itemRow[Properties.PreviewLine.FieldName] }}
                                </td>
                            </tr>
                        </template>
                    </tbody>

                </table>
            </div>


        </div>
        <div class="alert alert-info" v-else><strong>Listede kayıt bulunmamaktadır.</strong></div>

        <div class="row" v-if="DataSource.length > 0">
            <div class="col-3 col-xs-3 col-sm-5 col-md-4 ">

                <ul class="ul-show-data-size">
                    <li class="d-sm-none d-none d-sm-inline">Gös. Kyt.</li>
                    <li>
                        <select class="form-control" v-model="pageSize" @change="PageSize_Change">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="250">250</option>
                            <option value="500">500</option>
                        </select>
                    </li>
                </ul>
            </div>

            <div class="col-9 col-xs-9 col-sm-7 col-md-8 text-right" v-if="paginationCount > 1">
                <ul class="pagination justify-content-end">
                    <li class="page-item"><a class="page-link" href="javascript:;" @click="AllPrevPage"><i
                                class="fa fa-angle-double-left"></i></a></li>
                    <li class="page-item"><a class="page-link" href="javascript:;" @click="PrevPage"><i
                                class="fa fa-angle-left"></i></a></li>
                    <li class="page-item">
                        <select class="form-control" v-model="page">
                            <option v-for="item in paginationCount" :value="item">{{ item }}. sayfa</option>
                        </select>
                    </li>
                    <li class="page-item"><a class="page-link" href="javascript:;" @click="NextPage"><i
                                class="fa fa-angle-right"></i></a></li>
                    <li class="page-item"><a class="page-link" href="javascript:;" @click="AllNextPage"><i
                                class="fa fa-angle-double-right"></i></a></li>
                </ul>
            </div>
        </div>

    </div>
</template>

<script>

import { ref, watch } from "vue";
import ButtonLoading from "../ButtonLoading.vue";

import ExportService from "../../_services/export.service";
import ToolService from "../../_helpers/tool";

export default {
    props: ["DataSource", "CheckedList", "PageSize", "Properties"],
    components: { ButtonLoading },
    setup(props, context) {

        const exportSubmitted = ref(false);
        const exportType = ref("export");

        const { valueTypeSearch_fns } = ToolService();

        const page = ref(0);
        const pageSize = ref(50);
        const paginationCount = ref(0);
        const dataSource = ref([]);
        const dataSourceCount = ref(0);
        const checkedList = ref([]);
        const allSelected = ref(false);
        const summaryItem = ref({});
        const indexStart = ref(0)
        const indexEnd = ref(0);
        const sortFieldName = ref('');
        const sortValue = ref(true);
        const searchList = ref([]);
        const properties = ref(
            {
                Export: { Landscape: false, MenuShow: true },
                Selection: { FieldValue: "" },
                StyleFormatRule: [],
                Columns: [],
                WordWrapColumn: false,
                WordWrapCell: false,
                AutoSize: true
            }
        );

        if (props["Properties"] != null) {
            let sortOrders = props["Properties"].Columns.filter(x => x.SortOrder && x.SortOrder != 'None');
            if (sortOrders.length > 0) {
                sortFieldName.value = sortOrders[0].FieldName;
                sortValue.value = sortOrders[0].SortOrder == 'Asc';
            }
        }

        const GetData = (val, fieldName) => {
            let arraySearch = {};

            allSelected.value = false;
            indexStart.value = (page.value - 1) * pageSize.value;
            indexEnd.value = page.value * pageSize.value;

            if ((val != null || val != '') && fieldName != null) {
                let searchMdel = searchList.value.find(x => x.FieldName == fieldName);

                if (searchMdel == null)
                    searchList.value.push({ FieldName: fieldName, Value: val });
                else
                    searchMdel.Value = val;
            }

            searchList.value.forEach(function (v, i) {
                if (v.Value != "") {
                    let fieldName = v.FieldName;
                    let valueType = typeof props["DataSource"][0][fieldName];

                    arraySearch[fieldName] = valueTypeSearch_fns[valueType](v.Value);
                }
            });

            if (Object.keys(arraySearch).length > 0)
                dataSource.value = props["DataSource"].filter(itemSearch, arraySearch).sort(Sort);
            else
                dataSource.value = props["DataSource"].sort(Sort);

            dataSourceCount.value = dataSource.value.length;
            paginationCount.value = Math.ceil(dataSourceCount.value / pageSize.value);

            properties.value.Columns.filter(x => x.SummaryItem != null && x.SummaryItem.SummaryType == "Sum").forEach((col) => {
                let sum = dataSource.value.reduce((acc, item) => acc + item[col.FieldName], 0);
                summaryItem.value[col.FieldName] = sum;
            });
        }

        /*
        const GetData2 = () => {
            if (props["DataSource"] != null) {

                if (props["Properties"] == null) {
                    Object.keys(props["DataSource"][0]).forEach((item) => {
                        properties.value.Columns.push({ Caption: item, FieldName: item });
                    });
                }
                else {
                    if (props["Properties"].Selection != null)
                        properties.value.Selection = props["Properties"].Selection;

                    if (props["Properties"].StyleFormatRule != null)
                        properties.value.StyleFormatRule = props["Properties"].StyleFormatRule;

                    if (props["Properties"].Columns == null) {
                        Object.keys(props["DataSource"][0]).forEach((item) => {
                            properties.value.Columns.push({ Caption: item, FieldName: item });
                        });
                    }
                    else {
                        properties.value.Columns = props["Properties"].Columns;
                    }
                }

                properties.value.WordWrapColumn = props["Properties"].WordWrapColumn;
                properties.value.WordWrapCell = props["Properties"].WordWrapCell;
                properties.value.AutoSize = props["Properties"].AutoSize == null ? true : props["Properties"].AutoSize;

                page.value = 1;
                pageSize.value = props["Properties"].PageSize == null ? pageSize.value : props["Properties"].PageSize;

                GetData(null, null);
            }
        }

        GetData2();
        */

        watch(() => props["DataSource"], (newValue, oldValue) => {
            // GetData2();
            if (props["DataSource"] != null) {

                if (props["Properties"] == null) {
                    Object.keys(props["DataSource"][0]).forEach((item) => {
                        properties.value.Columns.push({ Caption: item, FieldName: item });
                    });
                }
                else {
                    if (props["Properties"].Selection != null)
                        properties.value.Selection = props["Properties"].Selection;

                    if (props["Properties"].StyleFormatRule != null)
                        properties.value.StyleFormatRule = props["Properties"].StyleFormatRule;

                    if (props["Properties"].Columns == null) {
                        Object.keys(props["DataSource"][0]).forEach((item) => {
                            properties.value.Columns.push({ Caption: item, FieldName: item });
                        });
                    }
                    else {
                        properties.value.Columns = props["Properties"].Columns;
                    }
                }

                properties.value.WordWrapColumn = props["Properties"].WordWrapColumn;
                properties.value.WordWrapCell = props["Properties"].WordWrapCell;
                properties.value.AutoSize = props["Properties"].AutoSize == null ? true : props["Properties"].AutoSize;

                page.value = 1;
                pageSize.value = props["Properties"].PageSize == null ? pageSize.value : props["Properties"].PageSize;

                GetData();
            }

        }, { deep: true }
        );

        watch(() => props.CheckedList, (newValue, oldValue) => {
            checkedList.value = props.CheckedList;
        });

        watch(() => checkedList.value, (newValue, oldValue) => {
            context.emit("CheckedList", checkedList);
        });

        watch(() => page.value, (newValue, oldValue) => {
            indexStart.value = (page.value - 1) * pageSize.value;
            indexEnd.value = page.value * pageSize.value;
        });


        const Search = (val, fieldName) => {

            setTimeout(() => {
                page.value = 1;
                GetData(val, fieldName)
            }, 1000);
        }

        function itemSearch(item) {
            return Object.keys(this).every((key) => ConditionalSearch(item, this, key));
        }

        function ConditionalSearch(item, searchItem, key) {
            let valueType = typeof item[key];
            let itemValue = valueTypeSearch_fns[typeof item[key]](item[key]);
            let searchValue = valueTypeSearch_fns[typeof searchItem[key]](searchItem[key]);

            if (searchValue.substring(0, 1) == "*") {
                searchValue = searchValue.substring(1, searchValue.length);

                return itemValue.includes(searchValue);
            }

            return itemValue == null ? false : itemValue.startsWith(searchValue);

            // if (valueType == "string") {
            //     if (searchValue.substring(0, 1) == "*") {
            //         searchValue = searchValue.substring(1, searchValue.length);

            //         return itemValue.includes(searchValue);
            //     }

            //     return itemValue == null ? false : itemValue.startsWith(searchValue);
            // }
            // else
            //     return itemValue === searchValue;
        }


        const RowStyle = (itemRow) => {

            let style = "";

            properties.value.StyleFormatRule.filter(x => x.ApplyToRow).forEach((item) => {
                let returnValue = itemRow[item.FieldName];
                if (item.Rule.RuleType == "FormatConditionRuleValue") {
                    if (item.Rule.Condition == "Equal") {
                        if (returnValue === item.Rule.Value1)
                            style = item.Rule.Appearance;
                    }
                    else if (item.Rule.Condition == "None")
                        style = item.Rule.Appearance;
                }
            });

            return style;
        }

        const CellStyle = (itemRow, itemCol) => {

            let style = "";

            style = itemCol.StyleCell == null ? "" : itemCol.StyleCell;

            properties.value.StyleFormatRule.filter(x => x.ApplyToColumn && x.FieldName == itemCol.FieldName).forEach((item) => {
                let returnValue = itemRow[item.FieldName];
                if (item.Rule.RuleType == "FormatConditionRuleValue") {
                    if (item.Rule.Condition == "Equal") {
                        if (returnValue === item.Rule.Value1)
                            style = item.Rule.Appearance;
                    }
                    else if (item.Rule.Condition == "None")
                        style = item.Rule.Appearance;
                }

            });

            return style;
        }

        const Sort_Click = (field) => {

            sortFieldName.value = field;
            sortValue.value = !sortValue.value;

            GetData();
        }

        function Sort(a, b) {

            let valueType = typeof (props.DataSource[0][sortFieldName.value]);

            let fa = a[sortFieldName.value], fb = b[sortFieldName.value];

            if (sortValue.value) {//Asc
                if (valueType == "string") {
                    return fa.localeCompare(fb);
                }
                else {
                    if (fa < fb) {
                        return -1
                    }
                    if (fa > fb) {
                        return 1
                    }
                    return 0
                }
            }
            else {//Desc
                if (valueType == "string") {
                    return fb.localeCompare(fa);
                }
                else {
                    if (fa > fb) {
                        return -1
                    }
                    if (fa < fb) {
                        return 1
                    }
                    return 0
                }
            }
        }

        const PageSize_Change = () => {
            page.value = 1;
            indexStart.value = (page.value - 1) * pageSize.value;
            indexEnd.value = page.value * pageSize.value;
            paginationCount.value = Math.ceil(dataSourceCount.value / pageSize.value);
        }

        const onAllSelect = () => {
            if (allSelected.value) {
                props["DataSource"].forEach((select) => {
                    checkedList.value.push(select[properties.value.Selection.FieldValue]);

                });
            }
            else
                checkedList.value = [];

            context.emit("CheckedList", checkedList);
        }

        const AllPrevPage = () => {
            page.value = 1;
        }

        const PrevPage = () => {
            page.value = page.value <= 1 ? 1 : page.value - 1;
        }

        const AllNextPage = () => {
            page.value = paginationCount.value;
        }

        const NextPage = () => {
            if (page.value !== paginationCount.value)
                page.value += 1;
        }



        const tableExport_Click = async () => {
            if (!exportSubmitted.value) {
                exportSubmitted.value = true;

                const { tableExport, exportResult } = ExportService();
                const model = ref({
                    GridType: 1,
                    DataSourceJson: JSON.stringify(dataSource.value),
                    PropertiesJson: JSON.stringify(properties.value)
                });

                console.log(properties.value);
                console.log(JSON.stringify(properties.value));

                await tableExport(exportType.value, model.value);

                if (exportResult.value.Success)
                    exportSubmitted.value = false;
                else
                    exportSubmitted.value = false;
            }
        }

        const DropDownList_Selected = (Component, e, row) => {

            let value = null;

            if (Component.ValueType == 'Int')
                value = parseInt(e.target.value);



            context.emit(Component.Func, e.target.value, row);

        }

        const TextBox_Blur = (func, e, row) => {
            context.emit(func, e.target.value, row);
        }



        return {
            dataSource,
            properties,
            allSelected,
            checkedList,
            paginationCount,
            page,
            pageSize,
            dataSourceCount,
            exportSubmitted,
            exportType,
            summaryItem,
            indexStart,
            indexEnd,
            sortFieldName,
            sortValue,
            onAllSelect,
            AllPrevPage,
            AllNextPage,
            PrevPage,
            NextPage,
            PageSize_Change,
            tableExport_Click,
            GetData,
            Search,
            RowStyle,
            CellStyle,
            DropDownList_Selected,
            TextBox_Blur,
            Sort_Click
        }
    }
}
</script>

<style scoped>
thead,
tfoot {
    background: #eee;
}

thead {
    position: sticky;
    top: 0;
    border-bottom: 2px solid #ccc;
}

tfoot {
    position: sticky;
    bottom: 0;
    border-top: 2px solid #ccc;
}

/* td,
th {
    white-space: normal;
} */


.word-wrap-column th {
    white-space: normal;
}

.word-wrap-cell td {
    white-space: normal;
}

.page-link {
    line-height: 1.45;
}

.ul-show-data-size {
    margin: 0px;
    margin-top: 10px !important;
}

.ul-show-data-size li {
    float: left;
}

.ul-show-data-size li:first-child {
    line-height: 35px;
    margin-right: 5px;
}
</style>