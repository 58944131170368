<template>

    <label><b>{{ Config.Title }}</b></label>
    <input type="text" class="form-control" placeholder="Ara" @keydown="Search" v-model="searchValue" />
    <select size="13" class="form-control">
        <option v-for="v in DataSource" :value="v.Value" v-on:dblclick="$emit('SelectedValue', v)">{{ v.Text }}</option>
    </select>

</template>

<script>

import { ref, watch } from 'vue';

export default {
    props: ["Data", "Config", "KeyValue"],
    setup(props, context) {

        const DataSource = ref([]);
        const searchValue = ref(null);
        const secondNumber = ref(1000);

        DataSource.value = props.Data;

        watch(() => props["KeyValue"], (newValue, oldValue) => {
            secondNumber.value = 5;
            Search();
        });

        const Search = ($event) => {
            setTimeout(() => {
                DataSource.value = props.Data.filter(itemSearch);
            }, secondNumber.value);
        }

        function itemSearch(item) {

            if (searchValue.value != null) {

                let itemText = item == null ? "" : item.Text.toLocaleLowerCase('tr-TR');
                let searchValue_ = searchValue.value.toLocaleLowerCase('tr-TR');

                if (searchValue_.substring(0, 1) == "*") {
                    searchValue_ = searchValue_.substring(1, searchValue_.length);

                    return itemText.includes(searchValue_);
                }
                return item == null ? false : itemText.startsWith(searchValue_);
            }
            else
                return true;
        }

        return {
            DataSource,
            searchValue,
            Search
        }
    }
}

</script>