<template>
    <div class="btn-group m-b-10">
        <router-link :to="{ name: 'AvDosyaTakipEdit' }" class="btn btn-dropbox"><i class="fa fa-plus"></i> YENİ
        </router-link>
        <button class="btn btn-pinterest" @click="DeleteClick" :disabled='checkedList.length === 0'><i
                class="fa fa-minus"></i> SİL</button>
        <button class="btn btn-dropbox" @click="RefleshClick"><i class="fa fa-refresh"></i> YENİLE</button>
    </div>

    <DataGrid :DataSource="DataSource" :PageSize="10" :Properties="Properties"
        @CheckedList="checkedList = $event.value">
    </DataGrid>
</template>

<script>

import { ref, onMounted } from "vue";
import { MessageBoxShow } from "../../_helpers/notification";
import DataGrid from "../../components/data_layout/DataGrid";

import AvDosyaTakipService from "../../_services/avdosyatakip.service";

export default {
    components: {
        DataGrid
    },
    setup() {

        const { list, DeleteList, dosyaResult, deleteResult } = AvDosyaTakipService();

        const DataSource = ref(null);
        const checkedList = ref([]);
        const Properties = ref([]);


        Properties.value = {
            Export: { Landscape: true, MenuShow: true },
            Selection: { FieldValue: "Id" },
            StyleFormatRule: [
                { Name: "Format1", ApplyToRow: false, ApplyToColumn: true, FieldName: "DosyaHakkindaGorus", Rule: { RuleType: "FormatConditionRuleValue", Appearance: { "background-color": "#92D050" }, Condition: "Equal", Value1: "TAHSİL EDİLDİ" } }
            ],
            Columns: [
                { Name: "colYil", Caption: "Yıl", FieldName: "Yil" },
                { Name: "colAlacakliFirma", Caption: "Alacaklı Firma", FieldName: "AlacakliFirma" },
                { Name: "colBorcluCariKodu", Caption: "Borçlu Cari Kodu", FieldName: "BorcluCariKodu", Component: { Type: "RouterLink", To: { Name: 'AvDosyaTakipEdit' }, Click: true, Click_FieldName: "Id", Click_Key: "AvDosyaTakipEdit" } },
                { Name: "colBorcluCariAdi", Caption: "Borçlu Cari Adı", FieldName: "BorcluCariAdi", Component: { Type: "RouterLink", To: { Name: 'AvDosyaTakipEdit' }, Click: true, Click_FieldName: "Id", Click_Key: "AvDosyaTakipEdit" }, SummaryItem: { SummaryType: "Count", Format: "N0", Text: "Satır Sayısı = " } },
                { Name: "colEvrakTuru", Caption: "Evrak Türü", FieldName: "EvrakTuru" },
                { Name: "colAsilAlacak", Caption: "Asıl Alacak", FieldName: "AsilAlacak", Format:"N2", SummaryItem: { SummaryType: "Sum", Format: "N2" } },
                { Name: "colDosyaTutari", Caption: "Dosya Tutarı", FieldName: "DosyaTutari", Format:"N2", SummaryItem: { SummaryType: "Sum", Format: "N2" } },
                { Name: "colMasraf", Caption: "Masraf", FieldName: "Masraf", Format:"N2", SummaryItem: { SummaryType: "Sum", Format: "N2" } },
                { Name: "colTahsilatTutari", Caption: "Tahsilat Tutarı", Format:"N2", FieldName: "TahsilatTutari", SummaryItem: { SummaryType: "Sum", Format: "N2" } },
                { Name: "colDosKalanTutar", Caption: "Dos. Kalan Tutar", Format:"N2", FieldName: "DosyadaKalanTutar", SummaryItem: { SummaryType: "Sum", Format: "N2" } },
                { Name: "colAsilAlacakKalan", Caption: "Asıl Alacak Kalan", Format:"N2", FieldName: "AsilAlacakKalan", SummaryItem: { SummaryType: "Sum", Format: "N2" } },
                { Name: "colIli", Caption: "İli", FieldName: "Ili" },
                { Name: "colDosyaDurumu", Caption: "Dosya Durumu", FieldName: "DosyaDurumu" },
                { Name: "colHukukBurosu", Caption: "Hukuk Bürosu", FieldName: "HukukBurosu" },
                { Name: "colDosyaHakkindaGorus", Caption: "Dosya Hakkında Görüş", FieldName: "DosyaHakkindaGorus" },
                { Name: "colOncekiHukukBurosu", Caption: "Önceki Hukuk Bürosu", FieldName: "OncekiHukukBurosu" },
                { Name: "colDetay", Caption: "", FieldName: "", Css: "btn btn-warning btn-sm", Width: "25", Component: { Type: "RouterLink", TextImageRel: "Icon", IconCss: "fa fa-file-text", To: { Name: 'AvDosyaTakipDetail' } }, ExportShow: false }
            ]
        }

        onMounted(async () => {
            await RefleshClick();
        });

        const DeleteClick = async () => {

            MessageBoxShow('Silmek istediğinizden emin misiniz?', 'warning', 'YesNo').then(async (result) => {
                if (result.isConfirmed) {
                    await DeleteList(checkedList.value);

                    if (deleteResult.value.Success) {
                        checkedList.value = [];

                        await RefleshClick();
                    }
                }
            });
        };

        const RefleshClick = async () => {

            await list();

            if (dosyaResult.value.Success) {
                DataSource.value = dosyaResult.value.Data;
                dosyaResult.value.Success = false;
            }
        }

        return { DataSource, Properties, checkedList, DeleteClick, RefleshClick }
    }
}
</script>