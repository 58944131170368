<template>
    <ModalPopUp :Show="PopupShow" Size="big" @PopupClose="PopupClose_Click">
        <template #header>
            Malzeme Hareketleri
        </template>
        <template #body>
            <div v-show="isLoading" class="row">
                <div class="col m-t-15 text-center">
                    Lütfen Bekleyin...
                </div>
            </div>
            <div v-show="!isLoading" class="row m-t-15">
                <div class="col">
                    <DataGrid :DataSource="DataSource" :Properties="Properties"></DataGrid>
                </div>
            </div>
        </template>
    </ModalPopUp>
</template>

<script>

import { ref, watch } from "vue";

import ModalPopUp from "@/components/ModalPopup";
import DataGrid from "@/components/data_layout/DataGrid.vue";

import ProductService from "@/_services.reports/product.service";

export default {
    props: ["Show", "ProductCode"],
    components: {
        ModalPopUp,
        DataGrid
    },
    setup(props, context) {

        const productService = ProductService();

        const PopupShow = ref(false);
        const isLoading = ref(false);

        const DataSource = ref(null);
        const Properties = ref([]);

        Properties.value = {
            Export: { Landscape: false, MenuShow: false },
            WordWrapColumn: true,
            AutoSize: true,
            Name: "Malzeme Hareketleri",
            Columns: [
                { Name: "colFisTarihi", Caption: "Fiş Tarihi", FieldName: "FIS_TARIHI", Width: "125", Format: "Date" },
                { Name: "colFisNo", Caption: "Fiş No", FieldName: "FIS_NO", Width: "175"},
                { Name: "colFisTuru", Caption: "Fiş Türü", FieldName: "FIS_TURU", Width: "175"},
                { Name: "colMiktar", Caption: "Miktar", FieldName: "MIKTAR", Format:"N0", Width: "125"},
                { Name: "colBirimFiyati", Caption: "Birim Fiyatı", FieldName: "BIRIM_FIYATI", Format:"N2", Width: "125"},
                { Name: "colTutar", Caption: "Tutar", FieldName: "TUTAR", Format:"N2", Width: "125"},
                { Name: "colBirimFiyatiNet", Caption: "Birim Fiyatı (Net)", FieldName: "BIRIM_FIYATI_NET", Format:"N2", Width: "125"},
                { Name: "colTutarNet", Caption: "Tutar (Net)", FieldName: "TUTAR_NET", Format:"N2", Width: "125"},
                { Name: "colCariKodu", Caption: "Cari Kodu", FieldName: "CARI_HESAP_KODU", Width: "150"},
                { Name: "colCariAdi", Caption: "Cari Adı", FieldName: "CARI_HESAP_ADI", Width: "250"},
            ]
        }

        watch(() => props["Show"], (newValue, oldValue) => {
            PopupShow.value = props["Show"];

            if (PopupShow.value)
                Line_GetList();
        });

        const PopupClose_Click = () => {
            PopupShow.value = false;
            context.emit("PopupClose", PopupShow.value);
        }

        const Line_GetList = (async () => {

            isLoading.value = true;

            await productService.line_popup(props["ProductCode"]);

            if (productService.result.value.Success) {
                DataSource.value = productService.result.value.Data;
                isLoading.value = false;
            }
        });

        return {
            PopupShow, isLoading, DataSource, Properties, PopupClose_Click
        };
    }
}

</script>