<template>
    <div class="text-center">
        <h1>Oturumunuz Kapatıldı</h1>
        <h3>
            Oturumunuz otomatik kapatılmıştır. Lütfen tekrar sisteme giriş yapınız.<br>
            <a href="javascript:;" @click="SignOut_Click()">Giriş yapmak için tıklayın</a>
        </h3>
    </div>
    

</template>

<script>

import UserService from "@/_services/user.service";

export default{
    setup(){

        const { signOut } = UserService();

        const SignOut_Click = async() => {
            await signOut();
        }

        return{SignOut_Click}
    }
}
</script>

<style scoped>
h1{
    line-height: 70px;
}
h3{
    line-height: 35px;
}
a{
    font-size: 25px;
    text-decoration: 1px red underline;
}
a:hover {
  color: #404E67;
}


</style>