<template>
    <div class="btn-group m-b-10">
        <router-link :to="{ name: 'UserEdit' }" class="btn btn-dropbox"><i class="fa fa-plus"></i> YENİ
        </router-link>
        <button class="btn btn-pinterest" @click="Delete_Click"><i class="fa fa-minus"></i> SİL</button>
        <router-link :to="{ name: 'UserEdit', params: { id: Model.Id } }" class="btn btn-dropbox"><i
                class="fa fa-edit"></i> DÜZENLE
        </router-link>
        <ButtonLoading class="btn btn-dropbox" @click="ResetPassword_Click"
            :Config="{ Disabled: resetPassword_Submitted }">
            <i class="fa fa-refresh"></i> ŞİFRE YENİLEME
        </ButtonLoading>
    </div>

    <div class="col card">

        <div class="row p-t-15">

            <div class="col col-12 col-sm-12 col-md-6">

                <div class="form-group">
                    <label>Adı Soyadı</label><br>
                    {{ Model.NameSurName }}
                </div>

                <div class="form-group">
                    <label>Kullanıcı Adı</label><br>
                    {{ Model.UserName }}
                </div>

                <div class="form-group">
                    <label>Tipi</label><br>
                    {{ Model.UserType_Name }}
                </div>

                <div class="form-group">
                    <label>Aktif/Pasif</label><br>
                    <i v-if="Model.Active" class="fa fa-circle text-c-green"></i><i v-else
                        class="fa fa-circle text-danger"></i>
                </div>

            </div>

            <div class="col col-12 col-sm-12 col-md-6">

                <div class="row">
                    <div class="col col-sm-6 form-group">
                        <label>Oluşturan</label><br>
                        {{ Model.CreatedBy_Text }}
                    </div>

                    <div class="col col-sm-6 form-group">
                        <label>Oluşturulma Tarihi</label><br>
                        {{ Model.CreadedDate_Text }}
                    </div>
                </div>

                <div class="row">
                    <div class="col col-sm-6 form-group">
                        <label>Güncelleyen</label><br>
                        {{ Model.ModifiedBy_Text }}
                    </div>

                    <div class="col col-sm-6 form-group">
                        <label>Güncellenme Tarihi</label><br>
                        {{ Model.ModifiedDate_Text }}
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router"

import { ErrorNotifi } from "@/_helpers/notification";

import ButtonLoading from "@/components/ButtonLoading";

import UserService from "@/_services/user.service";
import AuthService from "@/_services/auth.service";

export default {
    components: {
        ButtonLoading
    },
    setup() {

        const { detail, Delete, userResult } = UserService();
        const { forgotMyPassword, authResult } = AuthService();
        const route = useRoute();

        const resetPassword_Submitted = ref(false);
        const Model = ref({
            Id: 0,
            NameSurName: null,
            EMail: null,
            UserType_Name: null,
            UserName: null,
            Active: null,
            CreatedBy_Text: null,
            CreadedDate_Text: null,
            ModifiedBy_Text: null,
            ModifiedDate_Text: null
        });

        onMounted(async () => {

            if (route.params.id > 0) {
                await detail(route.params.id);

                if (userResult.value.Success)
                    Model.value = userResult.value.Data;
            }
        });

        const Delete_Click = async () => {
            if (route.params.id > 0)
                await Delete(route.params.id);
        }

        const ResetPassword_Click = async () => {
            if ((Model.value.EMail == null || '')) 
                ErrorNotifi('Kullanıcıya ait mail adresi bulunamadı');
            else
            {
                if (!resetPassword_Submitted.value) {
                    resetPassword_Submitted.value = true;

                    await forgotMyPassword(Model.value.EMail);

                    if (authResult.value.Success) 
                        resetPassword_Submitted.value = false;
                    else
                        resetPassword_Submitted.value = false;
                }
            }
        }

        return { Model, resetPassword_Submitted, Delete_Click, ResetPassword_Click };
    }
}
</script>