<template>
    <div class="btn-group m-b-10">
        <router-link :to="{ name: 'PageEdit' }" class="btn btn-dropbox"><i class="fa fa-plus"></i> YENİ
        </router-link>
        <button class="btn btn-pinterest" @click="DeleteClick" :disabled='checkedList.length === 0'><i
                class="fa fa-minus"></i> SİL</button>
        <button class="btn btn-dropbox" @click="RefleshClick"><i class="fa fa-refresh"></i> YENİLE</button>
    </div>

    <div v-if="DataSource != null">

        <div class="card" v-if="DataSource.length > 0">
            <div class="table-responsive" style="background-color: #eee;">
                <table class="table table-list table-hover" style="background-color: #fff;">
                    <thead>
                        <tr class="border-bottom-primary">
                            <th width="15"><input type="checkbox" v-on:change="onAllSelect" v-model="allSelected" />
                            </th>
                            <th>Sayfa Adı</th>
                            <th width="125">Adres</th>
                            <th width="25">Sıra</th>
                            <th width="25">Aktif</th>
                            <th width="25">Gizli</th>
                            <th width="25">Admin<br />Sayfası</th>
                            <th width="1"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="itemRow in DataSource">
                            <td><input type="checkbox" :value="itemRow.Id" v-model="checkedList" /></td>
                            <td :style="{ 'padding-left': itemRow.LeftPad + 'px' }"><router-link
                                    :to="{ name: 'PageEdit', params: { id: itemRow.Id } }">{{
                                        itemRow.Name
                                    }}</router-link>
                            </td>
                            <td>{{ itemRow.Url }}</td>
                            <td>{{ itemRow.LineNumber }}</td>
                            <td class="text-center"><i
                                    :class='"fa fa-circle " + (itemRow.Active ? "text-c-green" : "text-danger")'></i>
                            </td>
                            <td class="text-center"><i
                                    :class='"fa fa-circle " + (itemRow.IsHide ? "text-c-green" : "text-danger")'></i>
                            </td>
                            <td class="text-center"><i
                                    :class='"fa fa-circle " + (itemRow.IsAdminPage ? "text-c-green" : "text-danger")'></i>
                            </td>
                            <td>
                                <router-link :to="{ name: 'PageDetail', params: { id: itemRow.Id } }"
                                    class="btn btn-warning btn-sm"><i class="fa fa-file-text"></i></router-link>
                            </td>
                        </tr>
                    </tbody>

                </table>
            </div>


        </div>
        <div class="alert alert-info" v-else><strong>Listede kayıt bulunmamaktadır.</strong></div>

    </div>

</template>

<script>

import { ref, onMounted } from "vue";
import { MessageBoxShow } from "@/_helpers/notification";

import PageService from "@/_services/page.service";

export default {
    setup() {

        const { list, DeleteList, pageResult, deleteResult } = PageService();

        const DataSource = ref(null);
        const checkedList = ref([]);
        const allSelected = ref(false);

        onMounted(async () => {
            await RefleshClick();
        });

        const onAllSelect = () => {

            if (allSelected.value) {
                DataSource.value.forEach((select) => {
                    checkedList.value.push(select.Id);
                });
            }
            else {
                checkedList.value = [];
            }
        };

        const DeleteClick = async () => {

            MessageBoxShow('Silmek istediğinizden emin misiniz?', 'warning', 'YesNo').then(async (result) => {
                if (result.isConfirmed) {
                    await DeleteList(checkedList.value);

                    if (deleteResult.value.Success) {
                        checkedList.value = [];

                        await RefleshClick();
                    }
                }
            });
        };

        const RefleshClick = async () => {

            await list();

            if (pageResult.value.Success) {
                DataSource.value = pageResult.value.Data;
                pageResult.value.Success = false;
            }
        }

        return { DataSource, checkedList, allSelected, onAllSelect, DeleteClick, RefleshClick }
    }
}
</script>