<template>

    <div class="btn-group m-b-10">
        <router-link :to="{ name: 'AuthGroupEdit' }" class="btn btn-dropbox"><i class="fa fa-plus"></i> YENİ
        </router-link>
        <button class="btn btn-pinterest" @click="DeleteClick" :disabled='checkedList.length === 0'><i
                class="fa fa-minus"></i> SİL</button>
        <button class="btn btn-dropbox" @click="RefleshClick"><i class="fa fa-refresh"></i> YENİLE</button>
    </div>

    <DataGrid :DataSource="DataSource" :PageSize="10" :Properties="Properties"
        @CheckedList="checkedList = $event.value">
    </DataGrid>

</template>

<script>

import { ref, onMounted } from "vue";
import { MessageBoxShow } from "@/_helpers/notification";

import DataGrid from "@/components/data_layout/DataGrid.vue";

import AuthorizationGroupService from "@/_services/authorizationgroup.service";

export default {
    components: {
        DataGrid
    },
    setup() {

        const { list, DeleteList, authGroupResult, deleteResult } = AuthorizationGroupService();

        const DataSource = ref(null);
        const checkedList = ref([]);
        const Properties = ref([]);

        Properties.value = {
            Export: { Landscape: false, MenuShow: false },
            Selection: { FieldValue: "Id" },
            Columns: [
                { Name: "colName", Caption: "Grup Adı", FieldName: "Name", Component: { Type: "RouterLink", To: { Name: 'AuthGroupEdit' } } }
            ]
        }

        onMounted(async () => {
            await RefleshClick();
        });

        const DeleteClick = async () => {

            MessageBoxShow('Silmek istediğinizden emin misiniz?', 'warning', 'YesNo').then(async (result) => {
                if (result.isConfirmed) {
                    await DeleteList(checkedList.value);

                    if (deleteResult.value.Success) {
                        checkedList.value = [];

                        await RefleshClick();
                    }
                }
            });
        };

        const RefleshClick = async () => {

            await list();

            if (authGroupResult.value.Success) {
                DataSource.value = authGroupResult.value.Data;
                authGroupResult.value.Success = false;
            }
        }

        return { DataSource, Properties, checkedList, DeleteClick, RefleshClick }
    }
}
</script>