<template>
    <div class="row">
        <div class="col col-12 col-sm-12 col-md-4">

            <div class="form-group row">
                <label class="col-12 col-sm-4 col-md-4 col-form-label"><b>Server</b></label>
                <div class="col-12 col-sm-8 col-md-8">
                    <select class="form-control" v-model="Model.CountryId">
                        <option v-for="item in CountryAuthList" :value="item.Id">{{ item.Name }}</option>
                    </select>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-12 col-sm-4 col-md-4 col-form-label"><b>Firma</b></label>
                <div class="col-12 col-sm-8 col-md-8">
                    <select class="form-control" v-model="Model.CompanyId">
                        <option v-for="item in CompanyAuthList" :value="item.Id">{{ item.Name }}</option>
                    </select>
                </div>
            </div>

            <div class="form-group row" v-show="(Config == null ? true : !Config.LogoCompany_Hide)">
                <label class="col-12 col-sm-4 col-md-4 col-form-label"><b>Logo Firma</b></label>
                <div class="col-12 col-sm-8 col-md-8">
                    <select class="form-control" v-model="Model.LogoCompanyId">
                        <option v-for="item in LogoCompanyAuthList" :value="item.Id">{{ item.No }} - {{ item.Name }}</option>
                    </select>
                </div>
            </div>

            <div class="form-group row" v-show="(Config == null ? true : !Config.LogoPeriod_Hide)">
                <label class="col-12 col-sm-4 col-md-4 col-form-label"><b>Logo Dönem</b></label>
                <div class="col-12 col-sm-8 col-md-8">
                    <select class="form-control" v-model="Model.LogoPeriodId">
                        <option v-for="item in LogoPeriodAuthList" :value="item.Id">{{ item.No }}</option>
                    </select>
                </div>
            </div>

            <slot name="Row1_Column"></slot>
        </div>

        <slot name="Row1_Column2"></slot>
        <slot name="Row1_Column3"></slot>
    </div>

    <div class="row">
        <slot name="Row2">
            <slot name="Row2_Column1"></slot>
            <slot name="Row2_Column2"></slot>
            <slot name="Row2_Column3"></slot>
        </slot>
    </div>

    
</template>

<script>
import { ref, watch, onMounted } from "vue";
import { store } from "@/store";

export default {
    props: ["Config"],
    setup(props, context) {

        const CountryAuthList = ref([]);
        const CompanyAuthList = ref([]);
        const LogoCompanyAuthList = ref([]);
        const LogoPeriodAuthList = ref([]);
        const Model = ref({
            CountryId: 0,
            CompanyId: 0,
            LogoCompanyId: 0,
            LogoPeriodId: 0
        });

        onMounted(() => {
            if (store.UserAuth != null) {
                CountryAuthList.value = store.UserAuth.CountryAuthList;
                CompanyAuthList.value = store.UserAuth.CompanyAuthList;
                LogoCompanyAuthList.value = store.UserAuth.LogoCompanyAuthList;
                LogoPeriodAuthList.value = store.UserAuth.LogoPeriodAuthList;

                Model.value.CountryId = CountryAuthList.value[0].Id;
            }
        });

        watch(() => Model.value.CountryId, (newValue, oldValue) => {
            CompanyAuthList.value = store.UserAuth.CompanyAuthList.filter(x => x.ParentId == newValue);
            Model.value.CompanyId = CompanyAuthList.value[0].Id;

            context.emit("Selected", Model.value);
        });

        watch(() => Model.value.CompanyId, (newValue, oldValue) => {
            LogoCompanyAuthList.value = store.UserAuth.LogoCompanyAuthList.filter(x => x.ParentId == newValue);

            var DefaultValue = LogoCompanyAuthList.value.find(x => x.DefaultValue > 0);
            if (DefaultValue != null)
                Model.value.LogoCompanyId = DefaultValue.Id;

            context.emit("Selected", Model.value);
        });

        watch(() => Model.value.LogoCompanyId, (newValue, oldValue) => {
            LogoPeriodAuthList.value = store.UserAuth.LogoPeriodAuthList.filter(x => x.ParentId == newValue);

            var DefaultValue = LogoPeriodAuthList.value.find(x => x.DefaultValue > 0);
            if (DefaultValue != null)
                Model.value.LogoPeriodId = DefaultValue.Id;


            context.emit("Selected", Model.value);
        });

        watch(() => Model.value.LogoPeriodId, (newValue, oldValue) => {
            context.emit("Selected", Model.value);
        });

        return { store, Model, CountryAuthList, CompanyAuthList, LogoCompanyAuthList, LogoPeriodAuthList }
    }

}
</script>