import { ref } from "vue";
import { store } from "../store";
import ResponseService from "@/_services/response.service";

import { ErrorNotifi, SuccessNotifi, MessageBoxShow } from "../_helpers/notification";

const result = ref({ Success: false, Data: null });
const routePrefix = ref("dashboard-kpi/");

const ciro = async () => {

    store.Loading = true;

    const { get, responseResult } = ResponseService();

    await get(routePrefix.value + "ciro");

    if (responseResult.value) {

        result.value.Success = true;
        result.value.Data = JSON.parse(responseResult.value.Message);

        store.Loading = false;
    }
}

const depo_envanter = async () => {

    store.Loading = true;

    const { get, responseResult } = ResponseService();

    await get(routePrefix.value + "depo-envanter");

    if (responseResult.value) {

        result.value.Success = true;
        result.value.Data = JSON.parse(responseResult.value.Message);

        store.Loading = false;
    }
}


const DashboardKPIService = () => {
    return { ciro, depo_envanter, result }
}

export default DashboardKPIService