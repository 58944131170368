<template>
    <div class="btn-group m-b-10">
        <router-link :to="{ name: 'ProposalEdit' }" class="btn btn-dropbox"><i class="fa fa-plus"></i> YENİ
        </router-link>
        <button class="btn btn-pinterest" @click="DeleteClick" :disabled='checkedList.length === 0'><i
                class="fa fa-minus"></i> SİL</button>
        <button class="btn btn-dropbox" @click="RefleshClick"><i class="fa fa-refresh"></i> YENİLE</button>
    </div>

    <form class="col card p-t-15">
        <CustomerFilter :Config="{ LogoCompany_Hide: false, LogoPeriod_Hide: false }" @Filter_Click="Filter_Click">
            <template #Row1_Column2>
                <div class="form-group row">
                    <label class="col-12 col-sm-4 col-md-4 col-form-label"><b>Yeni Cari Dahil</b></label>
                    <div class="col-12 col-sm-8 col-md-8">
                        <input type="checkbox" v-model="newCustomer" />
                    </div>
                </div>
            </template>
        </CustomerFilter>

    </form>

    <!-- <DataGrid :DataSource="DataSource" :PageSize="10" :Properties="Properties" @CheckedList="checkedList = $event.value">
    </DataGrid> -->
</template>

<script>

import { ref, onMounted } from "vue";
import { MessageBoxShow } from "@/_helpers/notification";

import DataGrid from "@/components/data_layout/DataGrid.vue";
import CustomerFilter from "@/components/filter/CustomerFilter"

import UserService from "@/_services/user.service";

export default {
    components: {
        DataGrid,
        CustomerFilter
    },
    setup() {

        const { list, DeleteList, userResult, deleteResult } = UserService();

        const DataSource = ref(null);
        const checkedList = ref([]);
        const Properties = ref([]);
        const newCustomer = ref(false);

        Properties.value = {
            Export: { Landscape: false, MenuShow: false },
            Selection: { FieldValue: "Id" },
            StyleFormatRule: [
                { Name: "Format1", ApplyToRow: false, ApplyToColumn: true, FieldName: "Active_Text", Rule: { RuleType: "FormatConditionRuleValue", Appearance: { "text-align": "center" }, Condition: "None" } }
            ],
            Columns: [
                { Name: "colNameSurName", Caption: "Adı Soyadı", FieldName: "NameSurName", Component: { Type: "RouterLink", To: { Name: 'UserEdit' } } },
                { Name: "colUserName", Caption: "Kullanıcı Adı", FieldName: "UserName", Component: { Type: "RouterLink", To: { Name: 'UserEdit' } }, SummaryItem: { SummaryType: "Count", Format: "N0", Text: "Satır Sayısı = " } },
                { Name: "colUserTypeName", Caption: "Tipi", FieldName: "UserType_Name", Width: "125" },
                { Name: "colActive", Caption: "Aktif", FieldName: "Active_Text", Width: "75", AllowHtmlString: true },
                { Name: "colPageAuthorization", Caption: "", FieldName: "", Css: "btn btn-instagram btn-sm", Width: "25", Component: { Type: "RouterLink", TextImageRel: "Icon", IconCss: "fa fa-key", To: { Name: 'UserAuthorizations' } }, ExportShow: false },
                { Name: "colDetail", Caption: "", FieldName: "", Css: "btn btn-warning btn-sm", Width: "25", Component: { Type: "RouterLink", TextImageRel: "Icon", IconCss: "fa fa-file-text", To: { Name: 'UserDetail' } }, ExportShow: false }
            ]
        }

        // onMounted(async () => {
        //     await RefleshClick();
        // });

        const DeleteClick = async () => {

            MessageBoxShow('Silmek istediğinizden emin misiniz?', 'warning', 'YesNo').then(async (result) => {
                if (result.isConfirmed) {
                    await DeleteList(checkedList.value);

                    if (deleteResult.value.Success) {
                        checkedList.value = [];

                        await RefleshClick();
                    }
                }
            });
        };

        const RefleshClick = async () => {

            await list();

            if (userResult.value.Success) {
                DataSource.value = userResult.value.Data;
                userResult.value.Success = false;
            }
        }

        const Filter_Click = ($event)=>{
            console.log($event);
        }

        return { DataSource, Properties, checkedList, newCustomer, DeleteClick, RefleshClick, Filter_Click }
    }
}
</script>