<template>
    <ModalPopUp :Show="PopupShow" Size="small" @PopupClose="PopupClose_Click">
        <template #header>
            Satış Fiyatları
        </template>
        <template #body>
            <div v-show="isLoading" class="row">
                <div class="col m-t-15 text-center">
                    Lütfen Bekleyin...
                </div>
            </div>
            <div v-show="!isLoading" class="row m-t-15">
                <div class="col">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Satış Fiyatı</th>
                                <th>İsk %</th>
                                <th>İsk. Fiyat</th>
                                <th>Ticari İşl. Grubu</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="row in priceData">
                                <td>{{ $filters["N2"](row.PRICE) }}</td>
                                <td>{{ row.DISCOUNT }}</td>
                                <td>{{ $filters["N2"](row.DISCOUNT_PRICE) }}</td>
                                <td>{{ row.TRADINGGRP }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </template>
    </ModalPopUp>
</template>

<script>

import { ref, watch } from "vue";

import ModalPopUp from "@/components/ModalPopup";

import PriceService from "@/_services.logo/price.service";

export default {
    props: ["Show", "ProductCode"],
    components: {
        ModalPopUp
    },
    setup(props, context) {

        const priceService = PriceService();

        const PopupShow = ref(false);
        const isLoading = ref(false);
        const priceData = ref([]);

        watch(() => props["Show"], (newValue, oldValue) => {
            PopupShow.value = props["Show"];

            if (PopupShow.value)
                Price_GetList();
        });

        const PopupClose_Click = () => {
            PopupShow.value = false;
            context.emit("PopupClose", PopupShow.value);
        }

        const Price_GetList = (async () => {

            isLoading.value = true;

            await priceService.sales_price_list_popup(props["ProductCode"]);

            if (priceService.result.value.Success) {
                priceData.value = priceService.result.value.Data;
                isLoading.value = false;
            }
        });

        return {
            PopupShow, isLoading, priceData, PopupClose_Click
        };
    }
}

</script>