import { ref } from "vue";
import { store } from "../store";
import ResponseService from "./response.service";

import { ErrorNotifi, SuccessNotifi } from "../_helpers/notification";

const companyResult = ref({ Success: false, Data: null });
const routePrefix = ref("company/");

const list = async () => {

    store.Loading = true;

    const { get, responseResult } = ResponseService();

    await get(routePrefix.value + "list");

    if (responseResult.value) {
        if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
            companyResult.value.Success = true;
            companyResult.value.Data = responseResult.value.Data;
        }
        else {
            let Messages = responseResult.value.Messages.filter(x => x.MessageId == 0);
            if (Messages.length > 0)
                ErrorNotifi(Messages[0].Message);

            companyResult.value.Success = false;
        }

        store.Loading = false;
    }
}

const dropdownList = async (CountryId) => {

    const { get, responseResult } = ResponseService();

    await get(routePrefix.value + "country-owned-company/" + CountryId);

    if (responseResult.value) {
        if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
            companyResult.value.Success = true;
            companyResult.value.Data = [{ Id: 0, Name: "Seçiniz" }, ...responseResult.value.Data]
        }
        else {
            let Messages = responseResult.value.Messages.filter(x => x.MessageId == 0);
            if (Messages.length > 0)
                ErrorNotifi(Messages[0].Message);

            companyResult.value.Success = false;
        }
    }
}

const CompanyService = () => {
    return { list, dropdownList, companyResult }
}

export default CompanyService