<template>
    <!--v-if ile göstermeyi yaparsak veriler gidiyor. Çünkü v-if cache den temizlemiş oluyor. v-show cache kalıyor.-->
    <transition v-show="Show" name="modal-fade">
        <div class="modal-backdrop">
            <span class="modal" :class="('popup-' + Size)" role="dialog" aria-labelledby="modalTitle"
                aria-describedby="modalDescription">
                <header class="modal-header" id="modalTitle">
                    <slot name="header"></slot>
                    <button type="button" class="btn-close" @click="$emit('PopupClose')" aria-label="Close modal">
                        x
                    </button>
                </header>

                <section class="modal-body" id="modalDescription">
                    <slot name="body">
                        <div class="text-center">
                            
                        </div>
                    </slot>
                </section>

                <footer class="modal-footer">
                    <slot name="footer">

                    </slot>
                </footer>
            </span>
        </div>
    </transition>
</template>

<script>

export default{
    name:"Modal",
    props:["Show", "Size"]
}

</script>

<style scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
}

.modal {
    position: absolute;
    top: 3%;
    bottom: 3%;
    margin: 0 auto;
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: table;
    flex-direction: column;
    width: 99%;
}

.popup-small {
    width: 450px;
}

.popup-medium {
    width: 850px;
}

.popup-big {
    width: 1250px;
}

.popup-full {
    width: 99%;
}

.modal-header,
.modal-footer {
    padding: 15px;
    display: flex;
}

.modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #4AAE9B;
    justify-content: space-between;
}

.modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
}

.modal-body {
    position: relative;
    padding: 20px 10px;
}


.btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #4AAE9B;
    background: transparent;
}

.btn-green {
    color: white;
    background: #4AAE9B;
    border: 1px solid #4AAE9B;
    border-radius: 2px;
}

.modal-fade-enter,
.modal-fade-leave-to {
    opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
    transition: opacity .5s ease;
}

@media (max-width: 767px) {
    .modal {
        width: 99%;
    }
}

@media (max-width: 880px) {
    .modal {
        width: 99%;
    }
}

@media (max-width: 1280px) {
    .modal {
        width: 99%;
    }
}
</style>