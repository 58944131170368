import { reactive } from 'vue'

export const store = reactive({
  PageTitle: null,
  UserModel: JSON.parse(localStorage.getItem("user")),
  UserAuth: null,
  userPublicPages:["/", "/loading"],
  AuthLoading: false,
  Loading: false,
  RefreshReturnUrl: null
});

export const proposal_store = reactive({
    Item_DataSource: []
});