import { ref } from "vue";
import axios from "axios";
import router from "../router"
import { store } from "../store";
import { ErrorNotifi } from "../_helpers/notification";

//const apiUrl = "http://localhost:1910/api/";
const apiUrl = "https://api.erol.me/api/";

function authHeader() {
    let user = JSON.parse(localStorage.getItem('user'));


    if (user && user.Token) {
        return {
            'token': user.Token,

        };
    } else {
        return {
            'token': ''
        };
    }
}

function errorConfig(error) {
    if (error.code == "ERR_NETWORK")
        ErrorNotifi("Sistem Server'a bağlanamıyor. Lütfen sistem yöneticisi ile iletişime geçiniz");
    else {
        store.Loading = false;

        switch (error.response.status) {
            case 401:
                router.push({ name: "MessageLoggedOut" });
                break;
            case 404:
                ErrorNotifi("Adres bulunamadı");
                break;
            case 413:
                ErrorNotifi("Hata oluştu : Veri çok fazla");
                break;
            case 500:
                ErrorNotifi(error.response.status + " (" + error.response.statusText + ")</br>" + error.response.data.ExceptionMessage);
                break;
            default:
                ErrorNotifi(error.response.data.Message);
                break;
        }
    }
}

const responseResult = ref(null);

const post = async (url, data) => {

    await axios.post(apiUrl + url, data, { headers: authHeader() })
        .then(res => {
            if (res.data.Messages != null) {
                let ErrorList = res.data.Messages.filter(x => x.MessageId == -2);
                if (ErrorList.length > 0) {
                    ErrorNotifi(ErrorList[0].Message);
                }
            }

            responseResult.value = res.data;
        }).catch(error => {
            responseResult.value = null;
            errorConfig(error);
        });
}

const get = async (url) => {

    await axios.get(apiUrl + url, { headers: authHeader() })
        .then(res => {

            if (res.data.Messages != null) {
                let ErrorList = res.data.Messages.filter(x => x.MessageId == -2);
                if (ErrorList.length > 0) {
                    ErrorNotifi(ErrorList[0].Message);
                }
            }

            responseResult.value = res.data;
        }).catch(error => {
            responseResult.value = null;
            errorConfig(error);
        });

}

const Delete = async (url) => {

    await axios.delete(apiUrl + url, { headers: authHeader() })
        .then(res => {
            let ErrorList = res.data.Messages.filter(x => x.MessageId == -2);
            if (ErrorList.length > 0) {
                ErrorNotifi(ErrorList[0].Message);
            }

            responseResult.value = res.data;
        }).catch(error => {
            responseResult.value = null;
            errorConfig(error);
        });
}

const exportData = async (url, data) => {

    await axios.post(apiUrl + url, data, { headers: authHeader(), responseType: "blob" })
        .then(res => {
            responseResult.value = res.data;
        }).catch(error => {
            responseResult.value = null;
            errorConfig(error);
        });
}

const ResponseService = () => {
    return { get, post, Delete, exportData, responseResult }
}

export default ResponseService