<template>
    <form @submit.prevent="Save_Click">
        <div class="btn-group m-b-10">
            <ButtonLoading class="btn btn-success" :Config="{ Disabled: submitted }">
                <i class="fa fa-check"></i> KAYDET
            </ButtonLoading>
        </div>

        <div class="col card">

            <div class="row p-t-15">

                <input type="hidden" v-model="Model.Id" />

                <div class="col col-12 col-sm-12 col-md-6">

                    <div class="form-group">

                        <TextBox
                            :Config="{ Name: 'Name', Caption: 'Adı', Placeholder: 'Adı', Type:'text', Required: true, ValidationMessage: ValidationMessage['Name'] }"
                            v-model:Value="Model.Name"
                            ></TextBox>
                    </div>

                    <div class="form-group">

                        <TextBox
                            :Config="{ Name: 'SurName', Caption: 'Soyadı', Placeholder: 'Soyadı', Type:'text', Required: true, ValidationMessage: ValidationMessage['SurName'] }"
                            v-model:Value="Model.SurName"
                            ></TextBox>
                    </div>

                    <div class="form-group">

                        <TextBox
                            :Config="{ Name: 'EMail', Caption: 'E-Mail', Placeholder: 'E-Mail', Type:'email', Required: true, ValidationMessage: ValidationMessage['EMail'] }"
                            v-model:Value="Model.EMail"
                            ></TextBox>
                    </div>


                    <div class="form-group">

                        <DropDownList
                        :Config="{ Name: 'DepartmentId', Caption: 'Departman', Required: true, DataText:'Name', DataValue:'Id', DisabledFieldName:'Id', DisabledValue : -1, ValidationMessage: ValidationMessage['DepartmentId'] }"
                        :Data="DepartmentData"
                        v-model:Value="Model.DepartmentId"></DropDownList>

                    </div>

                    <div class="form-group">

                        <DropDownList
                        :Config="{ Name: 'PositionId', Caption: 'Görevi', Required: true, DataText:'Name', DataValue:'Id', DisabledFieldName:'Id', DisabledValue : -1, ValidationMessage: ValidationMessage['PositionId'] }"
                        :Data="PositionData"
                        v-model:Value="Model.PositionId"></DropDownList>

                    </div>

                    <div class="form-group">

                        <DropDownList
                        :Config="{ Name: 'TitleId', Caption: 'Ünvanı', Required: true, DataText:'Name', DataValue:'Id', DisabledFieldName:'Id', DisabledValue : -1, ValidationMessage: ValidationMessage['TitleId'] }"
                        :Data="TitleData"
                        v-model:Value="Model.TitleId"></DropDownList>
                    </div>

                </div>

                <div class="col col-12 col-sm-12 col-md-6">

                    <div class="form-group">

                        <DropDownList
                        :Config="{ Name: 'CountryId', Caption: 'Ülke', Required: true, DataText:'Name', DataValue:'Id', DisabledFieldName:'Id', DisabledValue : -1, ValidationMessage: ValidationMessage['CountryId'] }"
                        :Data="CountryData"
                        v-model:Value="Model.CountryId"></DropDownList>
                    </div>

                    <div class="form-group">

                        <DropDownList
                        :Config="{ Name: 'CompanyId', Caption: 'Şirket', Required: true, DataText:'Name', DataValue:'Id', DisabledFieldName:'Id', DisabledValue : -1, ValidationMessage: ValidationMessage['CompanyId'] }"
                        :Data="CompanyData"
                        v-model:Value="Model.CompanyId"></DropDownList>
                    </div>

                    <div class="form-group">

                        <DropDownList
                        :Config="{ Name: 'WorkPlaceId', Caption: 'İş Yeri', Required: true, DataText:'Name', DataValue:'Id', DisabledFieldName:'Id', DisabledValue : -1, ValidationMessage: ValidationMessage['WorkPlaceId'] }"
                        :Data="WorkPlaceData"
                        v-model:Value="Model.WorkPlaceId"></DropDownList>
                    </div>

                    <div class="form-group">

                        <DropDownList
                        :Config="{ Name: 'EmployeeTypeId', Caption: 'Personel Tipi', Required: true, DataText:'Name', DataValue:'Id', DisabledFieldName:'Id', DisabledValue : -1, ValidationMessage: ValidationMessage['EmployeeTypeId'] }"
                        :Data="EmployeeTypeData"
                        v-model:Value="Model.EmployeeTypeId"></DropDownList>

                    </div>

                </div>

            </div>

        </div>
    </form>
</template>

<script>

import { ref, onMounted, watch } from "vue";
import { useRoute } from "vue-router"
import ButtonLoading from "@/components/ButtonLoading";
import TextBox from "@/components/textbox/TextBox";
import DropDownList from "@/components/dropdownlist/DropDownList";

import DepartmentService from "@/_services/department.service";
import PositionService from "@/_services/position.service";
import TitleService from "@/_services/title.service";
import CountryService from "@/_services/country.service";
import CompanyService from "@/_services/company.service";
import WorkPlaceService from "@/_services/workplace.service";
import EmployeeTypeService from "@/_services/employeetype.service";

import EmployeeService from "@/_services/employee.service";

export default {
    components: {
        ButtonLoading,
        TextBox,
        DropDownList
    },
    setup() {

        const route = useRoute();
        const { editdetail, save, employeeResult } = EmployeeService();
        const departmentService = DepartmentService();
        const positionService = PositionService();
        const titleService = TitleService();
        const countryService = CountryService();
        const companyService = CompanyService();
        const workplaceService = WorkPlaceService();
        const employeeTypeService = EmployeeTypeService();

        const submitted = ref(false);
        const DepartmentData = ref([{ Id: -1, Name: "Lütfen Bekleyin" }]);
        const PositionData = ref([{ Id: -1, Name: "Lütfen Bekleyin" }]);
        const TitleData = ref([{ Id: -1, Name: "Lütfen Bekleyin" }]);
        const CountryData = ref([{ Id: -1, Name: "Lütfen Bekleyin" }]);
        const CompanyData = ref([{ Id: -1, Name: "" }]);
        const WorkPlaceData = ref([{ Id: -1, Name: "" }]);
        const EmployeeTypeData = ref([{ Id: -1, Name: "Lütfen Bekleyin" }]);
        const ValidationMessage = ref([]);
        const Model = ref({
            Id: 0,
            Name: null,
            SurName: null,
            EMail: null,
            TitleId: -1,
            DepartmentId: -1,
            PositionId: -1,
            CountryId: -1,
            CompanyId: -1,
            WorkPlaceId: -1,
            EmployeeTypeId: -1
        });

        onMounted(async () => {

            await departmentService.dropdownList();

            if (departmentService.departmentResult.value.Success) {
                DepartmentData.value = departmentService.departmentResult.value.Data;
                Model.value.DepartmentId = 0;
            }


            await positionService.dropdownList();

            if (positionService.positionResult.value.Success) {
                PositionData.value = positionService.positionResult.value.Data;
                Model.value.PositionId = 0;
            }


            await titleService.dropdownList();

            if (titleService.titleResult.value.Success) {
                TitleData.value = titleService.titleResult.value.Data;
                Model.value.TitleId = 0;
            }


            await countryService.dropdownList();

            if (countryService.countryResult.value.Success) {
                CountryData.value = countryService.countryResult.value.Data;
                Model.value.CountryId = 0;
            }


            await employeeTypeService.dropdownList();

            if (employeeTypeService.employeeTypeResult.value.Success) {
                EmployeeTypeData.value = employeeTypeService.employeeTypeResult.value.Data;
                Model.value.EmployeeTypeId = 0;
            }

            if (route.params.id > 0) {

                await editdetail(route.params.id);

                if (employeeResult.value.Success)
                    Model.value = employeeResult.value.Data;
            }
        });

        watch(() => Model.value.CountryId, async (newValue, oldValue) => {

            if (newValue > 0) {
                await companyService.dropdownList(newValue);

                if (companyService.companyResult.value.Success) {
                    CompanyData.value = companyService.companyResult.value.Data;

                    if(newValue != oldValue && route.params.id == 0)
                        Model.value.CompanyId = 0;
                    else if(newValue != oldValue && route.params.id > 0 && oldValue > 0)
                        Model.value.CompanyId = 0;
                }
            }
            else {
                CompanyData.value = [{ Id: -1, Name: "" }];
                WorkPlaceData.value = [{ Id: -1, Name: "" }];
            }


        });

        watch(() => Model.value.CompanyId, async (newValue, oldValue) => {

            if (newValue > 0) {
                await workplaceService.dropdownList(newValue);

                if (workplaceService.workplaceResult.value.Success) {
                    WorkPlaceData.value = workplaceService.workplaceResult.value.Data;

                    if(newValue != oldValue && route.params.id == 0)
                        Model.value.WorkPlaceId = 0;
                    else if(newValue != oldValue && route.params.id > 0 && oldValue > 0)
                        Model.value.WorkPlaceId = 0;
                }
            }
            else
                WorkPlaceData.value = [{ Id: -1, Name: "" }];
        });



        const Save_Click = async () => {
            if (!submitted.value) {
                submitted.value = true;

                await save(Model.value);

                if (employeeResult.value.Success)
                    submitted.value = false;
                else {
                    submitted.value = false;

                    ValidationMessage.value = ref([]);
                    if (employeeResult.value.Data.Messages.filter(x => x.MessageId == -1).length > 0)
                        ValidationMessage.value = employeeResult.value.Data.Validations;
                }
            }
        }

        return {
            Model,
            ValidationMessage,
            submitted,
            DepartmentData,
            PositionData,
            TitleData,
            CountryData,
            CompanyData,
            WorkPlaceData,
            EmployeeTypeData,
            Save_Click
        };
    }
}
</script>