<template>
    <button :disabled="Config.Disabled">
        <slot v-if="!Config.Disabled">

        </slot>
        <img v-else src="/images/btn-loading.gif" />
    </button>
</template>

<script>

export default {
    props: ["Config"]
}

</script>