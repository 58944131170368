import { ref } from "vue";
import { store } from "../store";
import ResponseService from "./response.service";
import { SuccessNotifi, ErrorNotifi } from "../_helpers/notification";
import router from "../router";

const authResult = ref({ Success: false, Messages: [] });
const routePrefix = ref("auth/");

const login = async (userName, password) => {

    const { post, responseResult } = ResponseService();

    await post(routePrefix.value + "login", { UserName: userName, Password: password });

    if (responseResult.value) {
        if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
            authResult.value.Success = true;

            localStorage.setItem("user", JSON.stringify(responseResult.value.Data));
            location.href = "/";//bu şekilde yönlendirme yapmayınca tasarım bozuk oluyor.
        }
        else {
            let Messages = responseResult.value.Messages.filter(x => x.MessageId == 0);
            if (Messages.length > 0)
                authResult.value.Messages = Messages;

            if (responseResult.value.Messages.filter(x => x.MessageId == -1).length > 0)
                authResult.value.Messages = responseResult.value.Validations;

            authResult.value.Success = false;
        }
    }

}

const forgotMyPassword = async (email) => {

    const { post, responseResult } = ResponseService();

    await post(routePrefix.value + "forgot-my-password", { email: email });

    if (responseResult.value) {
        if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
            authResult.value.Success = true;
            SuccessNotifi("Mail gönderildi. Mailinizi kontrol ediniz.");
        }
        else {
            let Messages = responseResult.value.Messages.filter(x => x.MessageId == 0);
            if (Messages.length > 0)
                authResult.value.Messages = Messages;

            if (responseResult.value.Messages.filter(x => x.MessageId == -1).length > 0)
                authResult.value.Messages = responseResult.value.Validations;

            authResult.value.Success = false;
        }
    }

}

const activationControl = async (code) => {

    const { get, responseResult } = ResponseService();

    await get(routePrefix.value + "activation-control/" + code);

    if (responseResult.value) {
        let Messages = responseResult.value.Messages.filter(x => x.MessageId == 1);
        if (Messages.length > 0)
            authResult.value.Success = true;
        else {
            let Messages = responseResult.value.Messages.filter(x => x.MessageId == 0);
            if (Messages.length > 0)
                authResult.value.Messages = Messages;

            authResult.value.Success = false;
        }
    }
    else {
        authResult.value.Success = false;
        authResult.value.Messages.push({ Message: "Server'a bağlantı yapılamadı" });
    }
}

const passwordRePasswordSave = async (password, rePassword, code) => {

    const { post, responseResult } = ResponseService();

    await post(routePrefix.value + "password-repassword-save", { Password: password, RePassword: rePassword, ActivateCode: code });

    if (responseResult.value) {
        if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
            router.push({ name: "Login" });
        }
        else {
            let Messages = responseResult.value.Messages.filter(x => x.MessageId == 0);
            if (Messages.length > 0)
                authResult.value.Messages = Messages;

            if (responseResult.value.Messages.filter(x => x.MessageId == -1).length > 0)
                authResult.value.Messages = responseResult.value.Validations;

            authResult.value.Success = false;
        }
    }

}

const getUser = async (IsBtnClick) => {

    store.AuthLoading = true;

    const { get, responseResult } = ResponseService();

    await get(routePrefix.value + "getuser");

    if (responseResult.value) {

        if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
            let messageModel = responseResult.value.Messages.filter(x => x.MessageId == 1);

            if (IsBtnClick)
                SuccessNotifi(messageModel[0].Message);


            store.UserAuth = responseResult.value.Data.UserAuth;
            store.userPublicPages = ["/", "/loading"];

            responseResult.value.Data.UserAuth.PageAuthList.filter(x => x.Url !== "").forEach((item) => {
                if (item.Url !== "")
                    store.userPublicPages.push(item.Url);
            });

            store.userPublicPages.push("/message/loggedout");


            delete responseResult.value.Data["UserAuth"];
            localStorage.setItem("user", JSON.stringify(responseResult.value.Data));
            store.UserModel = responseResult.value.Data;
            authResult.value.Success = true;
        }
        else {
            let Messages = responseResult.value.Messages.filter(x => x.MessageId == 0);
            if (Messages.length > 0)
                ErrorNotifi(Messages[0].Message);

            authResult.value.Data = responseResult.value;
            authResult.value.Success = false;
        }

        store.AuthLoading = false;
    }
}

const AuthService = () => {
    return { login, forgotMyPassword, activationControl, passwordRePasswordSave, getUser, authResult }
}

export default AuthService