<template>
    <div class="col card p-t-15">
        <!--tab control start-->

        <div class="row">
            <div class="col">
                <ul id="ulTabProposal" class="nav nav-tabs md-tabs" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" data-toggle="tab" href="#tabItems"
                            @click="Tab_Click('tabItems')">MALZEMELER</a>
                        <div class="slide"></div>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#tabCustomerInfo"
                            @click="Tab_Click('tabCustomerInfo')">CARİ BİLGİLERİ</a>
                        <div class="slide"></div>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#tabProposalText"
                            @click="Tab_Click('tabProposalText')">AÇIKLAMA</a>
                        <div class="slide"></div>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#tabSave" @click="Tab_Click('tabSave')">KAYDET</a>
                        <div class="slide"></div>
                    </li>
                </ul>
                <div class="tab-content card-block">
                    <div class="tab-pane active" id="tabItems" role="tabpanel">
                        <Items :TabChange="ItemsTabChange" @SetData="SetData"></Items>
                    </div>
                    <div class="tab-pane" id="tabCustomerInfo" role="tabpanel">
                        <Customer :TabChange="CustomerTabChange" @SetData="SetData"></Customer>
                    </div>
                    <div class="tab-pane" id="tabProposalText" role="tabpanel">
                        <Exp :TabChange="ExpTabChange" @SetData="SetData"></Exp>
                    </div>
                    <div class="tab-pane" id="tabSave" role="tabpanel">
                        <Save :TabChange="SaveTabChange"></Save>
                    </div>
                </div>
            </div>
        </div>

        <!--tab control end-->
    </div>
</template>

<script>

import { ref, onMounted } from "vue";
import { proposal_store } from "@/store";

import Items from "@/views/proposal_management/proposals/Components/Proposal_Items.vue";
import Customer from "@/views/proposal_management/proposals/Components/Proposal_Customer.vue";
import Exp from "@/views/proposal_management/proposals/Components/Proposal_Exp.vue";
import Save from "@/views/proposal_management/proposals/Components/Proposal_Save.vue";

export default {

    components: {
        Items,
        Customer,
        Exp,
        Save
    },
    setup() {
        const ItemsTabChange = ref(0);
        const CustomerTabChange = ref(0);
        const ExpTabChange = ref(0);
        const SaveTabChange = ref(0);

        let TabActive = "tabItems";

        const Tab_Click = (tabName) => {
            if (TabActive == "tabItems")
                ItemsTabChange.value += 1;
            else if (TabActive == "tabCustomerInfo")
                CustomerTabChange.value += 1;
            else if (TabActive == "tabProposalText")
                ExpTabChange.value += 1;
            else if (TabActive == "tabSave")
                SaveTabChange.value += 1;

            TabActive = tabName;
        }

        const SetData = (tabName) => {

        }

        onMounted(() => {
           

        });

        return { proposal_store, ItemsTabChange, CustomerTabChange, ExpTabChange, SaveTabChange, Tab_Click, SetData }
    }

}

</script>