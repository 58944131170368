<template>
<button @click="Tikla">Tıkla</button>
</template>

<script>

import { ref, onMounted, watch } from "vue";
import { proposal_store } from "@/store";

export default {
    props: ["TabChange"],
    setup(props, context) {

        watch(() => props["TabChange"], (newValue, oldValue) => {
            //context.emit("CheckedList", checkedList);
        });

        const Tikla = ()=>{
            proposal_store.Item_DataSource.push(
                {
                    MALZEME_ID: 1,
                    MUH_KODU: "hop dedik",
                    MALZEME_KODU: "MALZEME_KODU_1",
                    MALZEME_ADI: "MALZEME_ADI_1",
                    TIG_ID: 1
                }
            );
        }

        onMounted(() => {

            
        });

        return {  Tikla}
    }
}

</script>
