import { ref } from "vue";
import { store } from "../store";
import ResponseService from "./response.service";
import { ErrorNotifi, SuccessNotifi, MessageBoxShow } from "../_helpers/notification";
import router from "../router";

const dosyaResult = ref({ Success: false, Data: null });
const deleteResult = ref({ Success: false });
const routePrefix = ref("avdosyatakip/");

const list = async () => {

    store.Loading = true;

    const { get, responseResult } = ResponseService();

    await get(routePrefix.value + "list");

    if (responseResult.value) {
        if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
            dosyaResult.value.Success = true;
            dosyaResult.value.Data = responseResult.value.Data;
        }
        else {
            let Messages = responseResult.value.Messages.filter(x => x.MessageId == 0);
            if (Messages.length > 0)
                ErrorNotifi(Messages[0].Message);

            dosyaResult.value.Success = false;
        }

        store.Loading = false;
    }
}

const editdetail = async (id) => {

    store.Loading = true;

    const { get, responseResult } = ResponseService();

    await get(routePrefix.value + "edit/" + id);

    if (responseResult.value) {
        if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
            dosyaResult.value.Success = true;
            dosyaResult.value.Data = responseResult.value.Data;
        }
        else {
            let Messages = responseResult.value.Messages.filter(x => x.MessageId == 0);
            if (Messages.length > 0)
                ErrorNotifi(Messages[0].Message);

            dosyaResult.value.Success = false;
        }

        store.Loading = false;
    }
}

const detail = async (id) => {

    store.Loading = true;

    const { get, responseResult } = ResponseService();

    await get(routePrefix.value + "detail/" + id);

    if (responseResult.value) {
        if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
            dosyaResult.value.Success = true;
            dosyaResult.value.Data = responseResult.value.Data;
        }
        else {
            let Messages = responseResult.value.Messages.filter(x => x.MessageId == 0);
            if (Messages.length > 0)
                ErrorNotifi(Messages[0].Message);

            dosyaResult.value.Success = false;
        }

        store.Loading = false;
    }
}

const save = async (model) => {

    store.Loading = true;

    const { post, responseResult } = ResponseService();

    await post(routePrefix.value + "edit", model);

    if (responseResult.value) {
        if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
            let messageModel = responseResult.value.Messages.filter(x => x.MessageId == 1);
            SuccessNotifi(messageModel[0].Message);

            router.push({ name: "AvDosyaTakipList" });
            dosyaResult.value.Success = true;
        }
        else {
            let Messages = responseResult.value.Messages.filter(x => x.MessageId == 0);
            if (Messages.length > 0)
                ErrorNotifi(Messages[0].Message);

            dosyaResult.value.Data = responseResult.value;
            dosyaResult.value.Success = false;
        }

        store.Loading = false;
    }
}

const Delete = async (id) => {
    MessageBoxShow('Silmek istediğinizden emin misiniz?', 'warning', 'YesNo').then(async (result) => {
        if (result.isConfirmed) {
            store.Loading = true;

            const { Delete, responseResult } = ResponseService();

            await Delete(routePrefix.value + "/" + id);

            if (responseResult.value) {
                if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
                    let messageModel = responseResult.value.Messages.filter(x => x.MessageId == 1);
                    SuccessNotifi(messageModel[0].Message);

                    router.push({ name: "AvDosyaTakipList" });
                    dosyaResult.value.Success = true;
                }
                else {
                    let Messages = responseResult.value.Messages.filter(x => x.MessageId == 0);
                    if (Messages.length > 0)
                        ErrorNotifi(Messages[0].Message);

                    dosyaResult.value.Data = responseResult.value;
                    dosyaResult.value.Success = false;
                }

                store.Loading = false;
            }
        }
    });
}

const DeleteList = async (CheckedList) => {
    store.Loading = true;

    const { Delete, responseResult } = ResponseService();

    let ids = CheckedList.join(',')
    await Delete(routePrefix.value + "delete-list?ids=" + ids);

    if (responseResult.value) {
        if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
            let messageModel = responseResult.value.Messages.filter(x => x.MessageId == 1);
            SuccessNotifi(messageModel[0].Message);

            deleteResult.value.Success = true;
        }
        else {
            let Messages = responseResult.value.Messages.filter(x => x.MessageId == 0);
            if (Messages.length > 0)
                ErrorNotifi(Messages[0].Message);

            deleteResult.value.Success = false;
        }

        store.Loading = false;
    }
}




const gelenGidenList = async (id) => {

    const { get, responseResult } = ResponseService();

    await get(routePrefix.value + "gelen-giden-list/" + id);

    if (responseResult.value) {
        if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
            dosyaResult.value.Success = true;
            dosyaResult.value.Data = responseResult.value.Data;
        }
        else {
            let Messages = responseResult.value.Messages.filter(x => x.MessageId == 0);
            if (Messages.length > 0)
                ErrorNotifi(Messages[0].Message);

            dosyaResult.value.Success = false;
        }
    }
}

const gelenGidenEditDetail = async (id) => {

    store.Loading = true;

    const { get, responseResult } = ResponseService();

    await get(routePrefix.value + "gelen-giden-edit/" + id);

    if (responseResult.value) {
        if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
            dosyaResult.value.Success = true;
            dosyaResult.value.Data = responseResult.value.Data;
        }
        else {
            let Messages = responseResult.value.Messages.filter(x => x.MessageId == 0);
            if (Messages.length > 0)
                ErrorNotifi(Messages[0].Message);

            dosyaResult.value.Success = false;
        }

        store.Loading = false;
    }
}

const gelenGidenDetail = async (id) => {

    store.Loading = true;

    const { get, responseResult } = ResponseService();

    await get(routePrefix.value + "gelen-giden-detail/" + id);

    if (responseResult.value) {
        if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
            dosyaResult.value.Success = true;
            dosyaResult.value.Data = responseResult.value.Data;
        }
        else {
            let Messages = responseResult.value.Messages.filter(x => x.MessageId == 0);
            if (Messages.length > 0)
                ErrorNotifi(Messages[0].Message);

            dosyaResult.value.Success = false;
        }

        store.Loading = false;
    }
}

const gelenGidenSave = async (model) => {

    store.Loading = true;

    const { post, responseResult } = ResponseService();

    await post(routePrefix.value + "gelen-giden-edit", model);

    if (responseResult.value) {
        if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
            let messageModel = responseResult.value.Messages.filter(x => x.MessageId == 1);
            SuccessNotifi(messageModel[0].Message);

            dosyaResult.value.Success = true;
        }
        else {
            let Messages = responseResult.value.Messages.filter(x => x.MessageId == 0);
            if (Messages.length > 0)
                ErrorNotifi(Messages[0].Message);

            dosyaResult.value.Success = false;
        }

        store.Loading = false;
    }
}

const gelenGidenDeleteList = async (CheckedList) => {
    store.Loading = true;

    const { Delete, responseResult } = ResponseService();

    let ids = CheckedList.join(',')
    await Delete(routePrefix.value + "gelen-giden-delete-list?ids=" + ids);

    if (responseResult.value) {
        if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
            let messageModel = responseResult.value.Messages.filter(x => x.MessageId == 1);
            SuccessNotifi(messageModel[0].Message);

            deleteResult.value.Success = true;
        }
        else {
            let Messages = responseResult.value.Messages.filter(x => x.MessageId == 0);
            if (Messages.length > 0)
                ErrorNotifi(Messages[0].Message);

            deleteResult.value.Success = false;
        }

        store.Loading = false;
    }
}




const generalExplanationList = async (id) => {

    const { get, responseResult } = ResponseService();

    await get(routePrefix.value + "general-explanation-list/" + id);

    if (responseResult.value) {
        if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
            dosyaResult.value.Success = true;
            dosyaResult.value.Data = responseResult.value.Data;
        }
        else {
            let Messages = responseResult.value.Messages.filter(x => x.MessageId == 0);
            if (Messages.length > 0)
                ErrorNotifi(Messages[0].Message);

            dosyaResult.value.Success = false;
        }
    }
}

const generalExplanationEditDetail = async (id) => {

    store.Loading = true;

    const { get, responseResult } = ResponseService();

    await get(routePrefix.value + "general-explanation-edit/" + id);

    if (responseResult.value) {
        if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
            dosyaResult.value.Success = true;
            dosyaResult.value.Data = responseResult.value.Data;
        }
        else {
            let Messages = responseResult.value.Messages.filter(x => x.MessageId == 0);
            if (Messages.length > 0)
                ErrorNotifi(Messages[0].Message);

            dosyaResult.value.Success = false;
        }

        store.Loading = false;
    }
}

const generalExplanationDetail = async (id) => {

    store.Loading = true;

    const { get, responseResult } = ResponseService();

    await get(routePrefix.value + "general-explanation-detail/" + id);

    if (responseResult.value) {
        if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
            dosyaResult.value.Success = true;
            dosyaResult.value.Data = responseResult.value.Data;
        }
        else {
            let Messages = responseResult.value.Messages.filter(x => x.MessageId == 0);
            if (Messages.length > 0)
                ErrorNotifi(Messages[0].Message);

            dosyaResult.value.Success = false;
        }

        store.Loading = false;
    }
}

const generalExplanationSave = async (model) => {

    store.Loading = true;

    const { post, responseResult } = ResponseService();

    await post(routePrefix.value + "general-explanation-edit", model);

    if (responseResult.value) {
        if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
            let messageModel = responseResult.value.Messages.filter(x => x.MessageId == 1);
            SuccessNotifi(messageModel[0].Message);

            dosyaResult.value.Success = true;
        }
        else {
            let Messages = responseResult.value.Messages.filter(x => x.MessageId == 0);
            if (Messages.length > 0)
                ErrorNotifi(Messages[0].Message);

            dosyaResult.value.Success = false;
        }

        store.Loading = false;
    }
}

const generalExplanationDeleteList = async (CheckedList) => {
    store.Loading = true;

    const { Delete, responseResult } = ResponseService();

    let ids = CheckedList.join(',')
    await Delete(routePrefix.value + "general-explanation-delete-list?ids=" + ids);

    if (responseResult.value) {
        if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
            let messageModel = responseResult.value.Messages.filter(x => x.MessageId == 1);
            SuccessNotifi(messageModel[0].Message);

            deleteResult.value.Success = true;
        }
        else {
            let Messages = responseResult.value.Messages.filter(x => x.MessageId == 0);
            if (Messages.length > 0)
                ErrorNotifi(Messages[0].Message);

            deleteResult.value.Success = false;
        }

        store.Loading = false;
    }
}

const AvDosyaTakipService = () => {
    return {
        list,
        editdetail,
        detail,
        Delete,
        DeleteList,
        gelenGidenList,
        gelenGidenEditDetail,
        gelenGidenDetail,
        gelenGidenSave,
        gelenGidenDeleteList,
        generalExplanationList,
        generalExplanationEditDetail,
        generalExplanationDetail,
        generalExplanationSave,
        generalExplanationDeleteList,
        save,
        dosyaResult,
        deleteResult
    }
}

export default AvDosyaTakipService