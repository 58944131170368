<template>
    <ul>
        <li v-for="item in  DataSource.filter(x => x.ParentId == Id)">
            <a v-if="DataSource.filter(x => x.ParentId == item.Id).length > 0" href="javascript:;"
                class="child-list-show" @click="ChildShow_Click(item.Id)">
                <i :class="childOpenList.filter(x => x == item.Id).length == 0 ? 'fa fa-caret-right' : 'fa fa-caret-down'"></i>
            </a>
            <input type="checkbox" :name="item.Id" :value="item.Id"
                :checked="checkedList.filter(x => x.Id == item.Id).length > 0" @change="Checkbox_Change($event)" />
            <label :for="item.Id" v-html="item[Config.Text_FieldName]"></label>
            <TreeViewItem v-if="childOpenList.filter(x => x == item.Id).length > 0" :DataSource="DataSource"
                :CheckedList="checkedList" :Id="item.Id" :Config="Config" @SelectChecked="SelectCheckedItem"></TreeViewItem>
        </li>
    </ul>
</template>

<script>

import { ref } from "vue";

export default {
    props: ["DataSource", "CheckedList", "Id", "Config"],
    setup(props, context) {
        const checkedList = ref([]);
        const childOpenList = ref([]);

        checkedList.value = props.CheckedList;

        const SelectCheckedItem = ($event) => {
            checkedList.value = $event.value;
            context.emit("SelectChecked", checkedList);
        }

        let id = 0;
        let checked = false;

        const Checkbox_Change = (e) => {

            id = parseInt(e.target.value);
            checked = e.target.checked;

            let checkModel = props.DataSource.find(x => x.Id == id);
            if (checked) {
                let control = checkedList.value.find(x => x.Id == id);
                if (!control)
                    checkedList.value.push({ Id: id, ParentId: checkModel.ParentId });
            } else {
                CheckList_ChildDelete(id);
                CheckList_ParentDelete(id);
            }

            Checkbox_ChildCheck();
            Checkbox_ParentCheck();

            context.emit("SelectChecked", checkedList);
        }

        const Checkbox_ChildCheck = () => {

            props.DataSource.filter(x => x.ParentId == id).forEach((item) => {

                if (checked) {
                    let control = checkedList.value.find(x => x.Id == item.Id);
                    if (!control)
                        checkedList.value.push({ Id: item.Id, ParentId: item.ParentId });
                } else {
                    CheckList_ChildDelete(item.Id);
                }

                ChildNode(item.Id);
            });
        }

        const ChildNode = (Id) => {

            props.DataSource.filter(x => x.ParentId == Id).forEach((item) => {

                if (checked) {
                    let control = checkedList.value.find(x => x.Id == item.Id);
                    if (!control)
                        checkedList.value.push({ Id: item.Id, ParentId: item.ParentId });
                } else {
                    CheckList_ChildDelete(item.Id);
                }

                ChildNode(item.Id);
            });
        }


        const Checkbox_ParentCheck = () => {
            props.DataSource.filter(x => x.Id == id).forEach((item) => {

                if (checked) {
                    let control = checkedList.value.find(x => x.Id == item.Id);
                    if (!control)
                        checkedList.value.push({ Id: item.Id, ParentId: item.ParentId });
                } else {
                    CheckList_ParentDelete(item.Id);
                }

                ParentNode(item.ParentId);
            });
        }

        const ParentNode = (Id) => {
            props.DataSource.filter(x => x.Id == Id).forEach((item) => {

                if (checked) {
                    let control = checkedList.value.find(x => x.Id == item.Id);
                    if (!control)
                        checkedList.value.push({ Id: item.Id, ParentId: item.ParentId });
                } else {
                    CheckList_ParentDelete(item.Id);
                }

                ParentNode(item.ParentId);
            });
        }

        const CheckList_ChildDelete = (Id) => {
            let value = checkedList.value.find(x => x.Id == Id);
            let index = checkedList.value.lastIndexOf(value);
            if (index > -1)
                checkedList.value.splice(index, 1);
        }

        const CheckList_ParentDelete = (Id) => {
            let value = checkedList.value.find(x => x.Id == Id);

            let index = checkedList.value.lastIndexOf(value);
            if (index > -1) {
                let control = checkedList.value.find(x => x.ParentId == Id);
                if (!control)
                    checkedList.value.splice(index, 1);
            }
        }

        const ChildShow_Click = (Id) => {
            let value = childOpenList.value.find(x => x == Id);

            if (!value)
                childOpenList.value.push(Id);
            else
                childOpenList.value.splice(childOpenList.value.lastIndexOf(Id), 1);
        }

        return { checkedList, childOpenList, SelectCheckedItem, Checkbox_Change, ChildShow_Click }
    }
}

</script>

<style scoped>
ul {
    margin-left: 25px;
    margin-top: 5px;
    margin-bottom: 5px;

}

.child-list-show {
    margin-right: 5px;
    color: black;
}
</style>