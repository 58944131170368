<template>

    <div class="table-responsive">
        <table class="table">
            <tbody>
                <tr>
                    <th>Code</th>
                    <td><input type="text" class="form-control" v-model="Model.Code"></td>
                </tr>
                <tr>
                    <th>Definition</th>
                    <td><input type="text" class="form-control" v-model="Model.Definition"></td>
                </tr>
                <tr>
                    <th>Page Size</th>
                    <td>
                        <select class="form-control" v-model="Model.PageSize">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50" selected>50</option>
                            <option value="100">100</option>
                            <option value="250">250</option>
                            <option value="500">500</option>
                        </select>
                    </td>
                </tr>
                <tr>
                    <th>Field Value
                        <i class="fa fa-info-circle tooltip-custom">
                            <span class="tooltiptext-custom">Data nın seçilmesi için gerekli olan alan adı</span>
                        </i>
                    </th>
                    <td><input type="text" class="form-control" v-model="Model.Selection.FieldValue"></td>
                </tr>
                <tr>
                    <th>Word Wrap Column
                        <i class="fa fa-info-circle tooltip-custom">
                            <span class="tooltiptext-custom">Column başlığın otomatik sığdırılması</span>
                        </i>
                    </th>
                    <td><input type="checkbox" v-model="Model.WordWrapColumn"></td>
                </tr>
                <tr>
                    <th>Word Wrap Cell
                        <i class="fa fa-info-circle tooltip-custom">
                            <span class="tooltiptext-custom">Cell verinin otomatik sığdırılması</span>
                        </i>
                    </th>
                    <td><input type="checkbox" v-model="Model.WordWrapCell"></td>
                </tr>
                <tr>
                    <th>Auto Size</th>
                    <td><input type="checkbox" v-model="Model.AutoSize"></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>

export default {
    props: ["Model"],
    setup() {
    }
}

</script>

<style scoped>
.table tr:first-child th,
tr:first-child td {
    border-top: none;
}

.table th{
width: 150px;
}


</style>