<template>

    <div v-if="!Model.IsAdmin && Model.UserId > 0" class="btn-group m-b-15">
        <ButtonLoading class="btn btn-success" :Config="{ Disabled: submitted }" @click="Save_Click">
            <i class="fa fa-check"></i> KAYDET
        </ButtonLoading>
    </div>

    <div class="col card">
        <span style="font-size: 25px;">{{ Model.NameSurName }} <label
                v-if='Model.AuthorizationGroup !=""'
                class="label label-inverse-primary" title="Yetki Grubu">{{
                    Model.AuthorizationGroup
                }}</label>
        </span>
    </div>

    <div v-if="Model.IsAdmin" class="alert alert-info" style="font-size: 20px;">
        Kullanıcı <strong>"Admin"</strong> yetkisine sahip
    </div>
    <div v-else class="col card">
        <ul class="nav nav-tabs md-tabs" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" data-toggle="tab" href="#tabPageAuthorization" role="tab"
                    data-tab-type="cpu">SAYFA YETKİLERİ</a>
                <div class="slide"></div>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#tabActionAuthorization" role="tab"
                    data-tab-type="ram">AKSİYON YETKİLERİ</a>
                <div class="slide"></div>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#tabOtherAuthorization" role="tab"
                    data-tab-type="freespace">DİĞER YETKİLER</a>
                <div class="slide"></div>
            </li>
        </ul>
        <div class="tab-content card-block">
            <div class="tab-pane active" id="tabPageAuthorization" role="tabpanel">
                <TreeView :DataSource="Model.PageAuthList" :Config="{ Text_FieldName: 'Name' }"
                    :CheckedList="pageAuthorization_CheckedList" @SelectChecked="PageAuthorization_SelectChecked">
                </TreeView>
            </div>
            <div class="tab-pane" id="tabActionAuthorization" role="tabpanel"></div>
            <div class="tab-pane" id="tabOtherAuthorization" role="tabpanel"></div>
        </div>
    </div>
</template>

<script>

import { ref, onMounted } from "vue";
import { useRoute } from "vue-router"
import ButtonLoading from "@/components/ButtonLoading";
import TreeView from "@/components/TreeView";

import UserService from "@/_services/user.service";

export default {
    components: {
        TreeView,
        ButtonLoading
    },
    setup() {

        const route = useRoute();
        const { authorizationList, authorizationSave, userResult } = UserService();

        const submitted = ref(false);
        const Model = ref({
            UserId: 0,
            NameSurName: null,
            IsAdmin: false,
            AuthorizationGroup: null,
            PageAuthList: []
        });

        const pageAuthorization_CheckedList = ref([]);

        onMounted(async () => {
            await Reflesh_Click();
        });

        const Reflesh_Click = async () => {
            if (route.params.id > 0) {

                await authorizationList(route.params.id);

                if (userResult.value.Success) {
                    Model.value = userResult.value.Data;

                    Model.value.PageAuthList.filter(x => x.Active).forEach((item) => {
                        pageAuthorization_CheckedList.value.push({ Id: item.Id, ParentId: item.ParentId });
                    });
                }
            }
        }

        const Save_Click = async () => {
            if (!submitted.value) {
                submitted.value = true;

                Model.value.PageAuthList.forEach((item) => {
                    if (pageAuthorization_CheckedList.value.filter(x => x.Id == item.Id).length > 0)
                        item.Active = true;
                    else
                        item.Active = false;
                });

                await authorizationSave(Model.value);

                if (userResult.value.Success) {
                    submitted.value = false;
                    Reflesh_Click();
                }
                else {
                    submitted.value = false;

                    ValidationMessage.value = ref([]);
                    if (userResult.value.Data.Messages.filter(x => x.MessageId == -1).length > 0)
                        ValidationMessage.value = userResult.value.Data.Validations;
                }
            }
        }

        const PageAuthorization_SelectChecked = ($event) => {
            pageAuthorization_CheckedList.value = $event.value;
        }

        return {
            Model,
            submitted,
            pageAuthorization_CheckedList,
            Save_Click
        }
    }
}

</script>
