<template>
    <loading v-model:active="store.Loading" :is-full-page="true" color="#80CBC4" loader="bars" />

    <div v-if="store.AuthLoading" class="loader-load text-center" style="background-color: white;">
        <div class="loader">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>

    <div id="pcoded" class="pcoded">
        <div class="pcoded-overlay-box"></div>
        <div class="pcoded-container navbar-wrapper">
            <nav class="navbar header-navbar pcoded-header">
                <div class="navbar-wrapper">
                    <div class="navbar-logo">

                        <div class="text-center logo2">

                            <img src="/images/logo-icon.png" />
                            <router-link to="/">core<span> web</span></router-link>
                        </div>

                        <a class="mobile-menu" id="mobile-collapse" href="javascript:;">
                            <i class="feather icon-menu"></i>
                        </a>

                        <a class="mobile-options">
                            <i class="feather icon-more-horizontal"></i>
                        </a>
                    </div>
                    <div class="navbar-container container-fluid">
                        <ul class="nav-left">
                            <li>
                                <a href="javascript:;" onclick="javascript:toggleFullScreen()">
                                    <i class="feather icon-maximize full-screen"></i>
                                </a>
                            </li>
                        </ul>
                        <ul class="nav-right">
                            <li class="header-notification">
                                <a href="javascript:;" @click="UserRefresh(true)"><img src="/images/refresh-user.png" /></a>
                            </li>
                            <li class="user-profile header-notification">
                                <div class="dropdown-primary dropdown">
                                    <div class="dropdown-toggle" data-toggle="dropdown">
                                        <span>{{ UserName }}</span>
                                        <i class="feather icon-chevron-down"></i>
                                    </div>
                                    <ul class="show-notification profile-notification dropdown-menu"
                                        data-dropdown-in="fadeIn" data-dropdown-out="fadeOut">
                                        <li>
                                            <router-link :to="{ name: 'UserPasswordChange' }"><i
                                                    class="feather icon-settings"></i>
                                                Şifre Değiştir</router-link>
                                        </li>
                                        <li>
                                            <a href="javascript:;" @click="SignOut_Click()"><i
                                                    class="feather icon-log-out"></i> Çıkış</a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

            <div class="pcoded-main-container">
                <div class="pcoded-wrapper">
                    <nav class="pcoded-navbar">
                        <div class="pcoded-inner-navbar main-menu">

                            <Menu :menuList="PageAuthList"></Menu>

                        </div>
                    </nav>

                    <div class="pcoded-content">
                        <div class="pcoded-inner-content">
                            <!-- Main-body start -->
                            <div class="main-body">
                                <div class="page-wrapper">
                                    <!-- Page-header start -->
                                    <div class="page-header">
                                        <div class="row align-items-end">
                                            <div class="col-lg-12">
                                                <div class="page-header-title">
                                                    <div class="d-inline">
                                                        <h4>{{ store.PageTitle }}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Page-header end -->
                                    <div class="page-body">

                                        <router-view></router-view>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router"
import router from "@/router";
import { store } from "../../store";
import Menu from "../../components/menu/Menu";

import Loading from 'vue-loading-overlay';
//import 'vue-loading-overlay/dist/vue-loading.css';
import 'vue-loading-overlay/dist/css/index.css';

import AuthService from "@/_services/auth.service";
import UserService from "@/_services/user.service";

export default {
    components: {
        Menu,
        Loading
    },
    setup() {

        router.push("/loading");

        const route = useRoute();
        const { getUser, authResult } = AuthService();
        const { signOut } = UserService();
        const PageAuthList = ref([]);
        const UserName = ref(null);

        onMounted(() => {
            UserRefresh(false);
        });

        store.RefreshReturnUrl = route.path;

        const UserRefresh = async (IsBtnClick) => {

            PageAuthList.value = [];

            await getUser(IsBtnClick);

            if (authResult.value.Success) {

                setTimeout(() => {
                    PageAuthList.value = store.UserAuth.PageAuthList.filter(x => !x.IsHide);
                    UserName.value = store.UserModel.UserName;
                }, 500);


                authResult.value.Success = false;

                if (!store.userPublicPages.includes(store.RefreshReturnUrl))
                    router.push("/");
                else {
                    if (store.RefreshReturnUrl.includes("/loading"))
                        router.push("/");
                    else
                        router.push(store.RefreshReturnUrl);
                }
            }
            else {
                localStorage.clear("user");
                router.push("/login");
            }
        };

        const SignOut_Click = async() => {
            await signOut();
        }

        return { store, PageAuthList, UserName, UserRefresh, SignOut_Click }
    }

}

</script>