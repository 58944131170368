<template>
    <div class="card">
        <div class="card-header">
            <h4>Ay Bazlı</h4>
        </div>
        <div class="card-block">
            <GChart type="ColumnChart" :data="ayBazliData" :options="options" />
        </div>
    </div>
    <div class="card">
        <div class="card-header">
            <h4>Cari Grubuna Göre</h4>
        </div>
        <div class="card-block">
            <GChart type="ComboChart" :data="cariGrubuData" :options="options" />
        </div>
    </div>
    <div class="card">
        <div class="card-header">
            <h4>Plasiyere Göre</h4>
        </div>
        <div class="card-block">
            <GChart type="ComboChart" :data="plasiyerData" :options="options" />
        </div>
    </div>
    <div class="card">
        <div class="card-header">
            <h4>Malzeme Grubuna Göre</h4>
        </div>
        <div class="card-block">
            <GChart type="ComboChart" :data="malzemeGrubuData" :options="options" />
        </div>
    </div>
</template>

<script>

import { ref, onMounted } from "vue";
import { GChart } from 'vue-google-charts'

import Dashboard_KPI_Service from "@/_services.reports/dashboard_kpi.service";

export default
    {
        components: {
            GChart
        },
        setup() {
            const { ciro, result } = Dashboard_KPI_Service();

            const ayBazliData = ref([]);
            const cariGrubuData = ref([]);
            const plasiyerData = ref([]);
            const malzemeGrubuData = ref([]);

            onMounted(async () => {
                await ciro();

                if (result.value.Success) {

                    ayBazliData.value = [['Ay', 'Tutar', { role: 'annotation' }]];
                    cariGrubuData.value = [['Cari Grubu', 'Tutar', { role: 'annotation' }, '']];
                    plasiyerData.value = [['Plasiyer', 'Tutar', { role: 'annotation' }, '']];
                    malzemeGrubuData.value = [['Malzeme Grubu', 'Tutar', { role: 'annotation' }, '']];

                    result.value.Data.Aylik.forEach((item) => {
                        ayBazliData.value.push([item.AY_TEXT, item.TOPLAM_TUTAR, item.TOPLAM_TUTAR]);
                    });

                    result.value.Data.CariGrubuList.forEach((item) => {
                        cariGrubuData.value.push([item.CARI_GRUBU, item.TOPLAM_TUTAR, item.TOPLAM_TUTAR, item.ORAN_KUMULATIF]);
                    });

                    result.value.Data.PlasiyerList.forEach((item) => {
                        plasiyerData.value.push([item.PLASIYER_KODU, item.TOPLAM_TUTAR, item.TOPLAM_TUTAR, item.ORAN_KUMULATIF]);
                    });

                    result.value.Data.MalzemeGrubuList.forEach((item) => {
                        malzemeGrubuData.value.push([item.MALZEME_GRUBU, item.TOPLAM_TUTAR, item.TOPLAM_TUTAR, item.ORAN_KUMULATIF]);
                    });

                    result.value.Success = false;
                }
            });

            const options = {
                width: "100%",
                legend: { position: 'none' },         // no legend
                backgroundColor: { strokeWidth: 1 },   // to get a nice box
                seriesType: "bars",                   // the standard chart type
                chartArea: { left: 75, top: 75, right: 60, bottom: 65, height: '100%', width: '100%' },
                // the second data column should be of type 'line' and should be associated with the second vertical axis
                series: { 1: { type: "line", targetAxisIndex: 1 } },
                height: 450
            }

            return { ayBazliData, cariGrubuData, plasiyerData, malzemeGrubuData, options }

        }
    }

</script>