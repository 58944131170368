import { ref } from "vue";
import { store } from "../store";
import ResponseService from "./response.service";
import router from "../router";

import { ErrorNotifi, SuccessNotifi, MessageBoxShow } from "../_helpers/notification";

const proposalResult = ref({ Success: false, Data: null });
const deleteResult = ref({ Success: false });
const routePrefix = ref("proposal/");

const product_add_or_update = async (Products, Tig) => {

    store.Loading = true;

    const { post, responseResult } = ResponseService();

    await post(routePrefix.value + "product-addorupdate", { Products, Tig });

    if (responseResult.value) {
        if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
            let messageModel = responseResult.value.Messages.filter(x => x.MessageId == 1);
            SuccessNotifi(messageModel[0].Message);

            proposalResult.value.Data = responseResult.value.Data;
            proposalResult.value.Success = true;
        }
        else {
            let Messages = responseResult.value.Messages.filter(x => x.MessageId == 0);
            if (Messages.length > 0)
                ErrorNotifi(Messages[0].Message);

            proposalResult.value.Success = false;
        }

        store.Loading = false;
    }
}


const ProposalService = () => {
    return { product_add_or_update, proposalResult, deleteResult }
}

export default ProposalService