<template>
    <div class="row">
        <div class="col col-12 m-b-25">
            <ButtonLoading class="btn btn-success" :Config="{ Disabled: submitted }" @click="btnSave_Click">
                <i class="fa fa-check"></i> KAYDET
            </ButtonLoading>
        </div>
    </div>

    <div class="row">
        <div class="col col-12 col-sm-2">
            <ul id="ulTabTableView" class="nav nav-tabs md-tabs tabs-left">
                <li class="nav-item">
                    <a class="nav-link active" data-toggle="tab" href="#tabViews">VIEWS</a>
                    <div class="slide"></div>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#tabColumns">COLUMNS</a>
                    <div class="slide"></div>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#tabStyleFormatRules">STYLE FORMAT RULES</a>
                    <div class="slide"></div>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#tabParameters">PARAMETERS</a>
                    <div class="slide"></div>
                </li>
            </ul>
        </div>
        <div class="col col-12 col-sm-10">
            <div class="card">
                <div class="card-block">
                    <div class="tab-content">
                        <div class="tab-pane active" id="tabViews" role="tabpanel">
                            <View :Model="DataSource.View"></View>
                        </div>
                        <div class="tab-pane" id="tabColumns" role="tabpanel">
                            <Columns :Model="DataSource.Columns"></Columns>
                        </div>
                        <div class="tab-pane" id="tabStyleFormatRules" role="tabpanel">
                            STYLE FORMAT RULES
                        </div>
                        <div class="tab-pane" id="tabParameters" role="tabpanel">
                            PARAMETERS
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { ref, onMounted } from "vue";
import { useRoute } from "vue-router"

import View from "@/views/admin/tableview/Components/TableView_Views.vue";
import Columns from "@/views/admin/tableview/Components/TableView_Columns.vue";

import ButtonLoading from "@/components/ButtonLoading";

import TableViewService from "@/_services/tableview.service";

export default {

    components: {
        View,
        Columns,
        ButtonLoading
    },
    setup() {
        const route = useRoute();
        const { editdetail, save, result } = TableViewService();

        const submitted = ref(false);
        const DataSource = ref(null);

        DataSource.value = {
            View: {
                Selection: { FieldValue: null }
            },
            Columns: []
        }

        onMounted(async () => {

            await editdetail(route.params.id);

            if (result.value.Success) {
                DataSource.value = result.value.Data;
            }
        });

        const btnSave_Click = async () => {
            if (!submitted.value) {
                submitted.value = true;

                console.log(DataSource.value);
            }
        }

        return { submitted, DataSource, btnSave_Click }
    }
}

</script>

<style scoped>
.nav-item {
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.nav-link {
    margin-left: 15px;
}

.nav-tabs {
    border-bottom: none;
    /* border-bottom: 1px solid #ddd; */
}


@media only screen and (max-width: 573px) {
    .nav-tabs .slide {
        width: 4px !important;
    }

    .nav-tabs .nav-link {
        text-align: left;
    }
}
</style>