<template>

    <div class="btn-group m-b-10">
        <router-link :to="{ name: 'DescriptionFormatsEdit' }" class="btn btn-dropbox"><i class="fa fa-plus"></i> YENİ
        </router-link>
        <button class="btn btn-pinterest" @click="DeleteClick" :disabled='checkedList.length === 0'><i
                class="fa fa-minus"></i> SİL</button>
        <button class="btn btn-dropbox" @click="RefleshClick"><i class="fa fa-refresh"></i> YENİLE</button>
    </div>

    <DataGrid :DataSource="DataSource" :PageSize="10" :Properties="Properties"
        @CheckedList="checkedList = $event.value">
    </DataGrid>

</template>

<script>

import { ref, onMounted } from "vue";
import { MessageBoxShow } from "@/_helpers/notification";

import DataGrid from "@/components/data_layout/DataGrid.vue";

import DefinitionFormatService from "@/_services/proposaldefinitions.service.js";

export default {
    components: {
        DataGrid
    },
    setup() {

        const { list, DeleteList, descResult, deleteResult } = DefinitionFormatService();

        const DataSource = ref(null);
        const checkedList = ref([]);
        const Properties = ref([]);

        Properties.value = {
            Export: { Landscape: false, MenuShow: false },
            Selection: { FieldValue: "Id" },
            StyleFormatRule: [
                { Name: "Format1", ApplyToRow: false, ApplyToColumn: true, FieldName: "IsDefault_Text", Rule: { RuleType: "FormatConditionRuleValue", Appearance: { "text-align": "center" }, Condition: "None" } }
            ],
            Columns: [
                { Name: "colTitle", Caption: "Başlık", FieldName: "Title", Component: { Type: "RouterLink", To: { Name: 'DescriptionFormatsEdit' } } },
                { Name: "colDefault", Caption: "Varsayılan", FieldName: "IsDefault_Text", Width: "75", AllowHtmlString: true }
            ]
        }

        onMounted(async () => {
            await RefleshClick();
        });

        

        const DeleteClick = async () => {

            MessageBoxShow('Silmek istediğinizden emin misiniz?', 'warning', 'YesNo').then(async (result) => {
                if (result.isConfirmed) {
                    await DeleteList(checkedList.value);

                    if (deleteResult.value.Success) {
                        checkedList.value = [];

                        await RefleshClick();
                    }
                }
            });
        };

        const RefleshClick = async () => {

            await list(1);

            if (descResult.value.Success) {
                DataSource.value = descResult.value.Data;
                descResult.value.Success = false;
            }
        }

        return { DataSource, Properties, checkedList, DeleteClick, RefleshClick }
    }
}
</script>