<template>
    <label v-if="Config.CaptionShow == null || Config.CaptionShow" :for="Config.Name">{{ Config.Caption }}</label><label
        v-if="Config.Required" :for="Config.Name" class="text-danger">*</label>
    <select class="form-control" @input="$emit('update:Value', $event.target.value)"
        :class="{ 'input-required-field': Config.ValidationMessage != null }"
        :disabled="Data[0][Config.DisabledFieldName] == Config.DisabledValue">
        <option v-for="item in Data" :value="item[Config.DataValue]" :selected="Value == item[Config.DataValue]">{{
        item[Config.DataText] }}</option>
    </select>
    <div v-if="Config.ValidationMessage != null" class="text-danger">{{
        Config.ValidationMessage
    }}
    </div>
</template>

<script>

import { ref } from "vue";

export default {
    props: ["Config", "Value", "Data"],
    emits: ['update:Value']
}
</script>