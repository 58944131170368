<template>

    <div class="btn-group m-b-10">
        <router-link :to="{ name: 'AvDosyaTakipEdit' }" class="btn btn-dropbox"><i class="fa fa-plus"></i> YENİ
        </router-link>
        <button class="btn btn-pinterest" @click="Delete_Click"><i class="fa fa-minus"></i> SİL</button>
        <router-link :to="{ name: 'AvDosyaTakipEdit', params: { id: Model.Id } }" class="btn btn-dropbox"><i
                class="fa fa-edit"></i> DÜZENLE
        </router-link>
    </div>

    <div class="col card">

        <div class="row p-t-15">

            <div class="col col-12 col-sm-12 col-md-6">

                <div class="form-group">
                    <label>Alacaklı Firma</label><br>
                    {{ Model.AlacakliFirma }}
                </div>

                <div class="form-group">
                    <label>Borçlu Cari Kodu</label><br>
                    {{ Model.BorcluCariKodu }}
                </div>

                <div class="form-group">
                    <label>Borçlu Cari Adı</label><br>
                    {{ Model.BorcluCariAdi }}
                </div>

                <div class="row">
                    <div class="col col-sm-5 form-group">
                        <label>Evrak Türü</label><br>
                        {{ Model.EvrakTuru }}
                    </div>

                    <div class="col col-sm-7 form-group">
                        <label>Evrak Tutarı</label><br>
                        {{ Model.EvrakTutari }}
                    </div>
                </div>

                <div class="row">
                    <div class="col col-sm-6 form-group">
                        <label>İcra Dairesi</label><br>
                        {{ Model.IcraDairesi }}
                    </div>

                    <div class="col col-sm-6 form-group">
                        <label>Dosya No</label><br>
                        {{ Model.DosyaNo }}
                    </div>
                </div>

                <div class="row">
                    <div class="col col-sm-6 form-group">
                        <label>İli</label><br>
                        {{ Model.Ili }}
                    </div>

                    <div class="col col-sm-6 form-group">
                        <label>D. Hakkında Gör.</label><br>
                        {{ Model.DosyaHakkindaGorus }}
                    </div>
                </div>

                <div class="row">
                    <div class="col col-sm-6 form-group">
                        <label>Hukuk Bürosu</label><br>
                        {{ Model.HukukBurosu }}
                    </div>

                    <div class="col col-sm-6 form-group">
                        <label>Önceki H.Bürosu</label><br>
                        {{ Model.OncekiHukukBurosu }}
                    </div>
                </div>

            </div>

            <div class="col col-12 col-sm-12 col-md-6">
                <div class="row">
                    <div class="col col-sm-6 form-group">
                        <label>Asıl Alacak</label><br>
                        {{ Model.AsilAlacak }}
                    </div>

                    <div class="col col-sm-6 form-group">
                        <label>Dosya Tutarı</label><br>
                        {{ Model.DosyaTutari }}
                    </div>
                </div>

                <div class="row">
                    <div class="col col-sm-6 form-group">
                        <label>Masraf</label><br>
                        {{ Model.Masraf }}
                    </div>

                    <div class="col col-sm-6 form-group">
                        <label>Tahsilat Tutarı</label><br>
                        {{ Model.TahsilatTutari }}
                    </div>
                </div>

                <div class="row">
                    <div class="col col-sm-6 form-group">
                        <label>Dos.Kalan Tutar</label><br>
                        {{ Model.DosyadaKalanTutar }}
                    </div>

                    <div class="col col-sm-6 form-group">
                        <label>Asıl Alacak Kalan</label><br>
                        {{ Model.AsilAlacakKalan }}
                    </div>
                </div>

                <div class="row">
                    <div class="col col-sm-6 form-group">
                        <label>Yıl</label><br>
                        {{ Model.Yil }}
                    </div>

                    <div class="col col-sm-6 form-group">
                        <label>Dosya Durumu</label><br>
                        {{ Model.DosyaDurumu }}
                    </div>
                </div>

                <div class="row">
                    <div class="col col-sm-6 form-group">
                        <label>Oluşturan</label><br>
                        {{ Model.CreatedBy_Text }}
                    </div>

                    <div class="col col-sm-6 form-group">
                        <label>Oluşturulma Tarihi</label><br>
                        {{ $filters["Date"](Model.CreadedDate) }}
                    </div>
                </div>

                <div class="row">
                    <div class="col col-sm-6 form-group">
                        <label>Güncelleyen</label><br>
                        {{ Model.ModifiedBy_Text }}
                    </div>

                    <div class="col col-sm-6 form-group">
                        <label>Güncellenme Tarihi</label><br>
                        {{ $filters["Date"](Model.ModifiedDate) }}

                    </div>
                </div>
            </div>

        </div>

    </div>

    <div class="col card">
        <ul class="nav nav-tabs md-tabs" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" data-toggle="tab" href="#GelenGiden">GELEN/GİDEN</a>
                <div class="slide"></div>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#GeneralExplanations"
                    @click="generalExplanationShow = true">GENEL AÇIKLAMALAR</a>
                <div class="slide"></div>
            </li>
        </ul>
        <div class="tab-content">
            <div class="tab-pane active m-t-15 m-b-15" id="GelenGiden">
                <AvDosya_GelenGiden_List :PropModel="{ DosyaId: $route.params.id }">
                </AvDosya_GelenGiden_List>
            </div>

            <div class="tab-pane m-t-15 m-b-15" id="GeneralExplanations">
                <AvDosya_GeneralExplanations_List :PropModel="{ DosyaId: $route.params.id }"
                    v-if="generalExplanationShow">
                </AvDosya_GeneralExplanations_List>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router"

import AvDosyaTakipService from "../../_services/avdosyatakip.service";

import AvDosya_GelenGiden_List from "./AvDosya_GelenGiden_List.vue";
import AvDosya_GeneralExplanations_List from "./AvDosya_GeneralExplanations_List.vue";

export default {
    components: {
        AvDosya_GelenGiden_List,
        AvDosya_GeneralExplanations_List,
    },
    setup() {

        const route = useRoute();
        const { detail, Delete, dosyaResult } = AvDosyaTakipService();
        const generalExplanationShow = ref(false);

        const Model = ref({
            Id: 0,
            CompanyId: 0,
            LogoCompanyId: 0,
            LogoPeriodId: 0,
            AlacakliFirma: null,
            BorcluCariKodu: null,
            BorcluCariAdi: null,
            EvrakTuru: null,
            EvrakTutari: 0,
            IcraDairesi: null,
            DosyaNo: null,
            Ili: null,
            DosyaHakkindaGorus: null,
            HukukBurosu: null,
            OncekiHukukBurosu: null,
            AsilAlacak: 0,
            DosyaTutari: 0,
            Masraf: 0,
            TahsilatTutari: 0,
            DosyadaKalanTutar: 0,
            AsilAlacakKalan: 0,
            Yil: 0,
            DosyaDurumu: null,
            CreatedBy_Text: null,
            CreadedDate_Text: null,
            ModifiedBy_Text: null,
            ModifiedDate_Text: null
        });

        onMounted(async () => {

            if (route.params.id > 0) {
                await detail(route.params.id);

                if (dosyaResult.value.Success)
                    Model.value = dosyaResult.value.Data;
            }


        });

        const Delete_Click = async () => {
            if (route.params.id > 0)
                await Delete(route.params.id);
        }

        return {
            Model,
            generalExplanationShow,
            Delete_Click
        };
    }
}
</script>