<template>

    <ModalPopUp :Show="PopupShow" :Size="PopUpSize" @PopupClose="PopupShow = false">
        <template #header>
            {{ PopUpTitile }}
        </template>
        <template #body>
            <Edit :PopUpModel="PopUpModel" @SaveSuccess="SaveSuccess($event)"></Edit>
        </template>
    </ModalPopUp>

    <div class="btn-group m-b-10">
        <button class="btn btn-dropbox" @click="Detail_Click(0)"><i class="fa fa-plus"></i> YENİ</button>
        <button class="btn btn-pinterest" @click="DeleteClick" :disabled='checkedList.length === 0'><i
                class="fa fa-minus"></i> SİL</button>
        <button class="btn btn-dropbox" @click="RefleshClick"><i class="fa fa-refresh"></i> YENİLE</button>
    </div>

    <DataGrid :DataSource="DataSource" :PageSize="10" :Properties="Properties"
            @CheckedList="checkedList = $event.value" @Link_Click="Detail_Click">
    </DataGrid>
    <div class="text-center m-15" v-if="DataSource == null"><b>Lütfen Bekleyin...</b></div>

</template>

<script>
import { ref, onMounted } from "vue";

import ModalPopUp from "../../components/ModalPopup.vue";
import DataGrid from "../../components/data_layout/DataGrid";

import { MessageBoxShow } from "../../_helpers/notification";
import AvDosyaTakipService from "../../_services/avdosyatakip.service";

import Edit from "./AvDosya_GeneralExplanations_Edit.vue";

export default {
    components: {
        DataGrid,
        ModalPopUp,
        Edit
    },
    props: ["PropModel"],
    setup(props, context) {

        const { generalExplanationList, generalExplanationDeleteList, dosyaResult, deleteResult } = AvDosyaTakipService();

        const DataSource = ref(null);
        const checkedList = ref([]);
        const Properties = ref([]);

        const PopUpTitile = ref("Tanımlama");
        const PopUpSize = ref("small");
        const PopupShow = ref(false);
        const propModel = props["PropModel"];
        const PopUpModel = ref({
            Id: 0,
            DosyaId: propModel.DosyaId
        });

        Properties.value = {
            Export: { Landscape: false, MenuShow: false },
            Selection: { FieldValue: "Id" },
            PreviewLine:{FieldName:"CreatedBy_Text", Style:{"text-align": "right", "font-style": "italic", "font-weight":"bold", "font-size":"13px"}},
            Columns: [
                { Name: "colTarih", Caption: "Tarih", FieldName: "CreadedDate", Format:"Date", Width:"75px", Component: { Type: "Link", Click_FieldName:"Id", Click_Key:"Edit" } },
                { Name: "colAciklama", Caption: "Açıklama", FieldName: "Explanation" }
            ]
        }

        onMounted(async () => {
            await RefleshClick();
        });

        const Detail_Click = (model) => {
            PopupShow.value = true;
            PopUpModel.value.Id = model.Value;
        }

        const SaveSuccess = async () => {
            PopupShow.value = false;

            RefleshClick();
        }

        const RefleshClick = async () => {

            DataSource.value = null;

            await generalExplanationList(PopUpModel.value.DosyaId);

            if (dosyaResult.value.Success) {
                DataSource.value = dosyaResult.value.Data;
                dosyaResult.value.Success = false;
            }
        }

        const DeleteClick = async () => {

            MessageBoxShow('Silmek istediğinizden emin misiniz?', 'warning', 'YesNo').then(async (result) => {
                if (result.isConfirmed) {
                    await generalExplanationDeleteList(checkedList.value);

                    if (deleteResult.value.Success) {
                        checkedList.value = [];

                        await RefleshClick();
                    }
                }
            });
        };



        return {
            DataSource,
            checkedList,
            Properties,
            PopUpTitile,
            PopUpSize,
            PopupShow,
            PopUpModel,
            SaveSuccess,
            Detail_Click,
            RefleshClick,
            DeleteClick
        };
    }
}
</script>