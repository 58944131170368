<template>

    <ModalPopUp :Show="PopupShow" Size="medium" @PopupClose="PopupShow = false">
        <template #header>
            Sayfa Seç
        </template>
        <template #body>
            <Page_Popup_List @Selected="PageSelected"></Page_Popup_List>
        </template>
    </ModalPopUp>

    <form @submit.prevent="Save_Click">
        <div class="btn-group m-b-10">
            <ButtonLoading class="btn btn-success" :Config="{ Disabled: submitted }">
                <i class="fa fa-check"></i> KAYDET
            </ButtonLoading>
        </div>

        <div class="col card">

            <div class="row p-t-15">

                <input type="hidden" v-model="Model.Id" />
                <input type="hidden" v-model="Model.ParentId" />

                <div class="col col-12 col-sm-12 col-md-6">

                    <div class="form-group">
                        <TextBox_Action
                            :Config="{ Name: 'ParentName', Caption: 'Üst Sayfa Adı', BtnCaption: 'Seç', Placeholder: 'Üst Sayfa Adı' }"
                            v-model:Value="Model.ParentName" @BtnClick="PopupShow = true">
                        </TextBox_Action>

                    </div>

                    <div class="form-group">
                        <TextBox
                            :Config="{ Name: 'Code', Caption: 'Kod', Placeholder: 'Kod', Type: 'text', Required: true, ValidationMessage: ValidationMessage['Code'] }"
                            v-model:Value="Model.Code"></TextBox>
                        * Projedeki route name ile aynı olmalıdır.<br />
                        * Ana Sayfalarda bağımsız kod yazılabilinir.
                    </div>


                    <div class="form-group">
                        <TextBox
                            :Config="{ Name: 'Name', Caption: 'Adı', Placeholder: 'Adı', Type: 'text', Required: true, ValidationMessage: ValidationMessage['Name'] }"
                            v-model:Value="Model.Name"></TextBox>
                    </div>

                    <div class="form-group">
                        <TextBox
                            :Config="{ Name: 'Title', Caption: 'Başlık', Placeholder: 'Başlık', Type: 'text', Required: true, ValidationMessage: ValidationMessage['Title'] }"
                            v-model:Value="Model.Title"></TextBox>
                    </div>

                    <div class="form-group">
                        <TextBox :Config="{ Name: 'Url', Caption: 'Adres', Placeholder: 'Adres', Type: 'text', ValidationMessage: ValidationMessage['Url'] }"
                            v-model:Value="Model.Url"></TextBox>
                        * Ana Sayfalarda gerekli değildir.<br />
                        * Alt Sayfalar için doldurulması gereklidir.
                    </div>

                    <div class="form-group">
                        <TextBox :Config="{ Name: 'Icon', Caption: 'Icon', Placeholder: 'Icon', Type: 'text' }"
                            v-model:Value="Model.Icon"></TextBox>
                        * Ana Sayfalar için gerekli
                    </div>

                    <div class="form-group">
                        <TextBox
                            :Config="{ Name: 'LineNumber', Caption: 'Sıra', Placeholder: 'Sıra', Type: 'number', Required: true, ValidationMessage: ValidationMessage['LineNumber'] }"
                            v-model:Value="Model.LineNumber"></TextBox>
                    </div>

                    <div class="form-group">

                        <ul class="d-flex flex-wrap">
                            <li><label for="Active">Aktif</label>
                                <input type="checkbox" name="Active" v-model="Model.Active" class="form-checkbox" />
                            </li>
                            <li><label for="IsHide">Gizle</label>
                                <input type="checkbox" name="IsHide" v-model="Model.IsHide" class="form-checkbox" />
                            </li>
                            <li>
                                <label for="IsAdminPage">Admin Sayfası</label>
                                <input type="checkbox" name="IsAdminPage" v-model="Model.IsAdminPage"
                                    class="form-checkbox" />
                            </li>
                        </ul>
                    </div>
                </div>

            </div>

        </div>

    </form>
</template>

<script>

import { ref, onMounted } from "vue";
import { useRoute } from "vue-router"

import ButtonLoading from "@/components/ButtonLoading";
import ModalPopUp from "@/components/ModalPopup";
import TextBox_Action from "@/components/textbox/TextBox_Action";
import TextBox from "@/components/textbox/TextBox";
import Page_Popup_List from "@/views/admin/page/Page_Popup_List.vue";

import PageService from "@/_services/page.service";

export default {
    components: {
        ButtonLoading,
        ModalPopUp,
        TextBox,
        TextBox_Action,
        Page_Popup_List
    },
    setup() {

        const route = useRoute();
        const { editdetail, save, pageResult } = PageService();

        const submitted = ref(false);
        const PopupShow = ref(false);
        const ValidationMessage = ref([]);
        const Model = ref({
            Id: 0,
            ParentId: 0,
            Code: null,
            Name: null,
            Title: null,
            Url: null,
            Icon: null,
            LineNumber: 1,
            Active: true,
            IsHide: false,
            IsAdminPage: false
        });

        onMounted(async () => {

            if (route.params.id > 0) {

                await editdetail(route.params.id);

                if (pageResult.value.Success) {
                    Model.value = pageResult.value.Data;
                }
            }
        });


        const Save_Click = async () => {
            if (!submitted.value) {
                submitted.value = true;

                await save(Model.value);

                if (pageResult.value.Success)
                    submitted.value = false;
                else {
                    submitted.value = false;

                    ValidationMessage.value = ref([]);
                    if (pageResult.value.Data.Messages.filter(x => x.MessageId == -1).length > 0)
                        ValidationMessage.value = pageResult.value.Data.Validations;
                }
            }
        }

        const PageSelected = (item) => {
            Model.value.ParentId = item.Id;
            Model.value.ParentName = item.Name;
            PopupShow.value = false;
        }

        return {
            Model,
            ValidationMessage,
            submitted,
            PopupShow,
            Save_Click,
            PageSelected
        };
    }
}
</script>

<style scoped>
.flex-wrap li {
    padding-right: 15px;
}
</style>