<template>
    <ModalPopUp :Show="PopupShow" Size="small" @PopupClose="PopupClose_Click">
        <template #header>
            Satış Fiyatları
        </template>
        <template #body>
            <div v-show="isLoading" class="row">
                <div class="col m-t-15 text-center">
                    Lütfen Bekleyin...
                </div>
            </div>
            <div v-show="!isLoading" class="row m-t-15">
                <div class="col">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>Ambar</th>
                                <th>Stok</th>
                                <th>Bekleyen Sipariş</th>
                                <th>Son Durum Stok</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="row in stockData">
                                <td>{{ row.AMBAR_NO }}</td>
                                <td>{{ row.AMBAR_ADI }}</td>
                                <td>{{ $filters["N0"](row.STOK) }}</td>
                                <td>{{ $filters["N0"](row.BEKLEYEN_SIPARIS) }}</td>
                                <td>{{ $filters["N0"](row.SON_DURUM_STOK) }}</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th></th>
                                <th>Toplam</th>
                                <th>{{ $filters["N0"](stokTotal) }}</th>
                                <th>{{ $filters["N0"](bekleyenSiparisTotal) }}</th>
                                <th>{{ $filters["N0"](sonDurumStokTotal) }}</th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </template>
    </ModalPopUp>
</template>

<script>

import { ref, watch, computed } from "vue";

import ModalPopUp from "@/components/ModalPopup";

import ProductService from "@/_services.reports/product.service";

export default {
    props: ["Show", "ProductCode"],
    components: {
        ModalPopUp
    },
    setup(props, context) {

        const productService = ProductService();

        const PopupShow = ref(false);
        const isLoading = ref(false);
        const stockData = ref([]);

        const stokTotal = computed(() => stockData.value.reduce((acc, item) => acc + item.STOK, 0));
        const bekleyenSiparisTotal = computed(() => stockData.value.reduce((acc, item) => acc + item.BEKLEYEN_SIPARIS, 0));
        const sonDurumStokTotal = computed(() => stockData.value.reduce((acc, item) => acc + item.SON_DURUM_STOK, 0));

        watch(() => props["Show"], (newValue, oldValue) => {
            PopupShow.value = props["Show"];

            if (PopupShow.value)
                Stock_GetList();
        });

        const PopupClose_Click = () => {
            PopupShow.value = false;
            context.emit("PopupClose", PopupShow.value);
        }

        const Stock_GetList = (async () => {

            isLoading.value = true;

            await productService.stock_popup(props["ProductCode"]);

            if (productService.result.value.Success) {
                stockData.value = productService.result.value.Data;
                isLoading.value = false;
            }
        });

        return {
            PopupShow, isLoading, stockData, stokTotal, bekleyenSiparisTotal, sonDurumStokTotal, PopupClose_Click
        };
    }
}

</script>