<template>
    <ModalPopUp :Show="PopupShow" Size="small" @PopupClose="PopupClose_Click">
        <template #header>
            Satışlar
        </template>
        <template #body>
            <div class="row">
                <div class="col">
                    <div class="form-group row">
                        <label class="col-12 col-sm-2 col-md-2 col-form-label"><b>Yıl</b></label>
                        <div class="col-12 col-sm-8 col-md-8">
                            <select class="form-control" @change="ddlYear_Change($event)" v-model="yearValue">
                                <option v-for="item in yearList" :value="item">{{ item }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <div v-show="isLoading" class="row">
                <div class="col m-t-15 text-center">
                    Lütfen Bekleyin...
                </div>
            </div>
            <div v-show="!isLoading" class="row m-t-15">
                <div class="col">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Aylar</th>
                                <th>Miktar</th>
                                <th>Tutar</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="row in salesData">
                                <td>{{ row.AY_TEXT }}</td>
                                <td>{{ $filters["N0"](row.TOPLAM_SATIS_MIKTARI) }}</td>
                                <td>{{ $filters["N2"](row.TOPLAM_SATIS_TUTARI) }}</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th>Toplam</th>
                                <th>{{ $filters["N0"](amountTotal) }}</th>
                                <th>{{ $filters["N2"](salesTotal) }}</th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </template>
    </ModalPopUp>
</template>

<script>

import { ref, watch, computed } from "vue";

import ModalPopUp from "@/components/ModalPopup";

import SalesService from "@/_services.reports/sales.service";

export default {
    props: ["Show", "ProductCode"],
    components: {
        ModalPopUp
    },
    setup(props, context) {

        const salesService = SalesService();

        const PopupShow = ref(false);
        const isLoading = ref(false);
        const salesData = ref([]);
        const yearList = ref([]);
        const yearValue = ref(0);

        const amountTotal = computed(() => salesData.value.reduce((acc, item) => acc + item.TOPLAM_SATIS_MIKTARI, 0));
        const salesTotal = computed(() => salesData.value.reduce((acc, item) => acc + item.TOPLAM_SATIS_TUTARI, 0));


        watch(() => props["Show"], (newValue, oldValue) => {
            PopupShow.value = props["Show"];

            if (PopupShow.value) {

                if (yearValue.value == 0) {
                    yearList.value = [];

                    for (let index = new Date().getFullYear(); index >= 2023; index--)
                        yearList.value.push(index);
                }

                yearValue.value = yearList.value[0];

                Sales_GetList(yearValue.value);
            }
        });

        const PopupClose_Click = () => {
            PopupShow.value = false;
            context.emit("PopupClose", PopupShow.value);
        }

        const Sales_GetList = (async (year) => {

            isLoading.value = true;

            await salesService.month_based_sales_popup(props["ProductCode"], year);

            if (salesService.result.value.Success) {
                salesData.value = salesService.result.value.Data;
                isLoading.value = false;
            }
        });

        const ddlYear_Change = async (e) => {
            Sales_GetList(e.target.value);
        }

        return {
            PopupShow, isLoading, salesData, amountTotal, salesTotal, yearList, yearValue, PopupClose_Click, ddlYear_Change
        };
    }
}

</script>

<style scoped>
table td:first-child {
    font-weight: bold;
}
</style>