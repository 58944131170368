<template>

    <ModalPopUp :Show="PopupShow" Size="medium" @PopupClose="PopupShow = false">
        <template #header>
            Personel Seç
        </template>
        <template #body>
            <Emp_Single_Popup_List @Selected="EmployeeSelected"></Emp_Single_Popup_List>
        </template>
    </ModalPopUp>

    <form @submit.prevent="Save_Click">
        <div class="btn-group m-b-10">
            <ButtonLoading class="btn btn-success" :Config="{ Disabled: submitted }">
                <i class="fa fa-check"></i> KAYDET
            </ButtonLoading>
        </div>

        <div class="col card">

            <div class="row p-t-15">

                <input type="hidden" v-model="Model.Id" />
                <input type="hidden" v-model="Model.EmployeeId" />

                <div class="col col-12 col-sm-12 col-md-6">

                    <div class="form-group">
                        <TextBox_Action
                            :Config="{ Name: 'NameSurName', Caption: 'Personel Adı', BtnCaption: 'Seç', Placeholder: 'Personel Adı', Required: true, ValidationMessage: ValidationMessage['EmployeeId'] }"
                            v-model:Value="Model.NameSurName" @BtnClick="PopupShow = true">
                        </TextBox_Action>

                    </div>

                    <div class="form-group">
                        <TextBox
                            :Config="{ Name: 'UserName', Caption: 'Kullanıcı Adı', Placeholder: 'Kullanıcı Adı', Type:'text', Required: true, ValidationMessage: ValidationMessage['UserName'] }"
                            v-model:Value="Model.UserName"
                            ></TextBox>
                            
                    </div>


                    <div class="form-group">

                        <DropDownList
                        :Config="{ Name: 'UserTypeId', Caption: 'Tipi', Required: true, DataText:'Name', DataValue:'Id', DisabledFieldName:'Id', DisabledValue : -1, ValidationMessage: ValidationMessage['UserTypeId'] }"
                        :Data="UserTypeData"
                        v-model:Value="Model.UserTypeId"></DropDownList>
                    </div>

                    <div class="form-group">
                        <label for="Active">Aktif</label>
                        <input type="checkbox" name="Active" v-model="Model.Active" class="form-checkbox" />
                    </div>
                </div>

            </div>

        </div>

    </form>
</template>

<script>

import { ref, onMounted } from "vue";
import { useRoute } from "vue-router"

import ButtonLoading from "@/components/ButtonLoading";
import ModalPopUp from "@/components/ModalPopup";
import TextBox_Action from "@/components/textbox/TextBox_Action";
import TextBox from "@/components/textbox/TextBox";
import DropDownList from "@/components/dropdownlist/DropDownList";
import Emp_Single_Popup_List from "@/views/admin/employee/Emp_Single_Popup_List";


import UserTypeService from "@/_services/usertype.service";
import UserService from "@/_services/user.service";

export default {
    components: {
        ButtonLoading,
        ModalPopUp,
        TextBox,
        TextBox_Action,
        DropDownList,
        Emp_Single_Popup_List
    },
    setup() {

        const route = useRoute();
        const { editdetail, save, userResult } = UserService();
        const { dropdownList, userTypeResult } = UserTypeService();

        const submitted = ref(false);
        const PopupShow = ref(false);
        const UserTypeData = ref([{ Id: -1, Name: "Lütfen Bekleyin" }]);
        const ValidationMessage = ref([]);
        const Model = ref({
            Id: 0,
            EmployeeId: 0,
            UserTypeId: -1,
            NameSurName: null,
            UserName: null,
            Active: true,
        });

        onMounted(async () => {

            await dropdownList();

            if (userTypeResult.value.Success) {
                UserTypeData.value = userTypeResult.value.Data;
                Model.value.UserTypeId = 0;
            }

            if (route.params.id > 0) {

                await editdetail(route.params.id);

                if (userResult.value.Success)
                {
                    Model.value = userResult.value.Data;
                }
            }
        });


        const Save_Click = async () => {
            if (!submitted.value) {
                submitted.value = true;

                await save(Model.value);

                if (userResult.value.Success)
                    submitted.value = false;
                else {
                    submitted.value = false;

                    ValidationMessage.value = ref([]);
                    if (userResult.value.Data.Messages.filter(x => x.MessageId == -1).length > 0)
                        ValidationMessage.value = userResult.value.Data.Validations;
                }
            }
        }

        const EmployeeSelected = (item) => {
            Model.value.EmployeeId = item.Id;
            Model.value.NameSurName = item.NameSurName;
            PopupShow.value = false;
        }

        return {
            Model,
            ValidationMessage,
            submitted,
            UserTypeData,
            PopupShow,
            Save_Click,
            EmployeeSelected
        };
    }
}
</script>