<template>
    <ul class="pcoded-item pcoded-left-item">
        <li :id="('lnkMenuItem' + item.Id)" v-for="item in FilterList.filter(x => x.ParentId === 0)" class="pcoded-hasmenu" dropdown-icon="style1" subitem-icon="style1">
            <a href="javascript:void(0);" @click="MenuItem_Click(item.Id)">
                <span class="pcoded-micon"><i :class="item.Icon"></i></span>
                <span class="pcoded-mtext">{{ item.Name }}</span>
            </a>
            <MenuItem :menuList="menuList" :liItem="item"></MenuItem>
        </li>
    </ul>
</template>

<script>

import {ref, watch} from "vue";
import MenuItem from './MenuItem';

export default {
    name: 'MainMenu',
    props: ["menuList"],
    components: {
        MenuItem
    },
    setup(props, context)
    {
        const FilterList = ref([]);

        watch(() => props.menuList, (newValue, oldValue) => {
            FilterList.value = props.menuList; 
        });
        
        const MenuItem_Click = (Id)=>{
            let lnkId = '#lnkMenuItem' + Id;

            if($(lnkId).hasClass("pcoded-trigger"))
                $('#lnkMenuItem' + Id).removeClass("pcoded-trigger");
            else
                $('#lnkMenuItem' + Id).addClass("pcoded-trigger");
        };


        return{FilterList, MenuItem_Click};
    }
}

</script>