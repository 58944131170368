<template>
    <form @submit.prevent="Save_Click">
        <div class="btn-group m-b-10">
            <ButtonLoading class="btn btn-success" :Config="{ Disabled: submitted }">
                <i class="fa fa-check"></i> KAYDET
            </ButtonLoading>
        </div>

        <div class="col card">

            <div class="row p-t-15">

                <div class="col col-12 col-sm-12 col-md-6">

                    <input type="hidden" v-model="Model.Id" />

                    <div class="form-group">

                        <TextBox
                            :Config="{ Name: 'AlacakliFirma', Caption: 'Alacaklı Firma', Placeholder: 'Alacaklı Firma', Type:'text', Required: true, ValidationMessage: ValidationMessage['AlacakliFirma'] }"
                            v-model:Value="Model.AlacakliFirma"
                            ></TextBox>

                    </div>

                    <div class="form-group">

                        <TextBox
                            :Config="{ Name: 'BorcluCariKodu', Caption: 'Borçlu Cari Kodu', Placeholder: 'Borçlu Cari Kodu', Type:'text', Required: true, ValidationMessage: ValidationMessage['BorcluCariKodu'] }"
                            v-model:Value="Model.BorcluCariKodu"
                            ></TextBox>

                    </div>

                    <div class="form-group">

                        <TextBox
                            :Config="{ Name: 'BorcluCariAdi', Caption: 'Borçlu Cari Adı', Placeholder: 'Borçlu Cari Adı', Type:'text', Required: true, ValidationMessage: ValidationMessage['BorcluCariAdi'] }"
                            v-model:Value="Model.BorcluCariAdi"
                            ></TextBox>

                    </div>

                    <div class="row">
                        <div class="col col-sm-5 form-group">

                            <TextBox
                            :Config="{ Name: 'EvrakTuru', Caption: 'Evrak Türü', Placeholder: 'Evrak Türü', Type:'text', Required: true, ValidationMessage: ValidationMessage['EvrakTuru'] }"
                            v-model:Value="Model.EvrakTuru"
                            ></TextBox>

                        </div>

                        <div class="col col-sm-7 form-group">

                            <TextBox
                            :Config="{ Name: 'EvrakTutari', Caption: 'Evrak Tutarı', Placeholder: 'Evrak Tutarı', Type:'text', Required: true, ValidationMessage: ValidationMessage['EvrakTutari'] }"
                            v-model:Value="Model.EvrakTutari"
                            ></TextBox>

                        </div>
                    </div>

                    <div class="row">
                        <div class="col col-sm-6 form-group">

                            <TextBox
                            :Config="{ Name: 'IcraDairesi', Caption: 'İcra Dairesi', Placeholder: 'İcra Dairesi', Type:'text', Required: true, ValidationMessage: ValidationMessage['IcraDairesi'] }"
                            v-model:Value="Model.IcraDairesi"
                            ></TextBox>

                        </div>

                        <div class="col col-sm-6 form-group">

                            <TextBox
                            :Config="{ Name: 'DosyaNo', Caption: 'Dosya No', Placeholder: 'Dosya No', Type:'text', Required: true, ValidationMessage: ValidationMessage['DosyaNo'] }"
                            v-model:Value="Model.DosyaNo"
                            ></TextBox>

                        </div>
                    </div>

                    <div class="row">
                        <div class="col col-sm-6 form-group">

                            <TextBox
                            :Config="{ Name: 'Ili', Caption: 'Ili', Placeholder: 'Ili', Type:'text', Required: true, ValidationMessage: ValidationMessage['Ili'] }"
                            v-model:Value="Model.Ili"
                            ></TextBox>

                        </div>

                        <div class="col col-sm-6 form-group">

                            <TextBox
                            :Config="{ Name: 'DosyaHakkindaGorus', Caption: 'D. Hakkında Gör.', Placeholder: 'D. Hakkında Gör.', Type:'text', Required: true, ValidationMessage: ValidationMessage['DosyaHakkindaGorus'] }"
                            v-model:Value="Model.DosyaHakkindaGorus"
                            ></TextBox>

                        </div>
                    </div>

                    <div class="row">
                        <div class="col col-sm-6 form-group">

                            <TextBox
                            :Config="{ Name: 'HukukBurosu', Caption: 'Hukuk Bürosu', Placeholder: 'Hukuk Bürosu', Type:'text', Required: true, ValidationMessage: ValidationMessage['HukukBurosu'] }"
                            v-model:Value="Model.HukukBurosu"
                            ></TextBox>

                        </div>

                        <div class="col col-sm-6 form-group">

                            <TextBox
                            :Config="{ Name: 'OncekiHukukBurosu', Caption: 'Önceki H.Bürosu', Placeholder: 'Önceki H.Bürosu', Type:'text', ValidationMessage: ValidationMessage['OncekiHukukBurosu'] }"
                            v-model:Value="Model.OncekiHukukBurosu"
                            ></TextBox>

                        </div>
                    </div>

                </div>

                <div class="col col-12 col-sm-12 col-md-6">
                    <div class="row">
                        <div class="col col-sm-6 form-group">

                            <TextBox
                            :Config="{ Name: 'AsilAlacak', Caption: 'Asıl Alacak', Placeholder: 'Asıl Alacak', Type:'text', ValidationMessage: ValidationMessage['AsilAlacak'] }"
                            v-model:Value="Model.AsilAlacak"
                            ></TextBox>

                        </div>

                        <div class="col col-sm-6 form-group">

                            <TextBox
                            :Config="{ Name: 'DosyaTutari', Caption: 'Dosya Tutarı', Placeholder: 'Dosya Tutarı', Type:'text', ValidationMessage: ValidationMessage['DosyaTutari'] }"
                            v-model:Value="Model.DosyaTutari"
                            ></TextBox>

                        </div>
                    </div>

                    <div class="row">
                        <div class="col col-sm-6 form-group">

                            <TextBox
                            :Config="{ Name: 'Masraf', Caption: 'Masraf', Placeholder: 'Masraf', Type:'text', ValidationMessage: ValidationMessage['Masraf'] }"
                            v-model:Value="Model.Masraf"
                            ></TextBox>

                        </div>

                        <div class="col col-sm-6 form-group">

                            <TextBox
                            :Config="{ Name: 'TahsilatTutari', Caption: 'Tahsilat Tutarı', Placeholder: 'Tahsilat Tutarı', Type:'text', ValidationMessage: ValidationMessage['TahsilatTutari'] }"
                            v-model:Value="Model.TahsilatTutari"
                            ></TextBox>

                        </div>
                    </div>

                    <div class="row">
                        <div class="col col-sm-6 form-group">

                            <TextBox
                            :Config="{ Name: 'DosyadaKalanTutar', Caption: 'Dos.Kalan Tutar', Placeholder: 'Dos.Kalan Tutar', Type:'text', ValidationMessage: ValidationMessage['DosyadaKalanTutar'] }"
                            v-model:Value="Model.DosyadaKalanTutar"
                            ></TextBox>

                        </div>

                        <div class="col col-sm-6 form-group">

                            <TextBox
                            :Config="{ Name: 'AsilAlacakKalan', Caption: 'Asıl Alacak Kalan', Placeholder: 'Asıl Alacak Kalan', Type:'text', ValidationMessage: ValidationMessage['AsilAlacakKalan'] }"
                            v-model:Value="Model.AsilAlacakKalan"
                            ></TextBox>

                        </div>
                    </div>

                    <div class="row">
                        <div class="col col-sm-6 form-group">

                            <TextBox
                            :Config="{ Name: 'Yil', Caption: 'Yıl', Placeholder: 'Yıl', Type:'number', Required: true, ValidationMessage: ValidationMessage['Yil'] }"
                            v-model:Value="Model.Yil"
                            ></TextBox>

                        </div>

                        <div class="col col-sm-6 form-group">

                            <TextBox
                            :Config="{ Name: 'DosyaDurumu', Caption: 'Dosya Durumu', Placeholder: 'Dosya Durumu', Type:'text', Required: true, ValidationMessage: ValidationMessage['DosyaDurumu'] }"
                            v-model:Value="Model.DosyaDurumu"
                            ></TextBox>

                        </div>
                    </div>
                </div>

            </div>

        </div>
    </form>
</template>

<script>

import { ref, onMounted } from "vue";
import { useRoute } from "vue-router"
import ButtonLoading from "../../components/ButtonLoading";
import TextBox from "../../components/textbox/TextBox";

import AvDosyaTakipService from "../../_services/avdosyatakip.service";

export default {
    components: {
        ButtonLoading,
        TextBox
    },
    setup() {

        const route = useRoute();
        const { editdetail, save, dosyaResult } = AvDosyaTakipService();

        const submitted = ref(false);
        const ValidationMessage = ref([]);
        const Model = ref({
            Id: 0,
            CompanyId: 0,
            LogoCompanyId: 0,
            LogoPeriodId: 0,
            AlacakliFirma: null,
            BorcluCariKodu: null,
            BorcluCariAdi: null,
            EvrakTuru: null,
            EvrakTutari: 0,
            IcraDairesi: null,
            DosyaNo: null,
            Ili: null,
            DosyaHakkindaGorus: null,
            HukukBurosu: null,
            OncekiHukukBurosu: null,
            AsilAlacak: 0,
            DosyaTutari: 0,
            Masraf: 0,
            TahsilatTutari: 0,
            DosyadaKalanTutar: 0,
            AsilAlacakKalan: 0,
            Yil: 0,
            DosyaDurumu: null,
        });

        onMounted(async () => {

            if (route.params.id > 0) {
                await editdetail(route.params.id);

                if (dosyaResult.value.Success) {
                    Model.value = dosyaResult.value.Data;
                }
            }
        });

        const Save_Click = async () => {
            if (!submitted.value) {
                submitted.value = true;

                await save(Model.value);

                if (dosyaResult.value.Success)
                    submitted.value = false;
                else {
                    submitted.value = false;

                    ValidationMessage.value = ref([]);
                    if (dosyaResult.value.Data.Messages.filter(x => x.MessageId == -1).length > 0)
                        ValidationMessage.value = dosyaResult.value.Data.Validations;
                }
            }
        }

        return { Model, ValidationMessage, submitted, Save_Click };
    }
}
</script>