import { createToaster } from "@meforma/vue-toaster";
import Swal from 'sweetalert2';

const toaster = createToaster({
    position: "top",
    duration: 5000
});

export function ErrorNotifi(Msg) {
    toaster.error(Msg);
}

export function SuccessNotifi(Msg) {
    toaster.success(Msg);
}

export function MessageBoxShow(text, messageBoxIcon, messageBoxButtons) {

    let boxModel = {
        title: text,
        icon: messageBoxIcon
    };

    if (messageBoxButtons == "YesNo") {
        boxModel["showCancelButton"] = true;
        boxModel["confirmButtonColor"] = "#3085d6";
        boxModel["cancelButtonColor"] = "#d33";
        boxModel["confirmButtonText"] = "Evet";
        boxModel["cancelButtonText"] = "Hayır";
    }

    return Swal.fire(boxModel);
}