import { ref } from "vue";
import ResponseService from "@/_services/response.service";

const result = ref({ Success: false, Data: null });
const routePrefix = ref("trading-group/");

const popup_list = async () => {

    const { get, responseResult } = ResponseService();

    await get(routePrefix.value + "popup-list");

    if (responseResult.value) {
        if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
            result.value.Success = true;
            result.value.Data = responseResult.value.Data;
        }
        else 
            result.value.Success = false;
    }
}


const TradingGroupService = () => {
    return { popup_list, result }
}

export default TradingGroupService