<template>
    <label v-if="Config.CaptionShow == null || Config.CaptionShow" :for="Config.Name">{{ Config.Caption }}</label><label
        v-if="Config.Required" :for="Config.Name" class="text-danger">*</label>
    <input :type="Config.Type" :name="Config.Name" :value="Value" class="form-control"
        :class="{ 'input-required-field': Config.ValidationMessage != null }" :placeholder="Config.Placeholder"
        :required="Config.Required" @input="$emit('update:Value', $event.target.value)" />
    <div v-if="Config.ValidationMessage != null" class="text-danger">{{
        Config.ValidationMessage
    }}
    </div>
</template>

<script>
export default {
    props: ["Config", "Value"],
    emits: ['update:Value']
}
</script>