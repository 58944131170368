<template>
    <ModalPopUp :Show="PopupShow" Size="big" @PopupClose="PopupClose_Click">
        <template #header>
            Malzeme Listesi
        </template>
        <template #body>
            <div v-if="isChoose" class="row m-b-15">
                <div class="col">
                    <button class="btn btn-primary" @click="Ekle_Click"><i class="fa fa-plus"></i> EKLE</button>
                    <button class="btn btn-warning" @click="MultiCheck_PopupShow = true"><i
                            class="fa fa-check-circle-o"></i> ÇOKLU SEÇİM</button>
                    <hr>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label for="TIG"><b>TIG</b></label>
                    <select name="TIG" class="form-control" placeholder="TIG" v-model="selectedTigCode"
                        @change="TIG_Change($event)" :disabled="TIGDisabled">
                        <option v-if="TIGDisabled">Lütfen Bekleyin...</option>
                        <option value="">Seçiniz...</option>
                        <option v-for="item in tradingGroupData" :value="item.Code">{{ item.Code }}</option>
                    </select>
                </div>
            </div>

            <div v-show="isLoading" class="row">
                <div class="col m-t-15 text-center">
                    Lütfen Bekleyin...
                </div>
            </div>
            <div v-show="!isLoading && selectedTigCode != ''" class="row">
                <div class="col m-t-15">
                    <DataGrid :DataSource="DataSource.filter(MultiSelectFilter)" :PageSize="10" :Properties="Properties"
                        :CheckedList="selectedList" @CheckedList="selectedList = $event.value"></DataGrid>
                </div>
            </div>
        </template>
    </ModalPopUp>

    <ModalPopUp v-if="isChoose" :Show="MultiCheck_PopupShow" Size="small" @PopupClose="MultiCheck_PopupShow = false">
        <template #header>
            Çoklu Seçim
        </template>
        <template #body>
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label for="SelectField"><b>Alan Seçiniz</b></label>
                        <select name="SelectField" class="form-control" placeholder="Alan Seçiniz" v-model="selectField">
                            <option value="MUH_KODU">Muh. Kodu</option>
                            <option value="MALZEME_KODU">Malzeme Kodu</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="CodeList"><b>Kod Listsi</b></label>
                        <textarea name="CodeList" class="form-control" cols="1" rows="9" v-model="codeList"></textarea>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col text-right">
                    <button class="btn btn-success" @click="btnFiltrele_Click"><i class="fa fa-check"></i> Filtrele</button>
                </div>
            </div>
        </template>
    </ModalPopUp>
</template>

<script>

import { ref, watch } from "vue";

import ModalPopUp from "@/components/ModalPopup";
import DataGrid from "@/components/data_layout/DataGrid.vue";
import DropDownList from "@/components/dropdownlist/DropDownList";
import { ErrorNotifi } from "@/_helpers/notification";

import TradingGroupService from "@/_services.saha_satis/trading_group.service";
import ProductService from "@/_services/product.service";

export default {
    props: ["Show", "IsChoose", "CheckedList"],
    components: {
        ModalPopUp,
        DataGrid,
        DropDownList
    },
    setup(props, context) {

        const tradingGroupService = TradingGroupService();
        const productService = ProductService();

        const PopupShow = ref(false);
        const MultiCheck_PopupShow = ref(false);
        const isLoading = ref(false);
        const isChoose = ref(false);
        const TIGDisabled = ref(true);
        const tradingGroupData = ref([]);
        const selectedTigCode = ref('');
        const selectField = ref('MUH_KODU');
        const codeList = ref('');
        const selectedList = ref([]);
        const filterList = ref([]);

        const Properties = ref([]);
        const DataSource = ref([]);

        Properties.value = {
            Export: { Landscape: false, MenuShow: false },
            Selection: { FieldValue: "MALZEME_ID" },
            Name:"PoupUp",
            Columns: [
                { Name: "colMuhKodu", Caption: "Muh Kodu", FieldName: "MUH_KODU" },
                { Name: "colMalzemeKodu", Caption: "Malzeme Kodu", FieldName: "MALZEME_KODU" },
                { Name: "colMalzemeAdi", Caption: "Malzeme Adı", FieldName: "MALZEME_ADI", SummaryItem: { SummaryType: "Count", Format: "N0", Text: "Satır Sayısı = " } },
                { Name: "colBirim", Caption: "Birim", FieldName: "BIRIM" },
            ]
        }

        isChoose.value = props["IsChoose"] == null ? false : props["IsChoose"];

        if (isChoose.value)
            Properties.value["Selection"] = { FieldValue: "MALZEME_ID" };


        watch(() => props["Show"], (newValue, oldValue) => {
            PopupShow.value = props["Show"];

            if (PopupShow.value)
                TIG_GetList();
        });

        const PopupClose_Click = () => {
            PopupShow.value = false;
            context.emit("PopupClose", PopupShow.value);
        }

        const Ekle_Click = () => {

            if (selectedList.value.length > 0) {

                let selectedList2 = [];

                selectedList.value.forEach((v) => {
                    let model = DataSource.value.find(x => x.MALZEME_ID == v);
                    selectedList2.push(model);
                });

                selectedList.value = [];
                context.emit("SelectedList", { selectedList: selectedList2, TigCode: selectedTigCode.value });
                PopupClose_Click();
            }
            else
                ErrorNotifi("Malzeme Seçiniz");
        }

        const btnFiltrele_Click = () => {

            filterList.value = [];
            selectedList.value = [];

            codeList.value.split("\n").forEach((v) => {
                let model = DataSource.value.find(x => x.MUH_KODU == v);

                if (model != null) {
                    selectedList.value.push(model.MALZEME_ID);
                    filterList.value.push(v);
                }
            });

            MultiCheck_PopupShow.value = false;
        }

        const TIG_GetList = (async () => {

            TIGDisabled.value = true;

            await tradingGroupService.popup_list();

            if (tradingGroupService.result.value.Success) {
                tradingGroupData.value = tradingGroupService.result.value.Data;
                TIGDisabled.value = false;
            }
        });

        const TIG_Change = async (e) => {
            selectedTigCode.value = e.target.value;
            isLoading.value = true;
            selectedList.value = [];

            await productService.popup_list(selectedTigCode.value);

            if (productService.result.value.Success) {
                DataSource.value = productService.result.value.Data;
                isLoading.value = false;
            }
        }

        const MultiSelectFilter = (item) => {
            let returnValue = true;

            returnValue = selectedList.value.length == 0 ? true : filterList.value.length == 0 ? true : filterList.value.includes(item["MUH_KODU"]);
            return returnValue;
        }

        return {
            PopupShow, MultiCheck_PopupShow, isLoading, isChoose, TIGDisabled, tradingGroupData, selectedTigCode, selectField, codeList, selectedList, Properties, DataSource,
            PopupClose_Click, Ekle_Click, btnFiltrele_Click, TIG_Change, MultiSelectFilter
        };
    }
}

</script>