<template>
    <ul>
        <li v-for="item in DataSource.filter(x => x.ParentId == -1)">
            <input type="checkbox" :name="item.Id" :value="item.Id"
                :checked="checkedList.filter(x => x.Id == item.Id).length > 0" @change="Checkbox_Change($event)" />
            <label :for="item.Id" v-html="item.Name"></label>
            <TreeViewItem :DataSource="DataSource" :CheckedList="checkedList" :Id="item.Id" :Config="Config"
                @SelectChecked="SelectChecked"></TreeViewItem>
        </li>
    </ul>
</template>

<script>

import { ref } from "vue";
import TreeViewItem from "@/components/TreeViewItem";

export default {
    components: {
        TreeViewItem
    },
    props: ["DataSource", "Config", "CheckedList"],
    setup(props, context) {
        const checkedList = ref([]);

        checkedList.value = props.CheckedList;

        let id = 0;
        let checked = false;

        const Checkbox_Change = (e) => {

            id = parseInt(e.target.value);
            checked = e.target.checked;

            let checkModel = props.DataSource.find(x => x.Id == id);
            if (checked) {
                let control = checkedList.value.find(x => x.Id == id);
                if (!control)
                    checkedList.value.push({ Id: id, ParentId: checkModel.ParentId });
            } else {
                CheckList_ChildDelete(id);
            }

            Checkbox_ChildCheck();

            context.emit("SelectChecked", checkedList);
        }

        const Checkbox_ChildCheck = () => {
            props.DataSource.filter(x => x.ParentId == id).forEach((item) => {

                if (checked) {
                    checkedList.value.push({ Id: item.Id, ParentId: item.ParentId });
                } else {
                    CheckList_ChildDelete(item.Id);
                }

                ChildNode(item.Id);
            });
        }

        const ChildNode = (Id) => {

            props.DataSource.filter(x => x.ParentId == Id).forEach((item) => {

                if (checked) {
                    checkedList.value.push({ Id: item.Id, ParentId: item.ParentId });
                } else {
                    CheckList_ChildDelete(item.Id);
                }

                ChildNode(item.Id);
            });
        }

        const CheckList_ChildDelete = (Id) => {
            let value = checkedList.value.find(x => x.Id == Id);
            let index = checkedList.value.lastIndexOf(value);
            if (index > -1)
                checkedList.value.splice(index, 1);
        }



        const SelectChecked = ($event) => {
            checkedList.value = $event.value;

            //console.log(checkedList.value);
        }

        return { checkedList, Checkbox_Change, SelectChecked }
    }
}

</script>

<style scoped>
input[type=checkbox] {
    margin-right: 15px;
}
</style>