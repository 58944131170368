<template>
    <form @submit.prevent="Save_Click">
        <div class="btn-group m-b-10">
            <ButtonLoading class="btn btn-success" :Config="{ Disabled: submitted }">
                <i class="fa fa-check"></i> KAYDET
            </ButtonLoading>
        </div>

        <div class="col card">

            <div class="row p-t-15">
                <div class="col-md-12">
                    <p>Parolanızın geçerli ve güvenli olması için aşağıdaki kurallara dikkat ediniz.</p>
                    <p>
                        Parolanız;
                    <ul>
                        <li>En az 7 karakter içermelidir.</li>
                        <li>En az 1 karakter, 1 sayı, 1 özel karakter(*,?! vb) olmalıdır.</li>
                    </ul>
                    </p>
                </div>
            </div>
            <div class="row p-t-15">

                <input type="hidden" v-model="Model.Id" />

                <div class="col col-12 col-sm-12 col-md-6">

                    <div class="form-group">
                        <TextBox
                            :Config="{ Name: 'OldPassword', Caption: 'Eski Şifre', Placeholder: 'Eski Şifre', Type: 'password', Required: true, ValidationMessage: ValidationMessage['OldPassword'] }"
                            v-model:Value="Model.OldPassword"></TextBox>

                    </div>


                    <div class="form-group">
                        <TextBox
                            :Config="{ Name: 'Password', Caption: 'Yeni Şifre', Placeholder: 'Yeni Şifre', Type: 'password', Required: true, ValidationMessage: ValidationMessage['Password'] }"
                            v-model:Value="Model.Password"></TextBox>

                    </div>

                    <div class="form-group">
                        <TextBox
                            :Config="{ Name: 'RePassword', Caption: 'Yeni Şifre (Tekrar)', Placeholder: 'Yeni Şifre (Tekrar)', Type: 'password', Required: true, ValidationMessage: ValidationMessage['RePassword'] }"
                            v-model:Value="Model.RePassword"></TextBox>

                    </div>
                </div>

            </div>

        </div>

    </form>
</template>

<script>

import { ref } from "vue";

import ButtonLoading from "@/components/ButtonLoading";
import TextBox from "@/components/textbox/TextBox";

import UserService from "@/_services/user.service";

export default {
    components: {
        ButtonLoading,
        TextBox
    },
    setup() {

        const { passwordchange_save, userResult } = UserService();

        const submitted = ref(false);
        const ValidationMessage = ref([]);
        const Model = ref({
            Id: 0,
            OldPassword: null,
            Password: null,
            RePassword: null
        });


        const Save_Click = async () => {
            if (!submitted.value) {
                submitted.value = true;

                await passwordchange_save(Model.value);

                if (userResult.value.Success) {
                    submitted.value = false;
                    Model.value = {};
                }
                else {
                    submitted.value = false;

                    ValidationMessage.value = ref([]);
                    if (userResult.value.Data.Messages.filter(x => x.MessageId == -1).length > 0)
                        ValidationMessage.value = userResult.value.Data.Validations;
                }
            }
        }

        return {
            Model,
            ValidationMessage,
            submitted,
            Save_Click,
        };
    }
}
</script>