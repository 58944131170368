<template>
    <div>
        <div v-if="DataSource == null" class="row">
            <div class="col col-sm-12 text-center" style="margin-top:15px;line-height: 25px;">
                <b>Lütfen Bekleyin...</b>
            </div>
        </div>
        <div v-else>
            <div class="row">
                <div class="col col-sm-12" style="margin-top:15px;">
                    <div v-for="item in DataSource" class="list-group" :style="{'padding-left':item.LeftPad + 'px'}">
                        <a class='list-group-item d-flex' href='javascript:;' @click="$emit('Selected', item)">{{
                            item.Name
                        }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from "vue";

import PageService from "@/_services/page.service";

export default {
    setup() {
        const { list, pageResult } = PageService();

        const DataSource = ref(null);

        onMounted(async () => {
            await list();

            if (pageResult.value.Success) {
                DataSource.value = pageResult.value.Data;
            }
        });

        return {
            DataSource
        }
    }
}
</script>