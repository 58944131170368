<template>
  <GChart type="ComboChart" :data="chartData" :options="options" />
</template>

<script>

import { ref, onMounted } from "vue";
import { GChart } from 'vue-google-charts'

import DashboardService from "@/_services.reports/dashboard.service";

export default {
  components: {
    GChart
  },
  setup() {

    const { envanter_raporu, result } = DashboardService();

    const chartData = ref([]);

    onMounted(async () => {
      await envanter_raporu();

      if (result.value.Success) {

        chartData.value.push(['Malzeme Grupları', 'Tutar', { role: 'annotation' }, '']);

        result.value.Data.forEach((item) => {
          chartData.value.push([item.MALZEME_GRUBU]);
          chartData.value[chartData.value.length - 1][1] = item.ORT_MALIYET;
          chartData.value[chartData.value.length - 1][2] = item.ORT_MALIYET;
          chartData.value[chartData.value.length - 1][3] = item.ORAN_KUMULATIF;
        });

        result.value.Success = false;
      }
    });

    const options = {
      width: "100%",
      legend: { position: 'none' },         // no legend
      backgroundColor: { strokeWidth: 1 },   // to get a nice box
      seriesType: "bars",                   // the standard chart type
      chartArea: { left: 75, top: 75, right: 60, bottom: 65, height: '100%', width: '100%' },
      // the second data column should be of type 'line' and should be associated with the second vertical axis
      series: { 1: { type: "line", targetAxisIndex: 1 } },
      height: 450
    }

    return { chartData, options }
  }
}

</script>