import { ref } from "vue";
import { store } from "../store";
import ResponseService from "./response.service";

import { ErrorNotifi, SuccessNotifi } from "../_helpers/notification";

const positionResult = ref({Success : false, Data: null});
const routePrefix = ref("position/");

const list = async () => {

    store.Loading = true;

    const { get, responseResult } = ResponseService();

    await get(routePrefix.value + "list");

    if(responseResult.value)
    {
        if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
            positionResult.value.Success = true;
            positionResult.value.Data = [{ Id: 0, Name: "Seçiniz" }, ...responseResult.value.Data];
        }
        else {
            let Messages = responseResult.value.Messages.filter(x => x.MessageId == 0);
            if (Messages.length > 0)
                ErrorNotifi(Messages[0].Message);

                positionResult.value.Success = false;
        }

        store.Loading = false;
    }
}

const dropdownList = async () => {

    const { get, responseResult } = ResponseService();

    await get(routePrefix.value + "list");

    if(responseResult.value)
    {
        if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
            positionResult.value.Success = true;
            positionResult.value.Data = [{ Id: 0, Name: "Seçiniz" }, ...responseResult.value.Data]
        }
        else {
            let Messages = responseResult.value.Messages.filter(x => x.MessageId == 0);
            if (Messages.length > 0)
                ErrorNotifi(Messages[0].Message);

                positionResult.value.Success = false;
        }
    }
}

const PositionService = () => {
    return { list, dropdownList, positionResult }
}

export default PositionService