import { ref } from "vue";
import { saveAs } from 'file-saver';
import { store } from "../store";
import ResponseService from "./response.service";

const exportResult = ref({ Success: false, Data: null });
const routePrefix = ref("export/");

const tableExport = async (exportType, model) => {

    const { exportData, responseResult } = ResponseService();

    await exportData(routePrefix.value + exportType, model);

    if (responseResult.value) {
        let extension = exportType == "excel" ? ".xlsx" : exportType == "pdf" ? ".pdf" : "";
        let fileName = store.PageTitle.replace(' ', '-');

        fileName += extension;
        saveAs(responseResult.value, fileName);

        exportResult.value.Success = true;
    }
}

const ExportService = () => {
    return { tableExport, exportResult }
}

export default ExportService