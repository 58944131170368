<template>
    <form @submit.prevent="Save_Click">
        <div class="btn-group m-b-10">
            <ButtonLoading class="btn btn-success" :Config="{ Disabled: submitted }">
                <i class="fa fa-check"></i> KAYDET
            </ButtonLoading>
        </div>

        <div class="row p-t-15">

            <div class="col col-12">

                <input type="hidden" v-model="Model.Id" />

                <div class="form-group">
                    <label for="Explanation">Açıklama</label>
                    <textarea name="Explanation" v-model="Model.Explanation" class="form-control" rows="3"></textarea>
                </div>
            </div>

        </div>
    </form>
</template>

<script>
import { ref, onMounted } from "vue";
import ButtonLoading from "../../components/ButtonLoading";

import AvDosyaTakipService from "../../_services/avdosyatakip.service";

export default {
    components: {
        ButtonLoading
    },
    props:["PopUpModel"],
    setup(props, context) {

        const { generalExplanationEditDetail, generalExplanationSave, dosyaResult } = AvDosyaTakipService();
        const submitted = ref(false);
        const propModel = props["PopUpModel"];
        const Model = ref({
            Id: 0,
            DosyaId: propModel.DosyaId,
            Explanation: null,
        });

        onMounted(async () => {

            if (propModel.Id > 0) {
                await generalExplanationEditDetail(propModel.Id);

                if (dosyaResult.value.Success)
                    Model.value = dosyaResult.value.Data;
            }
        });

        const Save_Click = async () => {

            if (!submitted.value) {
                submitted.value = true;

                await generalExplanationSave(Model.value);

                if (dosyaResult.value.Success) {
                    submitted.value = false;
                    context.emit('SaveSuccess', 2);
                }
                else
                    submitted.value = false;
            }
        }

        return {
            Model, submitted, Save_Click
        };
    }
}
</script>