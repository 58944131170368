import { ref } from "vue";
import { store } from "../store";
import ResponseService from "./response.service";
import router from "../router";

import { ErrorNotifi, SuccessNotifi, MessageBoxShow } from "../_helpers/notification";

const userResult = ref({ Success: false, Data: null });
const deleteResult = ref({ Success: false });
const routePrefix = ref("user/");

const list = async () => {

    store.Loading = true;

    const { get, responseResult } = ResponseService();

    await get(routePrefix.value + "list");

    if (responseResult.value) {
        if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
            userResult.value.Success = true;
            userResult.value.Data = responseResult.value.Data;
        }
        else {
            let Messages = responseResult.value.Messages.filter(x => x.MessageId == 0);
            if (Messages.length > 0)
                ErrorNotifi(Messages[0].Message);

                userResult.value.Success = false;
        }

        store.Loading = false;
    }
}

const editdetail = async (id) => {

    store.Loading = true;

    const { get, responseResult } = ResponseService();

    await get(routePrefix.value + "edit/" + id);

    if (responseResult.value) {
        if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
            userResult.value.Success = true;
            userResult.value.Data = responseResult.value.Data;
        }
        else {
            let Messages = responseResult.value.Messages.filter(x => x.MessageId == 0);
            if (Messages.length > 0)
                ErrorNotifi(Messages[0].Message);

                userResult.value.Success = false;
        }

        store.Loading = false;
    }
}

const detail = async (id) => {

    store.Loading = true;

    const { get, responseResult } = ResponseService();

    await get(routePrefix.value + "detail/" + id);

    if (responseResult.value) {
        if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
            userResult.value.Success = true;
            userResult.value.Data = responseResult.value.Data;
        }
        else {
            let Messages = responseResult.value.Messages.filter(x => x.MessageId == 0);
            if (Messages.length > 0)
                ErrorNotifi(Messages[0].Message);

            userResult.value.Success = false;
        }

        store.Loading = false;
    }
}

const save = async (model) => {

    store.Loading = true;

    const { post, responseResult } = ResponseService();

    await post(routePrefix.value + "edit", model);

    if (responseResult.value) {
        if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
            let messageModel = responseResult.value.Messages.filter(x => x.MessageId == 1);
            SuccessNotifi(messageModel[0].Message);

            router.push({ name: "UserList" });
            userResult.value.Success = true;
        }
        else {
            let Messages = responseResult.value.Messages.filter(x => x.MessageId == 0);
            if (Messages.length > 0)
                ErrorNotifi(Messages[0].Message);

            userResult.value.Data = responseResult.value;
            userResult.value.Success = false;
        }

        store.Loading = false;
    }
}

const Delete = async (id) => {
    MessageBoxShow('Silmek istediğinizden emin misiniz?', 'warning', 'YesNo').then(async (result) => {
        if (result.isConfirmed) {
            store.Loading = true;

            const { Delete, responseResult } = ResponseService();

            await Delete(routePrefix.value + "/" + id);

            if (responseResult.value) {
                if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
                    let messageModel = responseResult.value.Messages.filter(x => x.MessageId == 1);
                    SuccessNotifi(messageModel[0].Message);

                    router.push({ name: "UserList" });
                    userResult.value.Success = true;
                }
                else {
                    let Messages = responseResult.value.Messages.filter(x => x.MessageId == 0);
                    if (Messages.length > 0)
                        ErrorNotifi(Messages[0].Message);

                    userResult.value.Data = responseResult.value;
                    userResult.value.Success = false;
                }

                store.Loading = false;
            }
        }
    });
}

const DeleteList = async (CheckedList) => {
    store.Loading = true;

    const { Delete, responseResult } = ResponseService();

    let ids = CheckedList.join(',')
    await Delete(routePrefix.value + "delete-list?ids=" + ids);

    if (responseResult.value) {
        if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
            let messageModel = responseResult.value.Messages.filter(x => x.MessageId == 1);
            SuccessNotifi(messageModel[0].Message);

            deleteResult.value.Success = true;
        }
        else {
            let Messages = responseResult.value.Messages.filter(x => x.MessageId == 0);
            if (Messages.length > 0)
                ErrorNotifi(Messages[0].Message);

            deleteResult.value.Success = false;
        }

        store.Loading = false;
    }
}

const authorizationList = async (id) => {

    store.Loading = true;

    const { get, responseResult } = ResponseService();

    await get(routePrefix.value + "authorizations/" + id);

    if (responseResult.value) {
        if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
            userResult.value.Success = true;
            userResult.value.Data = responseResult.value.Data;
        }
        else {
            let Messages = responseResult.value.Messages.filter(x => x.MessageId == 0);
            if (Messages.length > 0)
                ErrorNotifi(Messages[0].Message);

            userResult.value.Success = false;
        }

        store.Loading = false;
    }
}

const authorizationSave = async (model) => {

    store.Loading = true;

    const { post, responseResult } = ResponseService();

    await post(routePrefix.value + "authorizations/edit", model);

    if (responseResult.value) {
        if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
            let messageModel = responseResult.value.Messages.filter(x => x.MessageId == 1);
            SuccessNotifi(messageModel[0].Message);
            userResult.value.Success = true;
        }
        else {
            let Messages = responseResult.value.Messages.filter(x => x.MessageId == 0);
            if (Messages.length > 0)
                ErrorNotifi(Messages[0].Message);

            userResult.value.Data = responseResult.value;
            userResult.value.Success = false;
        }

        store.Loading = false;
    }
}


const passwordchange_save = async (model) => {

    store.Loading = true;

    const { post, responseResult } = ResponseService();

    await post(routePrefix.value + "password-change-save", model);

    if (responseResult.value) {
        if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
            let messageModel = responseResult.value.Messages.filter(x => x.MessageId == 1);
            SuccessNotifi(messageModel[0].Message);

            userResult.value.Success = true;
        }
        else {
            let Messages = responseResult.value.Messages.filter(x => x.MessageId == 0);
            if (Messages.length > 0)
                ErrorNotifi(Messages[0].Message);

            userResult.value.Data = responseResult.value;
            userResult.value.Success = false;
        }

        store.Loading = false;
    }
}

const signOut = async () => {

    const { post, responseResult } = ResponseService();

    await post(routePrefix.value + "signout?v=1");

    if (responseResult.value) {
        if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {

            localStorage.clear("user");
            userResult.value.Success = true;

            router.push({ name: "Login" });
        }
        else {
            let Messages = responseResult.value.Messages.filter(x => x.MessageId == 0);
            if (Messages.length > 0)
                ErrorNotifi(Messages[0].Message);

            userResult.value.Data = responseResult.value;
            userResult.value.Success = false;
        }
    }
}

const UserService = () => {
    return { list, editdetail, detail, save, Delete, DeleteList, authorizationList, authorizationSave, passwordchange_save, signOut, userResult, deleteResult }
}

export default UserService