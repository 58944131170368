import { ref } from "vue";
import ResponseService from "@/_services/response.service";

const result = ref({ Success: false, Data: null });
const routePrefix = ref("reports/sales/");

const month_based_sales_popup = async (code, year) => {

    const { get, responseResult } = ResponseService();

    await get(routePrefix.value + "month-based-sales-popup?code=" + code + "&year=" + year);

    if (responseResult.value) {
        if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
            result.value.Success = true;
            result.value.Data = responseResult.value.Data;
        }
        else
            result.value.Success = false;
    }
}


const SalesService = () => {
    return { month_based_sales_popup, result }
}

export default SalesService