<template>
    <div class="btn-group m-b-10">
        <router-link :to="{ name: 'TableViewEdit' }" class="btn btn-dropbox"><i class="fa fa-plus"></i> YENİ
        </router-link>
        <!-- <button class="btn btn-pinterest" @click="DeleteClick" :disabled='checkedList.length === 0'><i
                class="fa fa-minus"></i> SİL</button>
        <button class="btn btn-dropbox" @click="RefleshClick"><i class="fa fa-refresh"></i> YENİLE</button> -->
    </div>

    <!-- @CheckedList="checkedList = $event.value" -->
    <DataGrid v-if="Object.keys(Properties).length > 0" :DataSource="DataSource" :Properties="Properties"></DataGrid>
</template>

<script>

import { ref, onMounted } from "vue";

import DataGrid from "@/components/data_layout/DataGrid.vue";

import TableViewService from "@/_services/tableview.service";

export default {
    components: {
        DataGrid
    },
    setup() {

        const { createtableview, list, result } = TableViewService();

        const DataSource = ref(null);
        const Properties = ref([]);

        onMounted(async () => {
            await createtableview('TableView');

            if (result.value.Success) {
                Properties.value = result.value.Data;
                result.value.Success = false;

                await list();

                if(result.value.Success)
                {
                    DataSource.value = result.value.Data;
                    result.value.Success = false;
                }
            }

        });

        return { DataSource, Properties }
    }
}

</script>