<template>
    <ul class="pcoded-submenu">
        <li v-for="menu in FilterList"
            :id="('lnkMenuItem' + menu.Id)"
            :class="{ 'pcoded-hasmenu': menuList.filter(x => x.ParentId === menu.Id).length > 0 }" dropdown-icon="style1"
            subitem-icon="style1">
            <a v-if="menuList.filter(x => x.ParentId === menu.Id).length > 0" href="javascript:void(0)"
                @click="MenuItem_Click(menu.Id)">
                <span class="pcoded-mtext">{{ menu.Name }}</span>
            </a>
            <router-link v-else :to="menu.Url">
                <span class="pcoded-mtext">{{ menu.Name }}</span>
            </router-link>
            <MenuItem :menuList="menuList" :liItem="menu">
            </MenuItem>
        </li>
    </ul>
</template>

<script>
import { ref } from "vue";
import MenuItem from './MenuItem';

export default {
    name: "MenuItem",
    props: ["menuList", "liItem"],
    components: {
        MenuItem
    },
    setup(props, context) {
        const FilterList = ref([]);

        FilterList.value = props.menuList.filter(x => x.ParentId === props.liItem.Id);

        const MenuItem_Click = (Id) => {
            let lnkId = '#lnkMenuItem' + Id;

            if ($(lnkId).hasClass("pcoded-trigger"))
                $('#lnkMenuItem' + Id).removeClass("pcoded-trigger");
            else
                $('#lnkMenuItem' + Id).addClass("pcoded-trigger");
        };

        return { FilterList, MenuItem_Click };
    }
}
</script>