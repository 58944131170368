import { ref } from "vue";
import ResponseService from "@/_services/response.service";

const result = ref({ Success: false, Data: null });
const routePrefix = ref("report/product/");

const stock_popup = async (code) => {

    const { get, responseResult } = ResponseService();

    await get(routePrefix.value + "stock-popup?code=" + code);

    if (responseResult.value) {
        if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
            result.value.Success = true;
            result.value.Data = responseResult.value.Data;
        }
        else
            result.value.Success = false;
    }
}

const line_popup = async (code) => {

    const { get, responseResult } = ResponseService();

    await get(routePrefix.value + "line-popup?code=" + code);

    if (responseResult.value) {
        if (responseResult.value.Messages.filter(x => x.MessageId == 1).length > 0) {
            result.value.Success = true;
            result.value.Data = responseResult.value.Data;
        }
        else
            result.value.Success = false;
    }
}

const ProductService = () => {
    return { stock_popup, line_popup, result }
}

export default ProductService