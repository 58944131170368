const valueTypeSearch_fns = {
    number(val) {
        return val.toString();
    },
    string(val) {
        return val.toLocaleLowerCase('tr-TR');
    },
    object(val) {
        return val;
    }
};

const ToolService = () => {
    return { valueTypeSearch_fns }
}

export default ToolService