<template>
    <Product_List_PopUp :Show="PopupShow" :IsChoose="true" @PopupClose="PopupShow = false"
        @SelectedList="Items_Selected">
    </Product_List_PopUp>

    <MonthBasedSales_PopUp :Show="MonthBasedSales_PopupShow" @PopupClose="MonthBasedSales_PopupShow = false"
        :ProductCode="ProductCode">
    </MonthBasedSales_PopUp>

    <SalesPriceList_PopUp :Show="SalesPriceList_PopupShow" @PopupClose="SalesPriceList_PopupShow = false"
        :ProductCode="ProductCode">
    </SalesPriceList_PopUp>

    <Stock_List_PopUp :Show="Stock_List_PopUpShow" @PopupClose="Stock_List_PopUpShow = false"
        :ProductCode="ProductCode">
    </Stock_List_PopUp>

    <Line_List_PopUp :Show="Line_List_PopUpShow" @PopupClose="Line_List_PopUpShow = false"
        :ProductCode="ProductCode">
    </Line_List_PopUp>

    <div class="row">
        <div class="col">
            <button class="btn btn-primary btn-mini" @click="PopupShow = true"><i class="fa fa-plus"></i> YENİ
                MALZEME</button>
            <button class="btn btn-danger btn-mini" type="button"
                :disabled='proposal_store.Item_DataSource.length == 0 || selectedList.length == 0'
                @click="ProductRemove_Click()"><i class="fa fa-minus"></i> MALZEME ÇIKAR</button>
        </div>
    </div>

    <div class="row m-t-15">
        <div class="col">
            <div class="table-responsive">
                <DataGrid :DataSource="proposal_store.Item_DataSource" :PageSize="50" :Properties="Properties"
                    :CheckedList="selectedList" @CheckedList="selectedList = $event.value"
                    @ddlTIG_EditValueChanged="ddlTIG_EditValueChanged" @txtMiktar_Blur="txtMiktar_Blur"
                    @txtBirimFiyati_Blur="txtBirimFiyati_Blur" @txtIskonto_Blur="txtIskonto_Blur"
                    @txtArtiIskonto_Blur="txtArtiIskonto_Blur" @txtKarlilikOraniNakit_Blur="txtKarlilikOraniNakit_Blur"
                    @txtKarlilikOraniTaksit_Blur="txtKarlilikOraniTaksit_Blur"
                    @txtKarlilikOraniVadeli_Blur="txtKarlilikOraniVadeli_Blur" @txtMaliyet_Blur="txtMaliyet_Blur"
                    @Row_Menu_Click="Row_Menu_Click">
                </DataGrid>
            </div>
        </div>
    </div>
</template>

<script>

import { ref, watch, onMounted } from "vue";
import { proposal_store } from "@/store";

import DataGrid from "@/components/data_layout/DataGrid.vue";

import MonthBasedSales_PopUp from "@/views/sales/pop_up/MonthBasedSales_PopUp.vue";
import Product_List_PopUp from "@/views/products/components/Product_List_PopUp.vue";
import SalesPriceList_PopUp from "@/views/products/components/SalesPrice_List_PopUp.vue";
import Stock_List_PopUp from "@/views/products/components/Stock_List_PopUp.vue";
import Line_List_PopUp from "@/views/products/components/Line_List_PopUp.vue";


import ProposalService from "@/_services/proposal.service";
import TradingGroupService from "@/_services.saha_satis/trading_group.service";

export default {
    props: ["TabChange"],
    components: {
        Product_List_PopUp,
        MonthBasedSales_PopUp,
        SalesPriceList_PopUp,
        Stock_List_PopUp,
        Line_List_PopUp,
        DataGrid
    },
    setup(props, context) {

        const { product_add_or_update, proposalResult } = ProposalService();
        const tradingGroupService = TradingGroupService();

        const PopupShow = ref(false);
        const MonthBasedSales_PopupShow = ref(false);
        const SalesPriceList_PopupShow = ref(false);
        const Stock_List_PopUpShow = ref(false);
        const Line_List_PopUpShow = ref(false);

        const Properties = ref([]);
        const selectedList = ref([]);

        const ProductCode = ref("");

        Properties.value = {
            Export: { Landscape: false, MenuShow: true },
            WordWrapColumn: true,
            AutoSize: false,
            Selection: { FieldValue: "MALZEME_ID" },
            Name: "Proposal",
            Columns: [
                { Name: "colMenu", Caption: "", FieldName: "", Width: "75", Component: { Type: "Menu", TextImageRel: "Icon", IconCss: "fa fa-cog", Items: [{ Text: "Satış", ValueMember: "MALZEME_ID", Click: "Row_Menu_Click", Name: "mSatis" }, { Text: "Satış Fiyatları", ValueMember: "MALZEME_ID", Click: "Row_Menu_Click", Name: "mSatisFiyatlari" }, { Text: "Ambar Toplamları", ValueMember: "MALZEME_ID", Click: "Row_Menu_Click", Name: "mStok" }, { Text: "Malzeme Hareketleri", ValueMember: "MALZEME_ID", Click: "Row_Menu_Click", Name: "mMalzemeHareketleri" }] }, ExportShow: false },
                { Name: "colMuhKodu", Caption: "Muh Kodu", FieldName: "MUH_KODU", Width: "175" },
                { Name: "colMalzemeKodu", Caption: "Malzeme Kodu", FieldName: "MALZEME_KODU", Width: "155" },
                { Name: "colMalzemeAdi", Caption: "Malzeme Adı", FieldName: "MALZEME_ADI", Width: "355", SortOrder: "Asc", SummaryItem: { SummaryType: "Count", Format: "N0", Text: "Satır Sayısı = " } },
                { Name: "colTIG", Caption: "TIG", FieldName: "TIG", Width: "155", Component: { Name: "ddlTIG", Type: "DropDownList", Func: "ddlTIG_EditValueChanged", DisplayMember: "Code", ValueMember: "Code", ValueType: 'String', DataSource: null } },
                { Name: "colBirim", Caption: "Birim", FieldName: "BIRIM", Width: "75" },
                { Name: "colMiktar", Caption: "Miktar", FieldName: "MIKTAR", Width: "75", Component: { Name: "txtMiktar", Type: "TextBox", Class: "bg-success", Func: "txtMiktar_Blur", Format: "FormatUnknow" } },
                { Name: "colKur", Caption: "Kur", FieldName: "KUR_KODU", Width: "75", },
                { Name: "colSonDurumStok", Caption: "Son Durum Stok", FieldName: "SON_DURUM_STOK", Width: "100", Format: "N0" },
                { Name: "colIskBirimFiyatiNakit", Caption: "İsk. Birim Fiyati (Nakit)", FieldName: "ISK_BIRIM_FIYATI_NAKIT", Width: "110", Format: "N2", StyleCell: { "background-color": "#ffd800" }, SummaryItem: { SummaryType: "Sum", Format: "N2" } },
                { Name: "colIskBirimFiyatiTaksit", Caption: "İsk. Birim Fiyati (Taksit)", FieldName: "ISK_BIRIM_FIYATI_TAKSIT", Width: "110", Format: "N2", StyleCell: { "background-color": "#ffd800" }, SummaryItem: { SummaryType: "Sum", Format: "N2" } },
                { Name: "colIskBirimFiyatiVadeli", Caption: "İsk. Birim Fiyati (Vadeli)", FieldName: "ISK_BIRIM_FIYATI_VADELI", Width: "110", Format: "N2", StyleCell: { "background-color": "#ffd800" }, SummaryItem: { SummaryType: "Sum", Format: "N2" } },
                { Name: "colBirimFiyati", Caption: "Birim Fiyatı", FieldName: "BIRIM_FIYATI", Width: "100", Component: { Name: "txtBirimFiyati", Type: "TextBox", Class: "bg-success", Func: "txtBirimFiyati_Blur", Format: "N2" } },
                { Name: "colBirimFiyatiVadeli", Caption: "Birim Fiyatı (Vadeli)", FieldName: "VADELI_BIRIM_FIYATI", Width: "110", Format: "N2", StyleCell: { "background-color": "#C0FFFF" }, SummaryItem: { SummaryType: "Sum", Format: "N2" } },
                { Name: "colToplamBirimFiyatiNakit", Caption: "Toplam Birim Fiyatı (Nakit)", FieldName: "TOPLAM_BIRIM_FIYATI_NAKIT", Width: "110", Format: "N2", SummaryItem: { SummaryType: "Sum", Format: "N2" } },
                { Name: "colToplamBirimFiyatiTaksit", Caption: "Toplam Birim Fiyatı (Taksit)", FieldName: "TOPLAM_BIRIM_FIYATI_TAKSIT", Width: "110", Format: "N2", SummaryItem: { SummaryType: "Sum", Format: "N2" } },
                { Name: "colToplamBirimFiyatiVadeli", Caption: "Toplam Birim Fiyatı (Vadeli)", FieldName: "TOPLAM_BIRIM_FIYATI_VADELI", Width: "110", Format: "N2", SummaryItem: { SummaryType: "Sum", Format: "N2" } },
                { Name: "colIskonto", Caption: "% İskonto", FieldName: "ISKONTO", Width: "75", Component: { Name: "txtIskonto", Type: "TextBox", Class: "bg-success", Func: "txtIskonto_Blur" } },
                { Name: "colArtiIskonto", Caption: "(+)Artı İskonto", FieldName: "ARTI_ISKONTO", Width: "100", Component: { Name: "txtArtiIskonto", Type: "TextBox", Class: "bg-success", Func: "txtArtiIskonto_Blur" } },
                { Name: "colKarlilikOraniNakit", Caption: "% Karlılık Oranı (Nakit)", FieldName: "KARLILIK_ORANI_NAKIT", Width: "100", Component: { Name: "txtKarlilikOraniNakit", Type: "TextBox", Class: "bg-success", Func: "txtKarlilikOraniNakit_Blur", Format: "N2" } },
                { Name: "colKarlilikOraniTaksit", Caption: "% Karlılık Oranı (Taksit)", FieldName: "KARLILIK_ORANI_TAKSIT", Width: "100", Component: { Name: "txtKarlilikOraniTaksit", Type: "TextBox", Class: "bg-success", Func: "txtKarlilikOraniTaksit_Blur", Format: "N2" } },
                { Name: "colKarlilikOraniVadeli", Caption: "% Karlılık Oranı (Vadeli)", FieldName: "KARLILIK_ORANI_VADELI", Width: "100", Component: { Name: "txtKarlilikOraniVadeli", Type: "TextBox", Class: "bg-success", Func: "txtKarlilikOraniVadeli_Blur", Format: "N2" } },
                { Name: "colMaliyet", Caption: "Maliyet", FieldName: "MALIYET", Width: "100", Component: { Name: "txtMaliyet", Type: "TextBox", Class: "bg-success", Func: "txtMaliyet_Blur", Format: "N2" } },

                { Name: "colGuncelMaliyet", Caption: "Güncel Maliyet", FieldName: "GUNCEL_MALIYET", Width: "100", Format: "N2", StyleCell: { "background-color": "#FFC0C0" } },
                { Name: "colGuncelKarlilikOraniNakit", Caption: "Güncel Maliyet Karlılık Oranı (%) (Nakit)", FieldName: "GUNCEL_KARLILIK_ORANI_NAKIT", Width: "150", Format: "N2", StyleCell: { "background-color": "#FFC0C0" } },
                { Name: "colGuncelKarlilikOraniTaksit", Caption: "Güncel Maliyet Karlılık Oranı (%) (Taksit)", FieldName: "GUNCEL_KARLILIK_ORANI_TAKSIT", Width: "150", Format: "N2", StyleCell: { "background-color": "#FFC0C0" } },
                { Name: "colGuncelKarlilikOraniVadeli", Caption: "Güncel Maliyet Karlılık Oranı (%) (Vadeli)", FieldName: "GUNCEL_KARLILIK_ORANI_VADELI", Width: "150", Format: "N2", StyleCell: { "background-color": "#FFC0C0" } },

                { Name: "colBaglantiMaliyet", Caption: "Bağlantı Maliyet", FieldName: "BAGLANTI_MALIYET", Width: "100", Format: "N2", StyleCell: { "background-color": "#FFE0C0" } },
                { Name: "colBaglantiKarlilikOraniNakit", Caption: "Bağlantı Maliyet Karlılık Oranı (%) (Nakit)", FieldName: "BAGLANTI_KARLILIK_ORANI_NAKIT", Width: "150", Format: "N2", StyleCell: { "background-color": "#FFE0C0" } },
                { Name: "colBaglantiKarlilikOraniTaksit", Caption: "Bağlantı Maliyet Karlılık Oranı (%) (Taksit)", FieldName: "BAGLANTI_KARLILIK_ORANI_TAKSIT", Width: "150", Format: "N2", StyleCell: { "background-color": "#FFE0C0" } },
                { Name: "colBaglantiKarlilikOraniVadeli", Caption: "Bağlantı Maliyet Karlılık Oranı (%) (Vadeli)", FieldName: "BAGLANTI_KARLILIK_ORANI_VADELI", Width: "150", Format: "N2", StyleCell: { "background-color": "#FFE0C0" } },

                { Name: "colGuncelMaliyetTarihi", Caption: "Güncel Maliyet Tarihi", FieldName: "GUNCEL_MALIYET_TARIH", Width: "125", Format: "Date" },
                { Name: "colToplamMaliyet", Caption: "Toplam Maliyet", FieldName: "TOPLAM_MALIYET", Width: "100", Format: "N2" },
                { Name: "colSatinAlmaSonFaturaMaliyet", Caption: "Satın Alma Son Fatura Maliyet", FieldName: "SATIN_ALMA_SON_FATURA_MALIYET", Width: "100", Format: "N2" },
                
            ]
        }

        proposal_store.Item_DataSource = [];

        watch(() => props["TabChange"], (newValue, oldValue) => {
            context.emit("SetData", "tabItems");
        });

        // watch(() => proposal_store.Item_DataSource, (newValue, oldValue) => {

        // }//, { deep: true }
        // );


        onMounted(async () => {
            await tradingGroupService.popup_list();

            if (tradingGroupService.result.value.Success) {
                let col = Properties.value.Columns.find(x => x.Name == 'colTIG');
                col.Component.DataSource = tradingGroupService.result.value.Data;
            }

        });

        const Items_Selected = async (val) => {

            let products = [];

            val.selectedList.forEach((v) => {
                products.push(v.MALZEME_ID);
            });

            if (products.length > 0) {
                await product_add_or_update(products, val.TigCode);

                if (proposalResult.value.Success) {
                    proposalResult.value.Data.forEach((v) => {
                        v.TIG = val.TigCode;
                        v.ARTI_ISKONTO = '';

                        RowValue_Changed(v);
                        proposal_store.Item_DataSource.push(v);
                    });
                }
            }
        }

        const RowValue_Changed = (row, isDiscountCalc) => {

            let nakitIskonto = 0;
            let taksitIskonto = 0;
            let vadeliIskonto = 0;

            if (!isDiscountCalc) {
                let IskBirimFiyatiCari = row.BIRIM_FIYATI - ((row.ISKONTO / 100) * row.BIRIM_FIYATI);
                row.ISK_BIRIM_FIYATI_NAKIT = IskBirimFiyatiCari;
                row.ISK_BIRIM_FIYATI_TAKSIT = IskBirimFiyatiCari;
                row.ISK_BIRIM_FIYATI_VADELI = IskBirimFiyatiCari;

                nakitIskonto = row.NAKIT_ISKONTO;
                taksitIskonto = row.TAKSIT_ISKONTO;
                vadeliIskonto = row.VADELI_ISKONTO;
            }
            else {
                row.ISKONTO = 0;
            }

            row.ISK_BIRIM_FIYATI_NAKIT = row.ISK_BIRIM_FIYATI_NAKIT - ((nakitIskonto / 100) * row.ISK_BIRIM_FIYATI_NAKIT);
            row.ISK_BIRIM_FIYATI_TAKSIT = row.ISK_BIRIM_FIYATI_TAKSIT - ((taksitIskonto / 100) * row.ISK_BIRIM_FIYATI_TAKSIT);
            row.ISK_BIRIM_FIYATI_VADELI = row.ISK_BIRIM_FIYATI_VADELI - ((vadeliIskonto / 100) * row.ISK_BIRIM_FIYATI_VADELI);

            if (row.ARTI_ISKONTO != '') {
                row.ARTI_ISKONTO.split('+').forEach((isk) => {
                    row.ISK_BIRIM_FIYATI_NAKIT = row.ISK_BIRIM_FIYATI_NAKIT - ((isk / 100) * row.ISK_BIRIM_FIYATI_NAKIT);
                    row.ISK_BIRIM_FIYATI_TAKSIT = row.ISK_BIRIM_FIYATI_TAKSIT - ((isk / 100) * row.ISK_BIRIM_FIYATI_TAKSIT);
                    row.ISK_BIRIM_FIYATI_VADELI = row.ISK_BIRIM_FIYATI_VADELI - ((isk / 100) * row.ISK_BIRIM_FIYATI_VADELI);
                });
            }

            row.ISK_BIRIM_FIYATI_NAKIT = parseFloat(row.ISK_BIRIM_FIYATI_NAKIT).toFixed(2);
            row.ISK_BIRIM_FIYATI_TAKSIT = parseFloat(row.ISK_BIRIM_FIYATI_TAKSIT).toFixed(2);
            row.ISK_BIRIM_FIYATI_VADELI = parseFloat(row.ISK_BIRIM_FIYATI_VADELI).toFixed(2);

            row.TOPLAM_MALIYET = row.MALIYET * row.MIKTAR;
            row.GUNCEL_TOPLAM_MALIYET = row.GUNCEL_MALIYET * row.MIKTAR;
            row.BAGLANTI_TOPLAM_MALIYET = row.BAGLANTI_MALIYET * row.MIKTAR;

            row.NAKIT_KARLILIK = row.ISK_BIRIM_FIYATI_NAKIT - row.TOPLAM_MALIYET;
            row.TAKSIT_KARLILIK = row.ISK_BIRIM_FIYATI_TAKSIT - row.TOPLAM_MALIYET;
            row.VADELI_KARLILIK = row.ISK_BIRIM_FIYATI_VADELI - row.TOPLAM_MALIYET;

            if (!isDiscountCalc) {

                row.KARLILIK_ORANI_NAKIT = (row.NAKIT_KARLILIK / row.TOPLAM_MALIYET) * 100;
                row.KARLILIK_ORANI_TAKSIT = (row.TAKSIT_KARLILIK / row.TOPLAM_MALIYET) * 100;
                row.KARLILIK_ORANI_VADELI = (row.VADELI_KARLILIK / row.TOPLAM_MALIYET) * 100;

                row.KARLILIK_ORANI_NAKIT = parseFloat(row.KARLILIK_ORANI_NAKIT).toFixed(2);
                row.KARLILIK_ORANI_TAKSIT = parseFloat(row.KARLILIK_ORANI_TAKSIT).toFixed(2);
                row.KARLILIK_ORANI_VADELI = parseFloat(row.KARLILIK_ORANI_VADELI).toFixed(2);
            }

            row.TOPLAM_BIRIM_FIYATI_NAKIT = row.ISK_BIRIM_FIYATI_NAKIT * row.MIKTAR;
            row.TOPLAM_BIRIM_FIYATI_TAKSIT = row.ISK_BIRIM_FIYATI_TAKSIT * row.MIKTAR;
            row.TOPLAM_BIRIM_FIYATI_VADELI = row.ISK_BIRIM_FIYATI_VADELI * row.MIKTAR;

            if (row.GUNCEL_TOPLAM_MALIYET > 0) {
                let guncelKarlilikNakit = row.TOPLAM_BIRIM_FIYATI_NAKIT - row.GUNCEL_TOPLAM_MALIYET;
                row.GUNCEL_KARLILIK_ORANI_NAKIT = (guncelKarlilikNakit / row.GUNCEL_TOPLAM_MALIYET) * 100;

                let guncelKarlilikTaksit = row.TOPLAM_BIRIM_FIYATI_TAKSIT - row.GUNCEL_TOPLAM_MALIYET;
                row.GUNCEL_KARLILIK_ORANI_TAKSIT = (guncelKarlilikTaksit / row.GUNCEL_TOPLAM_MALIYET) * 100;

                let guncelKarlilikVadeli = row.TOPLAM_BIRIM_FIYATI_VADELI - row.GUNCEL_TOPLAM_MALIYET;
                row.GUNCEL_KARLILIK_ORANI_VADELI = (guncelKarlilikVadeli / row.GUNCEL_TOPLAM_MALIYET) * 100;

                row.GUNCEL_KARLILIK_ORANI_NAKIT = parseFloat(row.GUNCEL_KARLILIK_ORANI_NAKIT).toFixed(2);
                row.GUNCEL_KARLILIK_ORANI_TAKSIT = parseFloat(row.GUNCEL_KARLILIK_ORANI_TAKSIT).toFixed(2);
                row.GUNCEL_KARLILIK_ORANI_VADELI = parseFloat(row.GUNCEL_KARLILIK_ORANI_VADELI).toFixed(2);
            }

            if (row.BAGLANTI_TOPLAM_MALIYET > 0) {
                let baglantiKarlilikNakit = row.TOPLAM_BIRIM_FIYATI_NAKIT - row.BAGLANTI_TOPLAM_MALIYET;
                row.BAGLANTI_KARLILIK_ORANI_NAKIT = (baglantiKarlilikNakit / row.BAGLANTI_TOPLAM_MALIYET) * 100;

                let baglantiKarlilikTaksit = row.TOPLAM_BIRIM_FIYATI_TAKSIT - row.BAGLANTI_TOPLAM_MALIYET;
                row.BAGLANTI_KARLILIK_ORANI_TAKSIT = (baglantiKarlilikTaksit / row.BAGLANTI_TOPLAM_MALIYET) * 100;

                let baglantiKarlilikVadeli = row.TOPLAM_BIRIM_FIYATI_VADELI - row.BAGLANTI_TOPLAM_MALIYET;
                row.BAGLANTI_KARLILIK_ORANI_VADELI = (baglantiKarlilikVadeli / row.BAGLANTI_TOPLAM_MALIYET) * 100;

                row.BAGLANTI_KARLILIK_ORANI_NAKIT = parseFloat(row.BAGLANTI_KARLILIK_ORANI_NAKIT).toFixed(2);
                row.BAGLANTI_KARLILIK_ORANI_TAKSIT = parseFloat(row.BAGLANTI_KARLILIK_ORANI_TAKSIT).toFixed(2);
                row.BAGLANTI_KARLILIK_ORANI_VADELI = parseFloat(row.BAGLANTI_KARLILIK_ORANI_VADELI).toFixed(2);
            }
        }

        const ProductRemove_Click = () => {

            selectedList.value.forEach((v) => {
                proposal_store.Item_DataSource = proposal_store.Item_DataSource.filter(x => x.MALZEME_ID !== v);
            });

            selectedList.value = [];
        }

        const ddlTIG_EditValueChanged = async (val, row) => {
            row.TIG = val;

            let products = [];
            products.push(row.MALZEME_ID);

            if (products.length > 0) {
                await product_add_or_update(products, val);

                if (proposalResult.value.Success) {
                    proposalResult.value.Data.forEach((v) => {
                        row.ISKONTO = v.ISKONTO;
                        row.BIRIM_FIYATI = v.BIRIM_FIYATI;
                        row.VADELI_BIRIM_FIYATI = v.VADELI_BIRIM_FIYATI;
                        row.NAKIT_ISKONTO = v.NAKIT_ISKONTO;
                        row.TAKSIT_ISKONTO = v.TAKSIT_ISKONTO;
                        row.VADELI_ISKONTO = v.VADELI_ISKONTO;

                        RowValue_Changed(row);
                    });
                }
            }
        }

        const txtMiktar_Blur = (val, row) => {
            row.MIKTAR = parseFloat(val.replace(',', '.'));
            RowValue_Changed(row);
        }

        const txtBirimFiyati_Blur = (val, row) => {
            row.BIRIM_FIYATI = parseFloat(val.replace(',', '.'));
            RowValue_Changed(row);
        }

        const txtIskonto_Blur = (val, row) => {
            row.ISKONTO = parseFloat(val.replace(',', '.'));
            RowValue_Changed(row);
        }

        const txtArtiIskonto_Blur = (val, row) => {
            row.ARTI_ISKONTO = val.replace(',', '.');
            RowValue_Changed(row);
        }

        const txtKarlilikOraniNakit_Blur = (val, row) => {
            row.KARLILIK_ORANI_NAKIT = parseFloat(val.replace(',', '.'));
            row.ISK_BIRIM_FIYATI_NAKIT = row.MALIYET * ((row.KARLILIK_ORANI_NAKIT + 100) / 100);

            RowValue_Changed(row, true);
        }

        const txtKarlilikOraniTaksit_Blur = (val, row) => {
            row.KARLILIK_ORANI_TAKSIT = parseFloat(val.replace(',', '.'));
            row.ISK_BIRIM_FIYATI_TAKSIT = row.MALIYET * ((row.KARLILIK_ORANI_TAKSIT + 100) / 100);

            RowValue_Changed(row, true);
        }

        const txtKarlilikOraniVadeli_Blur = (val, row) => {
            row.KARLILIK_ORANI_VADELI = parseFloat(val.replace(',', '.'));
            row.ISK_BIRIM_FIYATI_VADELI = row.MALIYET * ((row.KARLILIK_ORANI_VADELI + 100) / 100);

            RowValue_Changed(row, true);
        }

        const txtMaliyet_Blur = (val, row) => {
            row.MALIYET = parseFloat(val.replace(',', '.'));

            RowValue_Changed(row);
        }

        const Row_Menu_Click = (model) => {

            let row = proposal_store.Item_DataSource[model.RowIndex];
                ProductCode.value = row.MUH_KODU;

            if (model.Key == "mSatis")
                MonthBasedSales_PopupShow.value = true;
            else if (model.Key == "mSatisFiyatlari")
                SalesPriceList_PopupShow.value = true;
            else if (model.Key == "mStok")
                Stock_List_PopUpShow.value = true;
            else if (model.Key == "mMalzemeHareketleri")
                Line_List_PopUpShow.value = true;
        }


        return {
            proposal_store,
            PopupShow,
            ProductCode,
            MonthBasedSales_PopupShow,
            SalesPriceList_PopupShow,
            Stock_List_PopUpShow,
            Line_List_PopUpShow,
            selectedList,
            Properties,
            Items_Selected,
            ProductRemove_Click,
            ddlTIG_EditValueChanged,
            txtMiktar_Blur,
            txtBirimFiyati_Blur,
            txtIskonto_Blur,
            txtArtiIskonto_Blur,
            txtKarlilikOraniNakit_Blur,
            txtKarlilikOraniTaksit_Blur,
            txtKarlilikOraniVadeli_Blur,
            txtMaliyet_Blur,
            Row_Menu_Click
        }
    }
}

</script>
