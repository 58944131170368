<template>
    <ModalPopUp :Show="PopupShow" Size="medium" @PopupClose="PopupShow = false">
        <template #header>
            Grup Seçiniz
        </template>
        <template #body>
            <TreeView :DataSource="Model.DataSource" :Config="{ Text_FieldName: 'Name' }" :CheckedList="CustomerCheckedList"
                @SelectChecked="Customer_SelectChecked">
            </TreeView>
        </template>
    </ModalPopUp>

    <TemplateFilter :Config="Config" @Selected="TemplateFilter_Selected">

        <template #Row1_Column2>

            <div class="col col-12 col-sm-12 col-md-4">
                <div class="form-group row">
                    <label class="col-12 col-sm-4 col-md-4 col-form-label"><b>Cari Grubu</b></label>
                    <div class="col-12 col-sm-8 col-md-8 input-group">
                        <input type="text" class="form-control bg-white" :value="CustomerCheckedList.length + ' seçili'">
                        <span class="input-group-btn">
                            <button type="button" class="btn btn-dropbox" @click="PopupShow = true">...</button>
                        </span>
                    </div>

                    
                </div>

                <slot name="Row1_Column2"></slot>
            </div>

            

        </template>

        
    </TemplateFilter>

    <div class="row">
        <div class="col col-12 m-b-15 text-center">
            <button type="button" class="btn btn-success" @click="Filter_Click"><i class="fa fa-check"></i> Filtrele</button>
        </div>
    </div>
</template>

<script>

import { ref } from "vue";
import { store } from "@/store";

import ModalPopUp from "@/components/ModalPopup";
import TemplateFilter from "@/components/filter/TemplateFilter"
import TreeView from "@/components//TreeView.vue";

export default {
    props: ["Config"],
    components: {
        ModalPopUp,
        TemplateFilter,
        TreeView
    },
    setup(props, context) {

        const PopupShow = ref(false);
        const CustomerCheckedList = ref([]);
        const Model = ref({
            DataSource: []
        });

        const TemplateFilter_Selected = ($event) => {
            Object.keys($event).forEach((item) => {
                Model.value[item] = $event[item];
            });

            if (store.UserAuth != null) {
                CustomerCheckedList.value = [];
                Model.value.DataSource = store.UserAuth.LogoCustomerGroupAuthList.filter(x => x.LogoCompanyId === Model.value.LogoCompanyId);

                Model.value.DataSource.splice(0, 0, { Id: 0, ParentId: -1, Name: "Tümü" });

                Model.value.DataSource.forEach((item) => {
                    CustomerCheckedList.value.push({ Id: item.Id, ParentId: item.ParentId });
                });
            }
        }

        const Filter_Click = () => {
            context.emit("Filter_Click", Model.value);
        }

        const Customer_SelectChecked = ($event) => {
            CustomerCheckedList.value = $event.value;
        }

        return { PopupShow, Model, CustomerCheckedList, TemplateFilter_Selected, Filter_Click, Customer_SelectChecked }
    }
}
</script>