<template>
    <div>
        <form class="row" @submit.prevent="Search_Click">
            <div class="col col-sm-12">
                <div class="input-group m-t-15">
                    <input name="Search" type="text" v-model="Model" class="form-control"
                        placeholder="Aramak istediğiniz kişiyi yazınız.">
                    <span class="input-group-btn">
                        <button type="button" class="btn btn-warning" :disabled="IsLoading" @click="Search_Click">
                            <i v-if="!IsLoading" class="fa fa-search"></i>
                            <i v-else class="fa fa-spinner fa-spin"></i>
                        </button>
                    </span>
                </div>
            </div>
        </form>

        <div v-if="DataSource == null" class="row">
            <div class="col col-sm-12" style="margin-top:15px;line-height: 25px;">
                Aramak istediğiniz kişiyi yazınız.<br>
                <b>(Örnk:Adı Soyadı, *Adı Soyadı)</b>
            </div>
        </div>
        <div v-else-if="IsLoading" class="row">
            <div class="col col-sm-12 text-center" style="margin-top:15px;line-height: 25px;">
                <b>Lütfen Bekleyin...</b>
            </div>
        </div>
        <div v-else>
            <div v-if="DataSource.length == 0" class="row">
                <div class="col col-sm-12" style="margin-top:15px;">
                    Aradığınız kriter bulunamadı
                </div>
            </div>
            <div v-else class="row">
                <div class="col col-sm-12" style="margin-top:15px;">
                    <div v-for="item in DataSource" class="list-group">
                        <a class='list-group-item d-flex' href='javascript:;' @click="$emit('Selected', item)">{{
        item.NameSurName
}}</a>
                    </div>
                </div>
            </div>
            <div v-if="page < pageCount" class="row">
                <div class="col col-sm-12 text-center m-t-15">
                    <button class="btn btn-primary" @click="PageLoad_Click">
                        <span v-if="!IsPageLoading">Sayfa {{ page }}/{{ pageCount }}</span>
                        <i v-else class="fa fa-spinner fa-spin"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";

import EmployeeService from "@/_services/employee.service";

export default {
    setup() {
        const { popupList, employeeResult } = EmployeeService();

        const IsLoading = ref(false);
        const IsPageLoading = ref(false);
        const DataSource = ref(null);
        const Model = ref(null);
        const page = ref(1);
        const pageCount = ref(1);

        let pageSize = 30;

        const Search_Click = async () => {

            IsLoading.value = true;
            page.value = 1;

            await popupList(Model.value, page.value, pageSize);

            if (employeeResult.value.Success) {
                DataSource.value = employeeResult.value.Data.list;
                pageCount.value = employeeResult.value.Data.dataCount / pageSize;
                IsLoading.value = false;
            }
        }

        const PageLoad_Click = async () => {

            page.value += 1;

            IsPageLoading.value = true;

            await popupList(Model.value, page.value, pageSize);

            if (employeeResult.value.Success) {

                employeeResult.value.Data.list.forEach((item) => {
                    DataSource.value.push(item);
                });

                pageCount.value = employeeResult.value.Data.dataCount / pageSize;
                IsPageLoading.value = false;
            }
        }

        return {
            IsLoading,
            IsPageLoading,
            DataSource,
            Model,
            page,
            pageCount,
            Search_Click,
            PageLoad_Click
        }
    }
}
</script>