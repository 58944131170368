<template>
    <form @submit.prevent="Save_Click">
        <div class="btn-group m-b-10">
            <ButtonLoading class="btn btn-success" :Config="{ Disabled: submitted }">
                <i class="fa fa-check"></i> KAYDET
            </ButtonLoading>
        </div>

        <div class="col card">

            <div class="row p-t-15">

                <input type="hidden" v-model="Model.Id" />

                <div class="col col-12 col-sm-12 col-md-6">

                    <div class="form-group">
                        <TextBox
                            :Config="{ Name: 'Title', Caption: 'Başlık', Placeholder: 'Başlık', Type: 'text', Required: true, ValidationMessage: ValidationMessage['Title'] }"
                            v-model:Value="Model.Title"></TextBox>

                    </div>


                    <div class="form-group">
                        <label for="IsDefault">Varsayılan</label>
                        <input type="checkbox" name="IsDefault" v-model="Model.IsDefault" class="form-checkbox" />
                    </div>


                </div>

            </div>

            <div class="row">
                <div class="col col-sm-12">
                    <div class="form-group">
                        <QuillEditor theme="snow" toolbar="full" v-model:content="Model.Text" contentType="html"
                            style="height: 250px;" />

                        <div v-if="ValidationMessage['Text'] != null" class="text-danger">{{
                            ValidationMessage['Text']
                        }}
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </form>
</template>

<script>

import { ref, onMounted } from "vue";
import { useRoute } from "vue-router"

import ButtonLoading from "@/components/ButtonLoading";
import TextBox from "@/components/textbox/TextBox";

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import DefinitionFormatService from "@/_services/proposaldefinitions.service";

export default {
    components: {
        ButtonLoading,
        TextBox,
        QuillEditor
    },
    setup() {

        const route = useRoute();
        const { editdetail, save, descResult } = DefinitionFormatService();

        const submitted = ref(false);
        const ValidationMessage = ref([]);
        const Model = ref({
            Id: 0,
            CompanyId: 1,
            Title: null,
            Text: null,
            IsDefault: false
        });


        onMounted(async () => {

            if (route.params.id > 0) {

                await editdetail(route.params.id);

                if (descResult.value.Success) {
                    Model.value = descResult.value.Data;
                }
            }
        });


        const Save_Click = async () => {

            if (!submitted.value) {
                submitted.value = true;

                await save(Model.value);

                if (descResult.value.Success)
                    submitted.value = false;
                else {
                    submitted.value = false;

                    ValidationMessage.value = ref([]);
                    if (descResult.value.Data.Messages.filter(x => x.MessageId == -1).length > 0)
                        ValidationMessage.value = descResult.value.Data.Validations;
                }
            }
        }

        return {
            Model,
            ValidationMessage,
            submitted,
            Save_Click
        };
    }
}
</script>