<template>
    <MyComponent></MyComponent>
</template>

<script>

import { ref, createApp } from "vue";
import Vue from 'vue'

export default {
    setup() {

        
    }

}

</script>

