<template>
    <div class="btn-group m-b-10">
        <router-link :to="{ name: 'EmployeeEdit' }" class="btn btn-dropbox"><i class="fa fa-plus"></i> YENİ
        </router-link>
        <button class="btn btn-pinterest" @click="Delete_Click"><i class="fa fa-minus"></i> SİL</button>
        <router-link :to="{ name: 'EmployeeEdit', params: { id: Model.Id } }" class="btn btn-dropbox"><i
                class="fa fa-edit"></i> DÜZENLE
        </router-link>
    </div>

    <div class="col card">

        <div class="row p-t-15">

            <div class="col col-12 col-sm-12 col-md-6">

                <div class="form-group">
                    <label>Adı</label><br>
                    {{ Model.Name }}
                </div>

                <div class="form-group">
                    <label>Soyadı</label><br>
                    {{ Model.SurName }}
                </div>

                <div class="form-group">
                    <label>E-Mail</label><br>
                    {{ Model.EMail }}
                </div>

                <div class="form-group">
                    <label>Departman</label><br>
                    {{ Model.DepartmentText }}
                </div>

                <div class="form-group">
                    <label>Görevi</label><br>
                    {{ Model.PositionText }}
                </div>

                <div class="form-group">
                    <label>Ünvanı</label><br>
                    {{ Model.TitleText }}
                </div>

            </div>

            <div class="col col-12 col-sm-12 col-md-6">
                <div class="form-group">
                    <label>Ülke</label><br>
                    {{ Model.CountryText }}
                </div>

                <div class="form-group">
                    <label>Şirket</label><br>
                    {{ Model.CompanyText }}
                </div>

                <div class="form-group">
                    <label>İş Yeri</label><br>
                    {{ Model.WorkPlaceText }}
                </div>

                <div class="form-group">
                    <label>Personel</label><br>
                    {{ Model.EmployeeTypeText }}
                </div>

                <div class="row">
                    <div class="col col-sm-6 form-group">
                        <label>Oluşturan</label><br>
                        {{ Model.CreatedBy_Text }}
                    </div>

                    <div class="col col-sm-6 form-group">
                        <label>Oluşturulma Tarihi</label><br>
                        {{$filters["Date"](Model.CreadedDate) }}
                    </div>
                </div>

                <div class="row">
                    <div class="col col-sm-6 form-group">
                        <label>Güncelleyen</label><br>
                        {{ Model.ModifiedBy_Text }}
                    </div>

                    <div class="col col-sm-6 form-group">
                        <label>Güncellenme Tarihi</label><br>
                        {{ $filters["Date"](Model.ModifiedDate) }}
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router"

import EmployeeService from "@/_services/employee.service";

export default {
    setup() {

        const { detail, Delete, employeeResult } = EmployeeService();
        const route = useRoute();

        const Model = ref({
            Id: 0,
            Name: null,
            SurName: null,
            EMail: null,
            TitleText: null,
            DepartmentText: null,
            PositionText: null,
            CountryText: null,
            CompanyText: null,
            WorkPlaceText: null,
            EmployeeTypeText: null,
            CreatedBy_Text: null,
            CreadedDate_Text: null,
            ModifiedBy_Text: null,
            ModifiedDate_Text: null
        });

        onMounted(async () => {

            if (route.params.id > 0) {
                await detail(route.params.id);

                if (employeeResult.value.Success) {
                    Model.value = employeeResult.value.Data;
                }
            }
        });

        const Delete_Click = async () => {
            if (route.params.id > 0)
                await Delete(route.params.id);
        }

        return { Model, Delete_Click };
    }
}
</script>